import axios from "axios";
import { min } from "lodash";
import React, { useMemo, useState } from "react";
import { Table } from "rsuite";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as EventLeavingIcon } from "../../../assets/icons/event_leaving.svg";
import { ReactComponent as EventRepeatIcon } from "../../../assets/icons/event_repeat.svg";
import { ReactComponent as EventUpcomingIcon } from "../../../assets/icons/event_upcoming.svg";
import { ReactComponent as LocalLaundryServiceIcon } from "../../../assets/icons/local_laundry_service.svg";
import TrendingComparison from "../../../components/Comparions/trendingComparison";
import ManualDataModal from "../../../components/Modals/ManualDataModal";
import BaseCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleDateCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleDateCell";
import SimpleTextCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useDeleteRequest from "../../../hooks/apiRequests/useDeleteRequest";
import { tUseGetRequest } from "../../../hooks/apiRequests/useGetRequest";
import useScreenSize from "../../../hooks/useScreenSize";
import { tWithRequired } from "../../../interfaces/others";
import { tHotelManualData } from "../../../models/hotel";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../../utils/tables";

export type tTableItem = {
  previousPeriodKgOutsourced: number | null;
  homologousPeriodKgOutsourced: number | null;
} & Pick<tHotelManualData, "from" | "to" | "_id"> &
  tWithRequired<tHotelManualData, "laundry">["laundry"];

interface iProps extends Pick<iListProps, "readyManualData"> {
  containerHeight: number;
}

const ListWrapped: React.FC<iProps> = ({
  readyManualData,
  containerHeight,
}) => {
  const { trans } = useLocalizationState();
  const { hotelId } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const deleteRequest = useDeleteRequest();
  const [manualDataModal, setManualDataModal] = useState<
    | {
        open: true;
        manualDataId: tHotelManualData["_id"];
      }
    | { open: false; manualDataId: undefined }
  >({ open: false, manualDataId: undefined });

  const options = (
    dataKey: string,
    item: tTableItem
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    options.push({
      key: "update entry",
      label: trans("components.manual_data.global_edit_entry.label"),
      icon: LocalLaundryServiceIcon,
      onClick() {
        setManualDataModal({ open: true, manualDataId: item._id });
      },
    });

    options.push({
      key: "remove",
      icon: { Element: DeleteIcon, fill: COLORS.error },
      label: {
        text: trans("components.manual_data.global_remove_entry.label"),
        color: COLORS.error,
      },
      onClick() {
        deleteRequest.pending();
        axios
          .delete(
            `${apiAddress(false)}/v2/hotels/${hotelId}/manual-data/${
              item._id
            }/properties/laundry`
          )
          .then((res) => {
            const {
              data: { hotel },
            } = res;
            updateHotel(hotelId, hotel);
            deleteRequest.resolve();
          })
          .catch((err) => {
            const error = getErrorMessage(err, trans);
            deleteRequest.reject(error);
          });
      },
    });

    return options;
  };

  const tableData: tTableItem[] = useMemo(() => {
    return readyManualData.data.list.map((md, i, self) => {
      const { laundry, from, to, _id } = md;
      return {
        _id,
        from,
        to,
        ...laundry,
        previousPeriodKgOutsourced:
          i > 0 ? self[i - 1].laundry.kgOutsourced : null,
        homologousPeriodKgOutsourced: null,
      } as tTableItem;
    });
  }, [readyManualData.data.list]);

  const tableHeight =
    readyManualData.isLoading || tableData.length === 1
      ? TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT
      : (min([
          tableData.length * TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT,
          containerHeight - 270,
        ]) as number);

  return (
    <>
      <ManualDataModal
        initialStep="laundry"
        open={manualDataModal.open}
        onClose={() => {
          setManualDataModal({ open: false, manualDataId: undefined });
        }}
        manualDataId={manualDataModal.manualDataId}
      />
      <div className="table-wrapper">
        <Table
          id="TABLE.CONSUMPTION.LAUNDRY"
          height={tableHeight}
          rowHeight={TABLE_ROW_HEIGHT.M}
          headerHeight={TABLE_HEADER_HEIGHT}
          data={tableData}
          loading={readyManualData.isLoading || deleteRequest.isLoading}
        >
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell
              name={trans("general.from_date")}
              icon={EventUpcomingIcon}
            />
            <SimpleDateCell showDiff={false} dataKey="from" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell
              name={trans("general.to_date")}
              icon={EventLeavingIcon}
            />
            <SimpleDateCell showDiff={false} dataKey="to" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell
              name={trans("general.laundry")}
              icon={LocalLaundryServiceIcon}
            />
            <SimpleTextCell
              dataKey="kgOutsourced"
              textFunction={(item: tTableItem) => {
                return `${item.kgOutsourced} kg`;
              }}
            />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell
              name={trans("general.period_previous")}
              icon={EventRepeatIcon}
            />
            <BaseCell>
              {(item: tTableItem) => {
                if (item.previousPeriodKgOutsourced === null) return "-";
                return (
                  <TrendingComparison
                    size={12}
                    value={item.kgOutsourced}
                    baseValue={item.previousPeriodKgOutsourced}
                  />
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </>
  );
};

interface iListProps {
  readyManualData: tUseGetRequest<{
    list: tWithRequired<tHotelManualData, "laundry">[];
  }>;
}

const List: React.FC<iListProps> = ({ readyManualData }) => {
  const { "root-outlet-container": roc } = useScreenSize();

  if (!roc) return null;

  return <ListWrapped {...{ readyManualData, containerHeight: roc.height }} />;
};

export default List;
