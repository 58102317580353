import React from "react";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import PageBottomPadding from "../../../components/PageBottomPadding";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";
import LoyaltyProgram from "./loyaltyProgram";
import PerformanceProgram from "./performanceProgram";
import Unlock from "./unlock";

const LoyaltyWrapped: React.FC = () => {
  return (
    <Flex column gap={40}>
      <PerformanceProgram />
      <LoyaltyProgram />
    </Flex>
  );
};

const LoyaltyGuarded: React.FC = () => {
  const { activeSubscriptionType } = useHotelState();

  if (activeSubscriptionType === "starter")
    return (
      <>
        <Unlock />
        <PageBottomPadding />
      </>
    );
  return (
    <>
      <LoyaltyWrapped />
      <PageBottomPadding />
    </>
  );
};

const Loyalty: React.FC = () => {
  const { trans } = useLocalizationState();
  return (
    <>
      <TopBar>
        <InterTag
          bold
          size={24}
          color={COLORS.secondary}
          text={trans("general.loyalty")}
        />
      </TopBar>
      <IpValidatorGuard>
        <LoyaltyGuarded />
      </IpValidatorGuard>
    </>
  );
};

export default Loyalty;
