import { ReactComponent as AccountBalanceIcon } from "../../assets/icons/account_balance.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import { ReactComponent as ElectricBoltIcon } from "../../assets/icons/electric_bolt.svg";
import { ReactComponent as HandshakeIcon } from "../../assets/icons/handshake.svg";
import { ReactComponent as PsychiatryIcon } from "../../assets/icons/psychiatry.svg";
import { ReactComponent as ShoppingCartIcon } from "../../assets/icons/shopping_cart.svg";
import { ReactComponent as WaterDropIcon } from "../../assets/icons/water_drop.svg";
import { ReactComponent as ModeHeatIcon } from "../../assets/icons/mode_heat.svg";
import { tIcon } from "../../interfaces/icon";
import { tHotelRoadmap } from "../../models/hotel";
import { COLORS, tColor } from "../../utils/colors";
import { hotelRoadmapStatusToTransKey } from "../../utils/hotels/roadmap";

export const typeTranslation = (
  type: string
): [tColor, string, tIcon] | [tColor] => {
  switch (type) {
    case "environment":
      return [
        COLORS.esg_environment,
        "general.environment",
        { Element: PsychiatryIcon },
      ];
    case "governance":
      return [
        COLORS.esg_governance,
        "general.governance",
        { Element: AccountBalanceIcon },
      ];
    case "social":
      return [COLORS.esg_social, "general.social", { Element: HandshakeIcon }];
    case "supply chain":
      return [
        COLORS.esg_supply_chain,
        "general.supply Chain",
        { Element: ShoppingCartIcon },
      ];
    case "electricity":
      return [
        COLORS.energy,
        "general.measures.electricity",
        { Element: ElectricBoltIcon },
      ];
    case "water":
      return [
        COLORS.water,
        "general.measures.water",
        { Element: WaterDropIcon },
      ];
    case "waste":
      return [COLORS.waste, "general.waste", { Element: DeleteIcon }];
    case "fuels":
      return [COLORS.fuels, "general.fuels", { Element: ModeHeatIcon }];
    default:
      return [COLORS.white];
  }
};

export const difficultyTranslation = (
  difficulty: tHotelRoadmap["difficulty"]
) => {
  switch (difficulty) {
    case "low":
      return ["green", "components.roadmap.item.difficulty.low"];
    case "medium":
      return ["yellow", "components.roadmap.item.difficulty.medium"];
    case "high":
      return ["red", "components.roadmap.item.difficulty.high"];
  }
};

export const statusTranslation = (
  status: tHotelRoadmap["status"]
): [string, tColor, tColor] /** [text, textColor, backgroundColor] */ => {
  switch (status) {
    case "in progress":
      return [
        hotelRoadmapStatusToTransKey(status),
        COLORS.info,
        "rgba(33, 150, 243, 0.15)",
      ];
    case "completed":
      return [
        hotelRoadmapStatusToTransKey(status),
        COLORS.success,
        "rgba(76, 175, 80, 0.15)",
      ];
    case "idle":
      return [
        hotelRoadmapStatusToTransKey(status),
        COLORS.esg_supply_chain,
        "rgba(237, 134, 84, 0.21)",
      ];
  }
};
