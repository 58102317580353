import moment from "moment";
import { tGuest } from "../../models/guest";

// @ts-expect-error
export const defaultGuest: tGuest = {
  _id: "",
  email: "",
  name: "",
  username: "",
  images: [],
  country: "",
  active: true,
  facebook: { id: "" },
  google: { id: "" },
  friends: [],
  isAdmin: false,
  isDummy: false,
  local: { password: "" },
  method: "local",
  noytrallId: "",
  phoneNumber: "",
  referralCode: "",
  registered: true,
  reservations: [],
  // createdAt: moment(0).toDate(),
  // forgotPassword: { token: "", validUntil: moment(0).toDate() },
  // updatedAt: moment(0).toDate(),
};
