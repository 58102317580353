import axios from "axios";
import { at, isString, isUndefined, toInteger } from "lodash";
import React, { useState } from "react";
import { InlineEdit } from "rsuite";
import Flex from "../../../components/Flex";
import SkeletonText from "../../../components/Skeleton/SkeletonText";
import InterTag from "../../../components/Text/Inter";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePutRequest from "../../../hooks/apiRequests/usePutRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import tHotel from "../../../models/hotel";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import styles from "./styles.module.css";

interface iProps {}

const AccommodationSettings: React.FC<iProps> = () => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const { hotel, hotelId, hotelLoading } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const [websiteInput, setWebsiteInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [addressInput, setAddressInput] = useState("");
  const [accommodationUnitsInput, setAccommodationUnitsInput] = useState(0);
  const putRequest = usePutRequest();

  useEffectSafe(() => {
    setWebsiteInput(hotel.website || "");
  }, [hotel.website]);

  useEffectSafe(() => {
    setAddressInput(hotel.location.address || "");
  }, [hotel.location.address]);

  useEffectSafe(() => {
    setEmailInput(hotel.email || "");
  }, [hotel.email]);

  useEffectSafe(() => {
    setAccommodationUnitsInput(hotel.accommodationNumberOfUnits || 0);
  }, [hotel.accommodationNumberOfUnits]);

  const handleUpdateHotel = (key: string, value: any) => {
    putRequest.pending();
    axios
      .put(`${apiAddress(false)}/v2/hotels/${hotelId}/`, { [key]: value })
      .then((res) => {
        const {
          data: { hotel: updatedHotel },
        } = res;
        updateHotel(hotelId, updatedHotel);
        toaster.success(trans("general.updated.successfully"));
        putRequest.resolve();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        toaster.error(error);
        putRequest.reject(error);
      });
  };

  const list: {
    path:
      | keyof Pick<
          tHotel,
          | "name"
          | "website"
          | "accommodationType"
          | "email"
          | "accommodationNumberOfUnits"
        >
      | `${keyof Pick<tHotel, "location">}.${Extract<
          keyof tHotel["location"],
          "address"
        >}`;
    transKeyword: string;
    editable?: {
      stateValue: string | number;
      setStateValue:
        | React.Dispatch<React.SetStateAction<string>>
        | React.Dispatch<React.SetStateAction<number>>;
      onCancel(): void;
      onSave(): void;
      disabled: boolean;
    };
    loading: boolean;
  }[] = [
    {
      path: "name",
      transKeyword: "accommodationName",
      loading: hotelLoading || putRequest.isLoading,
    },
    {
      path: "accommodationType",
      transKeyword: "accommodationType",
      loading: hotelLoading || putRequest.isLoading,
    },
    {
      path: "email",
      transKeyword: "accommodationEmail",
      editable: {
        stateValue: emailInput,
        setStateValue: setEmailInput,
        onCancel() {
          setEmailInput(hotel.email);
        },
        onSave() {
          handleUpdateHotel("email", emailInput);
        },
        disabled: false,
      },
      loading: hotelLoading || putRequest.isLoading,
    },
    {
      path: "website",
      transKeyword: "accommodationWebsite",
      editable: {
        stateValue: websiteInput,
        setStateValue: setWebsiteInput,
        onCancel() {
          setWebsiteInput(hotel.website);
        },
        onSave() {
          handleUpdateHotel("website", websiteInput);
        },
        disabled: false,
      },
      loading: hotelLoading || putRequest.isLoading,
    },
    {
      path: "location.address",
      transKeyword: "accommodationAddress",
      editable: {
        stateValue: addressInput,
        setStateValue: setAddressInput,
        onCancel() {
          setAddressInput(hotel.location.address);
        },
        onSave() {
          handleUpdateHotel("address", addressInput);
        },
        disabled: false,
      },
      loading: hotelLoading || putRequest.isLoading,
    },
    {
      path: "accommodationNumberOfUnits",
      transKeyword: "accommodationUnits",
      editable: {
        stateValue: accommodationUnitsInput,
        setStateValue: (value: any) =>
          setAccommodationUnitsInput(
            toInteger(
              `${value}`
                .split("")
                .filter((c) => /[0-9]/.test(c))
                .join("")
            )
          ),
        onCancel() {
          setAccommodationUnitsInput(hotel.accommodationNumberOfUnits || 0);
        },
        onSave() {
          handleUpdateHotel(
            "accommodationNumberOfUnits",
            accommodationUnitsInput
          );
        },
        disabled: false,
      },
      loading: hotelLoading || putRequest.isLoading,
    },
  ];

  return (
    <div>
      <Flex
        row
        between
        middle
        className={[
          styles["settings-item"],
          styles["settings-item-text"],
          styles["settings-item--not-last"],
        ].join(" ")}
      >
        <InterTag
          size={14}
          text={trans("pages.settings.panels.accommodation.items.rnt.label")}
          bold
          color={COLORS.secondary}
        />
        <InterTag
          loading={hotelLoading}
          skeletonWidth={80}
          size={14}
          text={
            hotel.tourismRegister.type
              ? `RNT ${hotel.tourismRegister.type} ${hotel.tourismRegister._id}`
              : "---"
          }
          color={COLORS.secondary}
        />
      </Flex>
      {list.map(({ path, editable, loading, transKeyword }) => {
        const value = at(hotel, path)[0] as any;
        return (
          <Flex
            key={path}
            row
            between
            middle
            className={[
              styles["settings-item"],
              styles["settings-item--not-last"],
            ].join(" ")}
          >
            <InterTag
              size={14}
              text={trans(
                `pages.settings.panels.accommodation.items.${transKeyword}.label`
              )}
              bold
              color={COLORS.secondary}
            />
            {loading ? (
              <SkeletonText width={210} height={14} />
            ) : editable ? (
              <InlineEdit
                className="inter"
                style={{
                  width: "fit-content",
                  minWidth: "210px",
                  textAlign: "right",
                  color: !editable.stateValue ? "red" : COLORS.secondary,
                }}
                value={editable.stateValue}
                onChange={editable.setStateValue}
                onCancel={editable.onCancel}
                onSave={(e: any) => {
                  if (
                    e.type === "click" ||
                    (e.type === "keydown" && e.code === "Enter")
                  ) {
                    editable.onSave();
                  } else editable.onCancel();
                }}
                disabled={editable.disabled}
                placeholder={trans("general.unfilled")}
              />
            ) : (
              <InterTag
                className={styles["settings-item-text"]}
                {...{
                  ...(!isUndefined(value) &&
                  (isString(value) ? (value as string).length : true)
                    ? {
                        size: 14,
                        text: value,
                        color: COLORS.secondary,
                      }
                    : {
                        size: 14,
                        text: trans("general.no_value"),
                        color: COLORS.secondary,
                      }),
                }}
              />
            )}
          </Flex>
        );
      })}
    </div>
  );
};

export default AccommodationSettings;
