import React, { useContext } from "react";
import DataTypeToggle from "../../../components/DataTypeToggle";
import { useDataTypeToggle } from "../../../components/DataTypeToggle/useDataTypeToggle";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import WithContextProvider from "../../../hoc/withProvider";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { COLORS } from "../../../utils/colors";
import { DATA_TYPE_TOGGLE_LIVE } from "../../../utils/dataTypeToggle";
import Archives from "./archives";
import OccupancyContextProvider, { OccupancyContext } from "./context";
import Manual from "./manual";

const OccupancyGuarded: React.FC = () => {
  const { activeSubscriptionIsStarter } = useHotelSubscriptionState();
  const context = useContext(OccupancyContext);
  const profilePermissions = useProfilePermissions();
  const dataTypeState = useDataTypeToggle();

  if (activeSubscriptionIsStarter)
    return (
      <Archives
        dataTypeToggle={<></>}
        profilePermissions={profilePermissions}
      />
    );

  const dataTypeToggle = (
    <DataTypeToggle
      {...{ dataTypeState }}
      loading={
        dataTypeState.isLoading ||
        Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
      }
    />
  );

  if (!dataTypeState.isResolved) return null;

  if (dataTypeState.dataType === DATA_TYPE_TOGGLE_LIVE)
    return <Manual {...{ dataTypeToggle }} />;

  return <Archives {...{ dataTypeToggle, profilePermissions }} />;
};

const Occupancy: React.FC = () => {
  const { trans } = useLocalizationState();
  return (
    <>
      <TopBar>
        <InterTag
          text={trans("general.occupancy")}
          bold
          color={COLORS.secondary}
          size={24}
        />
      </TopBar>
      <IpValidatorGuard>
        <OccupancyGuarded />
      </IpValidatorGuard>
    </>
  );
};

export default WithContextProvider(OccupancyContextProvider, Occupancy);
