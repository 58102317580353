import axios from "axios";
import { find, toNumber } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useReducer } from "react";
import {
  DatePicker,
  InputGroup,
  InputNumber,
  Modal,
  ModalProps,
  SelectPicker,
  useToaster,
} from "rsuite";
import Modal2ActionButtons from "../../../../components/Buttons/modal2ActionButtons";
import Flex from "../../../../components/Flex";
import InputWrapper from "../../../../components/InputWrapper";
import PageSectionTitle from "../../../../components/PageSectionTitle";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useApiRequest from "../../../../hooks/apiRequests/useApiRequest";
import { tHotelFleet } from "../../../../models/hotel";
import { apiAddress } from "../../../../utils/apiCall";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { notification } from "../../../../utils/notifications";
import { DEFAULT_MODAL_PROPS } from "../../../../utils/rsuite/modals";

type tData = {
  fleetId: tHotelFleet["_id"];
  distanceKm: number;
  fuelLiters: number;
  start: Date | null;
  end: Date | null;
};

type tErrors = Record<keyof tData, string>;

type tState = {
  data: tData;
  errors: tErrors;
};

type tAction =
  | { type: "reset" }
  | { type: "errors"; errors: Partial<tErrors> }
  | { type: "data"; data: Partial<tData> }
  | { type: "key value"; key: keyof tData; value: any };

const initialState: tState = {
  data: {
    fleetId: "",
    distanceKm: 0,
    fuelLiters: 0,
    start: null,
    end: null,
  },
  errors: {
    fleetId: "",
    distanceKm: "",
    fuelLiters: "",
    end: "",
    start: "",
  },
};

const reducer = (state: tState, action: tAction): tState => {
  switch (action.type) {
    case "reset": {
      return { ...initialState };
    }
    case "errors": {
      const { errors } = action;
      return { ...state, errors: { ...state.errors, ...errors } };
    }
    case "data": {
      const { data } = action;
      return {
        ...state,
        errors: {
          ...state.errors,
          ...Object.fromEntries(Object.keys(data).map((key) => [key, ""])),
        },
        data: { ...state.data, ...data },
      };
    }
    case "key value": {
      const { key, value } = action;
      return {
        ...state,
        errors: { ...state.errors, [key]: "" },
        data: { ...state.data, [key]: value },
      };
    }
    default: {
      return { ...state };
    }
  }
};

interface iEntriesModalWrappedProps
  extends Pick<iProps, "onClose" | "fleetId" | "entryId"> {}

const EntriesModalWrapped: React.FC<iEntriesModalWrappedProps> = ({
  onClose,
  fleetId,
  entryId,
}) => {
  const toaster = useToaster();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { updateHotel } = useHotelDispatch();
  const { hotelId, hotel, hotelIsLoaded } = useHotelState();
  const { trans } = useLocalizationState();
  const request = useApiRequest();

  useEffect(() => {
    if (fleetId && entryId) {
      if (hotelIsLoaded) {
        const fleetRecord = find(hotel.fleet, (f) => f._id === fleetId);

        if (fleetRecord) {
          const entry = find(fleetRecord.entries, (e) => e._id === entryId);

          if (entry)
            dispatch({
              type: "data",
              data: {
                ...entry,
                start: moment(entry.start).toDate(),
                end: moment(entry.end).toDate(),
                fleetId,
              },
            });
        }
      }
    } else if (fleetId)
      dispatch({ type: "key value", key: "fleetId", value: fleetId });
  }, [entryId, fleetId, hotel.fleet, hotelIsLoaded]);

  const [title, confirmLabel] = useMemo(() => {
    if (entryId) return [trans("Update Entry"), trans("Update")];
    return [trans("Insert Entry"), trans("Insert")];
  }, [entryId, trans]);

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    request.pending();

    const { distanceKm, fuelLiters, start, end } = state.data;
    const data = {
      distanceKm: toNumber(distanceKm),
      fuelLiters: toNumber(fuelLiters),
      start: moment(start).valueOf(),
      end: moment(end).valueOf(),
      driverName: "",
      driverId: "",
    };

    (entryId
      ? axios.put(
          `${apiAddress(false)}/v2/hotels/${hotelId}/fleet/${
            state.data.fleetId
          }/entries/${entryId}`,
          data
        )
      : axios.post(
          `${apiAddress(false)}/v2/hotels/${hotelId}/fleet/${
            state.data.fleetId
          }/entries`,
          data
        )
    )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        handleClose();
        request.resolve();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        toaster.push(notification("error", error), { placement: "topEnd" });
        request.reject(error);
      });
  };

  const handleChange = (key: keyof tData) => (value: any) => {
    dispatch({ type: "key value", key, value });
  };

  return (
    <Modal.Body className="modal-body-pb0">
      <Flex column gap={20}>
        <PageSectionTitle title={title} />
        <InputWrapper label={trans("Vehicle")}>
          <SelectPicker
            disabled={request.isLoading}
            cleanable={false}
            onChange={handleChange("fleetId")}
            value={state.data.fleetId}
            data={hotel.fleet.map((f) => ({
              value: f._id,
              label: (
                <Flex row gap={8}>
                  <span>{f.licensePlate}</span>/
                  <span>{`${f.vehicle.make} ${f.vehicle.model}`}</span>
                </Flex>
              ),
            }))}
          />
        </InputWrapper>
        <InputWrapper label={trans("Timeframe")}>
          <InputGroup style={{ width: "100%" }}>
            <DatePicker
              disabled={request.isLoading}
              format="yyyy-MM-dd HH:mm:ss"
              block
              appearance="subtle"
              style={{ width: "45%" }}
              value={state.data.start}
              onChange={handleChange("start")}
            />
            <InputGroup.Addon style={{ width: "10%" }}>
              <div style={{ width: "100%", textAlign: "center" }}>
                {trans("to")}
              </div>
            </InputGroup.Addon>
            <DatePicker
              disabled={request.isLoading}
              format="yyyy-MM-dd HH:mm:ss"
              block
              appearance="subtle"
              style={{ width: "45%" }}
              value={state.data.end}
              onChange={handleChange("end")}
            />
          </InputGroup>
        </InputWrapper>
        <InputWrapper label={trans("Distance")}>
          <InputGroup>
            <InputNumber
              disabled={request.isLoading}
              min={0}
              value={state.data.distanceKm}
              placeholder={"Distance Travelled"}
              onChange={(value) => {
                if (value !== null) handleChange("distanceKm")(value);
              }}
            />
            <InputGroup.Addon>km</InputGroup.Addon>
          </InputGroup>
        </InputWrapper>
        <InputWrapper label={trans("Fuel Spent")}>
          <InputGroup>
            <InputNumber
              disabled={request.isLoading}
              min={0}
              value={state.data.fuelLiters}
              placeholder={"Total Fuel Spent"}
              onChange={(value) => {
                if (value !== null) handleChange("fuelLiters")(value);
              }}
            />
            <InputGroup.Addon>{trans("Liters")}</InputGroup.Addon>
          </InputGroup>
        </InputWrapper>
        <Modal2ActionButtons
          isLoading={request.isLoading}
          onClose={handleClose}
          onConfirm={handleConfirm}
          confirmLabel={confirmLabel}
        />
      </Flex>
    </Modal.Body>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  entryId?: tHotelFleet["entries"][0]["_id"];
  fleetId?: tHotelFleet["_id"];
}

const EntriesModal: React.FC<iProps> = ({
  onClose,
  fleetId,
  entryId,
  ...props
}) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, onClose, ...props }}>
      {props.open && <EntriesModalWrapped {...{ onClose, entryId, fleetId }} />}
    </Modal>
  );
};

export default EntriesModal;
