import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import ManualDataModal from "../../../components/Modals/ManualDataModal";
import PageBottomPadding from "../../../components/PageBottomPadding";
import RoadmapTable from "../../../components/RoadmapTable";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { COLORS } from "../../../utils/colors";
import Graph from "./graph";
import List from "./list";
import Unlock from "./unlock";

const WasteWrapped2: React.FC = () => {
  return (
    <Flex column gap={40}>
      <Graph />
      <List />
      <RoadmapTable filterByType="waste" />
    </Flex>
  );
};

const WasteWrapped: React.FC = () => {
  const { trans } = useLocalizationState();
  const profilePermissions = useProfilePermissions();
  const [manualDataModal, setManualDataModal] = useState({ open: false });

  return (
    <div>
      {profilePermissions.writeManualData && (
        <ManualDataModal
          open={manualDataModal.open}
          initialStep="waste"
          singleStep
          onClose={() => setManualDataModal({ open: false })}
        />
      )}
      <div
        style={{
          position: "fixed",
          top: 70,
          width: "100%",
          zIndex: 3,
          paddingBottom: "8px",
          backgroundColor: COLORS.app_background,
        }}
      >
        <Flex row middle gap={12} style={{ height: "36px" }}>
          {profilePermissions.writeManualData && (
            <Button
              appearance="primary"
              onClick={() => setManualDataModal({ open: true })}
            >
              {trans("components.manual_data.global_add_entry.label")}
            </Button>
          )}
        </Flex>
      </div>
      <div style={{ marginTop: "40px" }}>
        <WasteWrapped2 />
      </div>
    </div>
  );
};

const WasteGuarded: React.FC = () => {
  const { activeSubscriptionType } = useHotelSubscriptionState();

  if (activeSubscriptionType === "starter") return <Unlock />;

  return <WasteWrapped />;
};

const Waste: React.FC = () => {
  const { trans } = useLocalizationState();
  const navigate = useNavigate();

  return (
    <div>
      <TopBar>
        <Flex row gap={12}>
          <Flex gap={8} column style={{ height: "100%" }}>
            <InterTag
              hoverUnderline
              onClick={() => {
                navigate("/consumption");
              }}
              text={trans("general.consumption")}
              size={12}
              color={COLORS.secondary}
            />
            <InterTag
              text={trans("general.waste")}
              size={24}
              bold
              color={COLORS.secondary}
            />
          </Flex>
        </Flex>
      </TopBar>
      <IpValidatorGuard>
        <div>
          <WasteGuarded />
          <PageBottomPadding />
        </div>
      </IpValidatorGuard>
    </div>
  );
};

export default Waste;
