import { find, round } from "lodash";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "rsuite";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import { ReactComponent as DomainIcon } from "../../../../assets/icons/domain.svg";
import { ReactComponent as EcoIcon } from "../../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as ModeHeatIcon } from "../../../../assets/icons/mode_heat.svg";
import { ReactComponent as PaymentsIcon } from "../../../../assets/icons/payments.svg";
import { ReactComponent as WaterDropIcon } from "../../../../assets/icons/water_drop.svg";
import Flex from "../../../../components/Flex";
import Icon from "../../../../components/Icon";
import PageSectionTitle from "../../../../components/PageSectionTitle";
import SkeletonText from "../../../../components/Skeleton/SkeletonText";
import InterTag from "../../../../components/Text/Inter";
import useHotelsListState from "../../../../context/Hotel/hooks/hotelState/useHotelsListState";
import { nHotel } from "../../../../context/Hotel/interfaces";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useProfileDispatch from "../../../../context/Profile/hooks/useProfileDispatch";
import useGetDefaultConverionFactors from "../../../../hooks/useGetDefaultConverionFactors";
import { nRequestStatus } from "../../../../interfaces/apiCalls";
import { tIconElement } from "../../../../interfaces/icon";
import { REQUEST_STATUS } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import {
  convertEnergyUnit,
  convertEuro,
  convertMassUnit,
  convertVolumeUnit,
} from "../../../../utils/convertUnits";
import { nUnitConverter } from "../../../../utils/convertUnits/interfaces";
import { thousandsSeparation } from "../../../../utils/numbers";
import { renderRequestedData } from "../../../../utils/render";

interface iHotelCardProps {
  hotel: nHotel.tHotelAugmented;
  period: [Date, Date];
}

const HotelCard: React.FC<iHotelCardProps> = ({ hotel, period }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { setActiveHotel } = useProfileDispatch();
  const [activeSlide, setActiveSlide] = useState(0);
  const factorsRequest = useGetDefaultConverionFactors("pt", {
    doRequest: hotel.ipValidated,
  });

  const {
    totalElectricityKWh,
    totalWaterM3,
    totalFuelsKWh,
    totalFootprintKg,
    totalPrice,
    totalWasteKg,
  } = useMemo(() => {
    const selectedManualData = find(hotel.manualData, (md) =>
      moment(md.from).isSame(moment(period[0]))
    );

    if (!selectedManualData || !hotel.ipValidated)
      return {
        totalElectricityKWh: 0,
        totalWaterM3: 0,
        totalFuelsKWh: 0,
        totalFootprintKg: 0,
        totalPrice: 0,
        totalWasteKg: 0,
      };

    const {
      water,
      electricity,
      butane,
      propane,
      biomass,
      ethanol,
      diesel,
      gasoline,
      naturalGas,
    } = selectedManualData;
    const totalElectricityKWh = electricity?.totalKWh || 0;
    const totalElectricityPrice = electricity?.price || 0;
    const totalWaterM3 = water?.totalM3 || 0;
    const totalWaterPrice = water?.price || 0;

    const totalButaneKWh = butane?.totalKWh || 0;
    const totalButaneFootprint = butane?.totalKWh
      ? butane?.totalKWh * factorsRequest.data.butane_kWh_to_kgCO2e
      : 0;
    const totalButanePrice = butane?.price || 0;

    const totalPropaneKWh = propane?.totalKWh || 0;
    const totalPropaneFootprint = propane?.totalKWh
      ? propane?.totalKWh * factorsRequest.data.propane_kWh_to_kgCO2e
      : 0;
    const totalPropanePrice = propane?.price || 0;

    const totalBiomassKWh = biomass?.totalKWh || 0;
    const totalBiomassFootprint = biomass?.totalKWh
      ? biomass?.totalKWh * factorsRequest.data.biomass_kWh_to_kgCO2e
      : 0;
    const totalBiomassPrice = biomass?.price || 0;

    const totalEthanolKWh = ethanol?.totalKWh || 0;
    const totalEthanolFootprint = ethanol?.totalKWh
      ? ethanol?.totalKWh * factorsRequest.data.ethanol_kWh_to_kgCO2e
      : 0;
    const totalEthanolPrice = ethanol?.price || 0;

    const totalDieselKWh = diesel?.totalKWh || 0;
    const totalDieselFootprint = diesel?.totalKWh
      ? diesel?.totalKWh * factorsRequest.data.diesel_kWh_to_kgCO2e
      : 0;
    const totalDieselPrice = diesel?.price || 0;

    const totalGasolineKWh = gasoline?.totalKWh || 0;
    const totalGasolineFootprint = gasoline?.totalKWh
      ? gasoline?.totalKWh * factorsRequest.data.gasoline_kWh_to_kgCO2e
      : 0;
    const totalGasolinePrice = gasoline?.price || 0;

    const totalNaturalGasKWh = naturalGas?.totalKWh || 0;
    const totalNaturalGasFootprint = naturalGas?.totalKWh
      ? naturalGas?.totalKWh * factorsRequest.data.natural_gas_kWh_to_kgCO2e
      : 0;
    const totalNaturalGasPrice = naturalGas?.price || 0;

    const totalFuelsKWh =
      totalButaneKWh +
      totalPropaneKWh +
      totalBiomassKWh +
      totalEthanolKWh +
      totalDieselKWh +
      totalGasolineKWh +
      totalNaturalGasKWh;

    const totalFootprintKg =
      totalElectricityKWh * factorsRequest.data.electricity_kWh_to_kgCO2e +
      totalWaterM3 * factorsRequest.data.water_m3_to_kgCO2e +
      totalNaturalGasFootprint +
      totalGasolineFootprint +
      totalDieselFootprint +
      totalEthanolFootprint +
      totalBiomassFootprint +
      totalPropaneFootprint +
      totalButaneFootprint;

    const totalPrice =
      totalElectricityPrice +
      totalWaterPrice +
      totalButanePrice +
      totalPropanePrice +
      totalBiomassPrice +
      totalEthanolPrice +
      totalDieselPrice +
      totalGasolinePrice +
      totalNaturalGasPrice;

    return {
      totalElectricityKWh,
      totalWaterM3,
      totalFuelsKWh,
      totalFootprintKg,
      totalPrice,
      totalWasteKg: 0,
    };
  }, [factorsRequest.data, hotel.ipValidated, hotel.manualData, period]);

  if (!hotel.ipValidated) {
    return (
      <div style={{ width: "420px" }}>
        <Flex
          column
          color={COLORS.white}
          className="hover-box-shadow"
          style={{ padding: "16px", borderRadius: "8px", cursor: "pointer" }}
          onClick={(e: any) => {
            setActiveHotel(hotel._id);
            setTimeout(() => {
              navigate("/overview");
            }, 200);
          }}
        >
          <Flex column gap={16}>
            <Flex row gap={16}>
              {hotel.images.length > 0 && (
                <img
                  style={{ borderRadius: "8px" }}
                  src={hotel.images[0]}
                  width={"96px"}
                  height={"96px"}
                  alt={hotel.name}
                />
              )}
              <Flex column gap={4}>
                <InterTag
                  text={hotel.name}
                  colro={COLORS.secondary}
                  size={20}
                />
                <InterTag
                  text={[
                    hotel.location.county,
                    hotel.location.district,
                    hotel.location.country,
                  ]
                    .filter((v) => v)
                    .join(", ")}
                  color={COLORS.gray_400}
                  size={14}
                />
              </Flex>
            </Flex>
            <InterTag
              color={COLORS.error}
              size={14}
              text={trans("Not permitted to access via this IP address")}
            />
          </Flex>
        </Flex>
      </div>
    );
  }

  const selectedManualData = find(hotel.manualData, (md) =>
    moment(md.from).isSame(moment(period[0]))
  );
  if (!selectedManualData)
    return (
      <div style={{ width: "420px" }}>
        <Flex
          column
          color={COLORS.white}
          className="hover-box-shadow"
          style={{ padding: "16px", borderRadius: "8px", cursor: "pointer" }}
          onClick={(e: any) => {
            setActiveHotel(hotel._id);
            setTimeout(() => {
              navigate("/overview");
            }, 200);
          }}
        >
          <Flex column gap={16}>
            <Flex row gap={16}>
              {hotel.images.length > 0 && (
                <img
                  style={{ borderRadius: "8px" }}
                  src={hotel.images[0]}
                  width={"96px"}
                  height={"96px"}
                  alt={hotel.name}
                />
              )}
              <Flex column gap={4}>
                <InterTag
                  text={hotel.name}
                  colro={COLORS.secondary}
                  size={20}
                />
                <InterTag
                  text={[
                    hotel.location.county,
                    hotel.location.district,
                    hotel.location.country,
                  ]
                    .filter((v) => v)
                    .join(", ")}
                  color={COLORS.gray_400}
                  size={14}
                />
              </Flex>
            </Flex>
            <InterTag
              color={COLORS.secondary}
              size={14}
              text={trans("No data available in this period.")}
            />
          </Flex>
        </Flex>
      </div>
    );

  const renderSlide1 = () => {
    const renderValues = () => {
      const list: {
        key: "co2" | "costs";
        label: string;
        icon: tIconElement;
        converter: nUnitConverter.tFunction;
        status: nRequestStatus.tStatus;
        value: number;
        unit: string;
      }[] = [
        {
          key: "co2",
          label: trans("CO<sub>2</sub>"),
          icon: EcoIcon,
          converter: convertMassUnit,
          status: factorsRequest.status,
          value: totalFootprintKg,
          unit: "kg",
        },
        {
          key: "costs",
          label: trans("Costs"),
          icon: PaymentsIcon,
          converter: convertEuro,
          status: REQUEST_STATUS.RESOLVED,
          value: totalPrice,
          unit: "€",
        },
      ];

      return (
        <Flex center gap={40}>
          {list.map(({ icon, key, label, converter, status, value, unit }) => {
            const renderValue = () => {
              const converted = converter(value, {
                from: unit,
                forceUnit: "",
              });

              return (
                <Flex row gap={4} bottom>
                  <InterTag
                    size={36}
                    text={thousandsSeparation(round(converted.value, 2))}
                    color={COLORS.secondary}
                  />
                  <InterTag
                    size={16}
                    text={converted.unitInHTML}
                    asHTML
                    color={COLORS.secondary}
                  />
                </Flex>
              );
            };

            return (
              <Flex column key={key} gap={8} middle>
                <Flex row gap={4} middle>
                  <Icon Element={icon} size={24} color={COLORS.secondary} />
                  <InterTag
                    text={label}
                    size={20}
                    asHTML
                    color={COLORS.secondary}
                  />
                </Flex>

                <div>
                  {renderRequestedData(status, {
                    pending: () => <SkeletonText height={36} width={80} />,
                    resolved: renderValue,
                  })}
                </div>
              </Flex>
            );
          })}
        </Flex>
      );
    };
    return (
      <Flex gap={16} column>
        <Flex row gap={16}>
          {hotel.images.length > 0 && (
            <img
              style={{ borderRadius: "8px" }}
              src={hotel.images[0]}
              width={"96px"}
              height={"96px"}
              alt={hotel.name}
            />
          )}
          <Flex column gap={4}>
            <InterTag text={hotel.name} colro={COLORS.secondary} size={20} />
            <InterTag
              text={[
                hotel.location.county,
                hotel.location.district,
                hotel.location.country,
              ]
                .filter((v) => v)
                .join(", ")}
              color={COLORS.gray_400}
              size={14}
            />
          </Flex>
        </Flex>
        {renderValues()}
      </Flex>
    );
  };

  const renderSlide2 = () => {
    const renderValues = () => {
      const list: {
        key: string;
        label: string;
        icon: tIconElement;
        converter: nUnitConverter.tFunction;
        value: number;
        unit: string;
        status: nRequestStatus.tStatus;
      }[] = [
        {
          key: "co2",
          label: trans("Footprint"),
          icon: EcoIcon,
          converter: convertMassUnit,
          value: totalFootprintKg,
          unit: "kg",
          status: factorsRequest.status,
        },
        {
          key: "costs",
          label: trans("Costs"),
          icon: PaymentsIcon,
          converter: convertEuro,
          value: totalPrice,
          unit: "€",
          status: REQUEST_STATUS.RESOLVED,
        },
        {
          key: "electricity",
          label: trans("Electricity"),
          icon: ElectricBoltIcon,
          converter: convertEnergyUnit,
          value: totalElectricityKWh,
          unit: "kWh",
          status: REQUEST_STATUS.RESOLVED,
        },
        {
          key: "water",
          label: trans("Water"),
          icon: WaterDropIcon,
          converter: convertVolumeUnit,
          value: totalWaterM3,
          unit: "m3",
          status: REQUEST_STATUS.RESOLVED,
        },
        {
          key: "fuels",
          label: trans("Fuels"),
          icon: ModeHeatIcon,
          converter: convertEnergyUnit,
          value: totalFuelsKWh,
          unit: "kWh",
          status: REQUEST_STATUS.RESOLVED,
        },
        {
          key: "waste",
          label: trans("Waste"),
          icon: DeleteIcon,
          converter: convertMassUnit,
          value: totalWasteKg,
          unit: "kg",
          status: REQUEST_STATUS.RESOLVED,
        },
      ];
      return (
        <Flex column gap={12} style={{ padding: "0 12px" }}>
          {list.map(({ icon, key, label, converter, value, unit, status }) => {
            const renderValue = () => {
              const converted = converter(value, { from: unit, forceUnit: "" });

              return (
                <Flex row gap={4} left>
                  <InterTag
                    size={14}
                    text={thousandsSeparation(round(converted.value, 2))}
                    color={COLORS.secondary}
                  />
                  <InterTag
                    size={14}
                    text={converted.unitInHTML}
                    asHTML
                    color={COLORS.secondary}
                  />
                </Flex>
              );
            };

            return (
              <Flex row key={key} between middle>
                <Flex one row gap={4} middle>
                  <Icon Element={icon} size={18} color={COLORS.secondary} />
                  <InterTag
                    text={label}
                    size={14}
                    asHTML
                    color={COLORS.secondary}
                  />
                </Flex>

                <Flex one>
                  {renderRequestedData(status, {
                    pending: () => <SkeletonText height={14} width={70} />,
                    resolved: () => renderValue(),
                  })}
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      );
    };

    return (
      <Flex gap={16} column>
        <Flex row gap={16}>
          <Flex column gap={4}>
            <InterTag text={hotel.name} colro={COLORS.secondary} size={20} />
            <InterTag
              text={[
                hotel.location.county,
                hotel.location.district,
                hotel.location.country,
              ]
                .filter((v) => v)
                .join(", ")}
              color={COLORS.gray_400}
              size={14}
            />
          </Flex>
        </Flex>
        {renderValues()}
      </Flex>
    );
  };

  return (
    <div style={{ width: "420px" }}>
      <Flex
        column
        color={COLORS.white}
        className="hover-box-shadow"
        style={{
          padding: "16px",
          paddingBottom: 0,
          borderRadius: "8px",
          cursor: "pointer",
        }}
        onClick={(e: any) => {
          setActiveHotel(hotel._id);
          setTimeout(() => {
            navigate("/overview");
          }, 200);
        }}
      >
        <Carousel
          onClick={(e: any) => {
            if (
              e.target.className.indexOf("rs-carousel-label") > -1 ||
              e.target.tagName.toLowerCase() === "input"
            ) {
              e.stopPropagation();
            }
          }}
          onSelect={(index, e) => {
            e.stopPropagation();
            setActiveSlide(index);
          }}
          activeIndex={activeSlide}
          shape="bar"
          style={{
            backgroundColor: "transparent",
            height: "250px",
          }}
        >
          {renderSlide1()}
          {renderSlide2()}
        </Carousel>
      </Flex>
    </div>
  );
};

interface iProps {
  period: [Date, Date];
}

const Archives: React.FC<iProps> = ({ period }) => {
  const { hotels } = useHotelsListState();
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <PageSectionTitle
        title={trans("pages.properties.sections.overview.title")}
        description={trans("pages.properties.sections.overview.description")}
        icon={DomainIcon}
      />
      <Flex wrap gap={24}>
        {hotels.map((hotel) => (
          <HotelCard key={hotel._id} {...{ hotel, period }} />
        ))}
      </Flex>
    </Flex>
  );
};

export default Archives;
