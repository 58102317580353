import React, { useContext } from "react";
import { ReactComponent as QueryStatsIcon } from "../../../../assets/icons/query_stats.svg";
import { ReactComponent as WaterDropIcon } from "../../../../assets/icons/water_drop.svg";
import ConsumptionPageSpecific from "../../../../components/ConsumptionPageSpecific";
import Flex from "../../../../components/Flex";
import PageBottomPadding from "../../../../components/PageBottomPadding";
import SimpleDateRangePicker from "../../../../components/RsuiteWrapper/SimpleDateRangePicker";
import InterTag from "../../../../components/Text/Inter";
import useHotelState from "../../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import usePeriodState from "../../../../hooks/usePeriodState";
import { TW } from "../../../../models/measures";
import { REQUEST_STATUS } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import {
  convertVolumeUnit,
  FORCE_WATER_MEASURE_UNIT,
} from "../../../../utils/convertUnits/";
import { ConsumptionContext } from "../../consumption/context";

const LiveData: React.FC<{ dataTypeToggle: JSX.Element | null }> = ({
  dataTypeToggle,
}) => {
  const { setDataTypeToggleDisabled } = useContext(ConsumptionContext);
  const { trans } = useLocalizationState();
  const {
    getMeasuresTracked,
    hotel: { spaces, waterToCurrency, waterToFootprint },
    hotelIsLoaded,
  } = useHotelState();
  const [period, setPeriod] = usePeriodState();

  const trackedMeasures = getMeasuresTracked(spaces.map((s) => s._id));

  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: 70,
          width: "100%",
          zIndex: 3,
          paddingBottom: "8px",
          backgroundColor: COLORS.app_background,
        }}
      >
        <Flex row gap={12} middle>
          {dataTypeToggle}
          <SimpleDateRangePicker value={period} onChange={setPeriod} />
        </Flex>
      </div>
      <div style={{ marginTop: "40px" }}>
        {hotelIsLoaded ? (
          trackedMeasures.tw ? (
            <ConsumptionPageSpecific
              measuresRequestStatusTracker={(status) =>
                setDataTypeToggleDisabled({
                  electricityMeasuresRequest: status === REQUEST_STATUS.PENDING,
                })
              }
              {...{
                period,
                mainMeasureKey: TW,
                overviewSection: {
                  title: trans("pages.water.live.sections.overview.title"),
                  description: trans(
                    "pages.water.live.sections.overview.description"
                  ),
                  icon: QueryStatsIcon,
                },
                spaceListSection: {
                  title: trans("pages.water.live.sections.list.title"),
                  description: trans(
                    "pages.water.live.sections.list.description"
                  ),
                  icon: WaterDropIcon,
                },
                converter: convertVolumeUnit,
                roadmapFilter: "water",
                tableHeader: {
                  icon: WaterDropIcon,
                  name: trans("general.measures.water"),
                },
                toCurrency: waterToCurrency,
                toFootprint: waterToFootprint,
                mainUnit: FORCE_WATER_MEASURE_UNIT,
              }}
            />
          ) : (
            <Flex>
              <InterTag
                text={trans("pages.water.live.not_measured")}
                size={16}
                color={COLORS.secondary}
              />
            </Flex>
          )
        ) : null}
        <PageBottomPadding />
      </div>
    </div>
  );
};

export default LiveData;
