import moment from "moment";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ElectricBoltIcon } from "../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as WaterDropIcon } from "../../../../assets/icons/water_drop.svg";
import Flex from "../../../../components/Flex";
import Icon from "../../../../components/Icon";
import { toIconProps } from "../../../../components/Icon/utils";
import MeasureConsumption from "../../../../components/MeasureConsumption";
import PageSectionTitle from "../../../../components/PageSectionTitle";
import SimpleDateRangePicker from "../../../../components/RsuiteWrapper/SimpleDateRangePicker";
import InterTag from "../../../../components/Text/Inter";
import useHotelMeasuresTrackedState from "../../../../context/Hotel/hooks/hotelState/useHotelMeasuresTrackedState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import usePeriodState from "../../../../hooks/usePeriodState";
import { tIcon, tIconElement } from "../../../../interfaces/icon";
import {
  AC,
  CW,
  EL,
  ES,
  GW,
  HW,
  TE,
  tMeasureEnergySpecific,
  tMeasureWaterSpecific,
  TW,
  WH,
} from "../../../../models/measures";
import { REQUEST_STATUS } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import {
  convertEnergyUnit,
  convertVolumeUnit,
  DEFAULT_ENERGY_MEASURE_UNIT,
  DEFAULT_WATER_MEASURE_UNIT,
} from "../../../../utils/convertUnits/";
import { getMeasureInfo } from "../../../../utils/measures";
import { ConsumptionContext } from "../context";

const LiveData: React.FC<{ dataTypeToggle: JSX.Element }> = ({
  dataTypeToggle,
}) => {
  const navigate = useNavigate();
  const { setDataTypeToggleDisabled } = useContext(ConsumptionContext);
  const { trans } = useLocalizationState();
  const {
    getMeasuresTracked,
    hotel: { spaces },
    hotelIsLoaded,
    hotelLoading,
    errorLoading,
  } = useHotelMeasuresTrackedState();
  const [period, setPeriod] = usePeriodState();

  const trackedMeasures = getMeasuresTracked(spaces.map((s) => s._id));

  if (hotelLoading) return null;

  if (errorLoading) return null;

  if (hotelIsLoaded) {
    const electricitySpecificMeasures: {
      measureKey: tMeasureEnergySpecific;
    }[] = [];
    const waterSpecificMeasures: {
      measureKey: tMeasureWaterSpecific;
    }[] = [];

    const measurementCards: {
      label: string;
      icon: tIconElement | tIcon;
      to: string;
    }[] = [];
    if (trackedMeasures.te) {
      measurementCards.push({
        label: trans("general.measures.electricity"),
        icon: { Element: ElectricBoltIcon, fill: COLORS.energy },
        to: "/consumption/electricity",
      });
      if (trackedMeasures.es)
        electricitySpecificMeasures.push({ measureKey: ES });
      if (trackedMeasures.el)
        electricitySpecificMeasures.push({ measureKey: EL });
      if (trackedMeasures.ac)
        electricitySpecificMeasures.push({ measureKey: AC });
      if (trackedMeasures.wh)
        electricitySpecificMeasures.push({ measureKey: WH });
    }
    if (trackedMeasures.tw) {
      measurementCards.push({
        label: trans("general.measures.water"),
        icon: { Element: WaterDropIcon, fill: COLORS.water },
        to: "/consumption/water",
      });

      if (trackedMeasures.hw) waterSpecificMeasures.push({ measureKey: HW });
      if (trackedMeasures.gw) waterSpecificMeasures.push({ measureKey: GW });
      if (trackedMeasures.cw) waterSpecificMeasures.push({ measureKey: CW });
    }

    return (
      <div>
        <div
          style={{
            position: "fixed",
            zIndex: 3,
            top: 70,
            width: "100%",
            backgroundColor: COLORS.app_background,
            paddingBottom: "8px",
          }}
        >
          <Flex row middle gap={16}>
            {dataTypeToggle}
            <SimpleDateRangePicker onChange={setPeriod} value={period} />
            {measurementCards.map(({ label, icon, to }) => {
              return (
                <Flex
                  key={label}
                  onClick={() => {
                    navigate(to);
                  }}
                  className={"hover-box-shadow"}
                  row
                  gap={6}
                  color={COLORS.white}
                  middle
                  style={{
                    cursor: "pointer",
                    padding: "0 16px",
                    height: "34px",
                    borderRadius: "8px",
                  }}
                >
                  <Icon
                    {...{
                      size: 24,
                      fill: COLORS.secondary,
                      ...toIconProps(icon),
                    }}
                  />
                  <InterTag text={label} size={14} color={COLORS.secondary} />
                </Flex>
              );
            })}
          </Flex>
        </div>
        <Flex style={{ marginTop: "40px" }} column gap={40}>
          {trackedMeasures.te && (
            <Flex column gap={16}>
              <PageSectionTitle
                {...{
                  title: trans("pages.consumption.live.electricity.title"),
                  description: trans(
                    "pages.consumption.live.electricity.description"
                  ),
                  icon: {
                    Element: ElectricBoltIcon,
                    fill: getMeasureInfo(TE).color,
                  },
                }}
              />
              <MeasureConsumption
                measuresRequestStatusTracker={(status) =>
                  setDataTypeToggleDisabled({
                    electricityMeasuresRequest:
                      status === REQUEST_STATUS.PENDING,
                  })
                }
                mainMeasureKey={TE}
                specificMeasures={electricitySpecificMeasures}
                converter={convertEnergyUnit}
                binUnit="day"
                binValue={1}
                mainUnit={DEFAULT_ENERGY_MEASURE_UNIT}
                {...{
                  ...(period
                    ? { from: period[0], to: period[1] }
                    : {
                        from: moment().startOf("day"),
                        to: moment().endOf("day"),
                      }),
                }}
              />
            </Flex>
          )}
          {trackedMeasures.tw && (
            <Flex column gap={16}>
              <PageSectionTitle
                {...{
                  title: trans("pages.consumption.live.water.title"),
                  description: trans(
                    "pages.consumption.live.water.description"
                  ),
                  icon: {
                    Element: WaterDropIcon,
                    fill: getMeasureInfo(TW).color,
                  },
                }}
              />
              <MeasureConsumption
                measuresRequestStatusTracker={(status) =>
                  setDataTypeToggleDisabled({
                    waterMeasuresRequest: status === REQUEST_STATUS.PENDING,
                  })
                }
                mainMeasureKey={TW}
                specificMeasures={waterSpecificMeasures}
                converter={convertVolumeUnit}
                binUnit="day"
                binValue={1}
                mainUnit={DEFAULT_WATER_MEASURE_UNIT}
                {...{
                  ...(period
                    ? { from: period[0], to: period[1] }
                    : {
                        from: moment().startOf("day"),
                        to: moment().endOf("day"),
                      }),
                }}
              />
            </Flex>
          )}
        </Flex>
      </div>
    );
  }

  return null;
};

export default LiveData;
