import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as DateRangeIcon } from "../../../assets/icons/date_range.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../assets/icons/meeting_room.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import PageBottomPadding from "../../../components/PageBottomPadding";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useEffectSafe from "../../../hooks/useEffectSafe";
import { tReservation } from "../../../models/reservation";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import AverageConsumption from "./averageConsumption";
import Awards from "./awards";
import GuestInformation from "./guestInformation";

type tReservationState = {
  reservation: tReservation;
  loading: boolean;
  error: string | null;
  fromApi: boolean;
};

const ReservationWrapped: React.FC = () => {
  const { trans } = useLocalizationState();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ idCode: string }>();
  const { findSpace } = useHotelState();

  const [reservationState, setReservationState] = useState<tReservationState>({
    reservation: location.state.reservation,
    loading: false,
    error: null,
    fromApi: false,
  });

  useEffectSafe(() => {
    if (reservationState.fromApi === false) {
      setReservationState((prev) => ({ ...prev, loading: true }));
      axios
        .get(`${apiAddress(false)}/v2/stays/${params.idCode}/consumption`)
        .then((res) => {
          const {
            data: { reservation },
          } = res;
          setReservationState({
            reservation,
            loading: false,
            error: null,
            fromApi: true,
          });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          setReservationState((prev) => ({
            ...prev,
            loading: false,
            error,
            fromApi: false,
          }));
        });
    }
  }, [params.idCode, reservationState.fromApi, trans]);

  const {
    reservation,
    reservation: {
      code,
      room,
      startAt,
      endAt,
      mainGuest,
      additionalGuests,
      _id,
    },
  } = reservationState;

  const numberOfNights = moment(endAt)
    .endOf("day")
    .diff(moment(startAt).startOf("day"), "days");

  const space = findSpace(room);

  return (
    <>
      <TopBar>
        <Flex gap={8} column style={{ height: "100%" }}>
          <InterTag
            hoverUnderline
            onClick={() => {
              navigate("/guests");
            }}
            style={{ marginTop: "10px" }}
            text={trans("Stays")}
            size={12}
            color={COLORS.secondary}
          />
          <InterTag text={`${code}`} bold color={COLORS.secondary} size={24} />
        </Flex>
      </TopBar>
      <Flex column gap={30}>
        <Flex row gap={16}>
          <Link to={`/spaces/${room}`}>
            <Flex row gap={8} className="card-s hover-box-shadow">
              <Icon
                Element={MeetingRoomIcon}
                fill={COLORS.secondary}
                size={20}
              />
              <InterTag
                color={COLORS.secondary}
                text={
                  space ? space.name : trans("Space $0", { parameters: [room] })
                }
              />
            </Flex>
          </Link>
          <Flex row gap={8} className="card-s">
            <Icon Element={DateRangeIcon} fill={COLORS.secondary} size={20} />
            <InterTag
              color={COLORS.secondary}
              text={`${moment(startAt)
                .format("MMM DD, YYYY (HH:mm)")
                .toLowerCase()} - ${moment(endAt)
                .format("MMM DD, YYYY (HH:mm)")
                .toLowerCase()}`}
            />
          </Flex>
          <Flex row gap={8} className="card-s">
            <Icon Element={DateRangeIcon} fill={COLORS.secondary} size={20} />
            <InterTag
              color={COLORS.secondary}
              text={`${numberOfNights} ${trans("nights")}`}
            />
          </Flex>
        </Flex>
        <GuestInformation
          {...{
            reservationId: _id,
            mainGuest,
            additionalGuests,
          }}
        />
        <AverageConsumption reservation={reservation} />
        <Awards reservation={reservation} />
      </Flex>
    </>
  );
};

const Reservation: React.FC = () => {
  return (
    <div>
      <ReservationWrapped />
      <PageBottomPadding />
    </div>
  );
};

export default Reservation;
