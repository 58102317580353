import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, InputGroup, Loader } from "rsuite";
import { ReactComponent as MailIcon } from "../../../assets/icons/mail.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InputWrapper from "../../../components/InputWrapper";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import { COLORS } from "../../../utils/colors";
import axios from "axios";
import { apiAddress } from "../../../utils/apiCall";
import { getErrorMessage } from "../../../utils/httpResponses/others";

interface iProps {}

const ForgotPassword: React.FC<iProps> = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();

  const postRequest = usePostRequest();

  const [emailState, setEmailState] = useState({ value: "", error: "" });

  const handleSubmit = () => {
    if (!emailState.value.length) {
      setEmailState((prev) => ({
        ...prev,
        error: trans("pages.forgot_password.errors.no_email"),
      }));
      return;
    } else if (
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(emailState.value)
    ) {
      setEmailState((prev) => ({
        ...prev,
        error: trans("pages.forgot_password.errors.invalid_email"),
      }));
      return;
    }

    postRequest.pending();
    axios
      .post(`${apiAddress(true)}/v2/managers/forgot-password`, {
        email: emailState.value,
      })
      .then(() => {
        postRequest.resolve(
          {},
          trans("pages.forgot_password.notifications.done")
        );
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        postRequest.reject(error, true);
      });
  };

  return (
    <Fragment>
      {postRequest.isLoading && <Loader center backdrop size="lg" />}
      <Flex column middle gap={16}>
        <Flex
          column
          center
          style={{
            backgroundColor: COLORS.light_white,
            borderRadius: "16px",
            padding: "64px",
            width: "40%",
          }}
        >
          <Flex column center middle>
            <Flex gap={8} column middle>
              <InterTag
                bold
                text={trans("pages.forgot_password.title")}
                size={32}
                color={COLORS.secondary}
              />
              <InterTag
                wrap
                style={{ lineHeight: "20px" }}
                text={trans("pages.forgot_password.description")}
                size={16}
                color={COLORS.secondary}
              />
            </Flex>
            <div className="width-100" style={{ marginTop: "32px" }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Flex column gap={16}>
                  <Flex gap={4} className="width-100" column>
                    <InputWrapper
                      label={trans("general.email")}
                      error={emailState.error}
                    >
                      <InputGroup>
                        <InputGroup.Addon>
                          <Icon
                            Element={MailIcon}
                            width={24}
                            height={24}
                            fill={COLORS.secondary}
                          />
                        </InputGroup.Addon>
                        <Input
                          value={emailState.value}
                          onChange={(value) =>
                            setEmailState((prev) => ({
                              ...prev,
                              error: "",
                              value: value,
                            }))
                          }
                          type="text"
                          placeholder={trans("general.placeholders.email")}
                          size="lg"
                        />
                      </InputGroup>
                    </InputWrapper>
                  </Flex>
                </Flex>
                <input type="submit" hidden />
              </form>
            </div>
            <div className="width-100" style={{ marginTop: "24px" }}>
              <Button onClick={handleSubmit} block appearance="primary">
                <InterTag
                  text={trans("general.submit")}
                  bold
                  color={COLORS.white}
                  size={20}
                />
              </Button>
            </div>
          </Flex>
        </Flex>
        <Flex row gap={4} center>
          <InterTag
            color={COLORS.white}
            text={trans("pages.setup_password.signin")}
          />
          <InterTag
            color={COLORS.primary}
            text={trans("general.signin")}
            hoverUnderline
            onClick={() => {
              navigate("/login");
            }}
          />
        </Flex>
        <Flex row gap={4} center>
          <InterTag
            color={COLORS.white}
            text={trans("pages.forgot_password.signup[0]")}
          />
          <InterTag
            color={COLORS.primary}
            text={trans("pages.forgot_password.signup[1]")}
            hoverUnderline
            onClick={() => {
              navigate("/register");
            }}
          />
          <InterTag
            color={COLORS.white}
            text={trans("pages.forgot_password.signup[2]")}
          />
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default ForgotPassword;
