import React, { useRef } from "react";
import { Dropdown, DropdownProps } from "rsuite";
import { tIcon, tIconElement } from "../../../interfaces/icon";
import { COLORS } from "../../../utils/colors";
import Flex from "../../Flex";
import Icon from "../../Icon";
import { toIconProps } from "../../Icon/utils";
import { iTextProps } from "../../Text";
import InterTag from "../../Text/Inter";
import { toTextProps } from "../../Text/utils";

export type tDropdownOption = {
  icon?: tIcon | tIconElement;
  label: string | iTextProps;
  onClick?(): any;
  disabled?: boolean;
  key: string;
};

export interface iSimpleDropdownProps extends DropdownProps {
  options: tDropdownOption[];
  placement: DropdownProps["placement"];
  renderToggle: DropdownProps["renderToggle"];
}

const SimpleDropdown: React.FC<iSimpleDropdownProps> = ({
  options,
  placement,
  renderToggle,
  ...rest
}) => {
  const ref = useRef(null);

  return (
    <Dropdown
      style={{ padding: 0 }}
      {...{ renderToggle, placement, ref, ...rest }}
    >
      {options.map(({ label, icon, onClick, disabled, key }, i) => {
        const labelProps = toTextProps(label);
        return (
          <Dropdown.Item
            style={{ padding: "0 20px 0 20px" }}
            key={key}
            disabled={disabled}
            onSelect={(ek, e) => {
              onClick && onClick();

              if (ref.current) {
                // @ts-ignore
                // ref.current.close();
              }
            }}
          >
            <Flex
              column
              middle
              center
              style={{
                width: "100%",
              }}
            >
              {i ? (
                <div
                  style={{
                    width: "100%",
                    borderBottom: "1px solid var(--color-gray-200)",
                  }}
                />
              ) : (
                <></>
              )}
              <Flex
                row
                middle
                left
                style={{
                  height: "30px",
                  width: "100%",
                }}
                gap={16}
              >
                {icon && (
                  <Icon
                    {...{
                      fill: COLORS.secondary,
                      size: 16,
                      ...toIconProps(icon),
                    }}
                  />
                )}
                <Flex
                  middle
                  style={{
                    ...(disabled ? { opacity: 0.5 } : {}),
                  }}
                >
                  <InterTag
                    {...{ size: 12, fill: COLORS.secondary, ...labelProps }}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Dropdown.Item>
        );
      })}
    </Dropdown>
  );
};

export default SimpleDropdown;
