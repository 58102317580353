import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Grid, Row } from "rsuite";
import Flex from "../../../../components/Flex";
import SimpleDateRangePickerForManualDataPeriods2 from "../../../../components/RsuiteWrapper/SimpleDateRangePickerForManualDataPeriods2";
import useHotelSubscriptionState from "../../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import CheckInCheckOut from "../checkInCheckOut";
import ConsumptionCards from "./consumptionCards";
import OccupancyCard from "./occupancyCard";

interface iProps {
  dataTypeToggle: JSX.Element | null;
}

const Archives: React.FC<iProps> = ({ dataTypeToggle }) => {
  const {
    activeSubscriptionIsStarter,
    hotel: { manualData },
  } = useHotelSubscriptionState();
  const [manualDataIndex, setManualDataIndex] = useState(manualData.length - 1);

  useEffect(() => {
    if (manualDataIndex + 1 > manualData.length) setManualDataIndex(0);
  }, [manualData.length, manualDataIndex]);

  const selectedManualData = manualData[manualDataIndex];

  return (
    <Flex column gap={16}>
      <Flex row middle gap={12}>
        {dataTypeToggle}
        <SimpleDateRangePickerForManualDataPeriods2
          {...{ manualDataIndex, setManualDataIndex }}
        />
      </Flex>
      <div>
        <Grid fluid>
          <Row gutter={16}>
            {!activeSubscriptionIsStarter && (
              <Col sm={12} xxl={8}>
                <CheckInCheckOut
                  period={[
                    moment(selectedManualData.from).toDate(),
                    moment(selectedManualData.to).toDate(),
                  ]}
                />
              </Col>
            )}
            <Col sm={12} xxl={8}>
              <OccupancyCard manualDataEntry={selectedManualData} />
            </Col>
          </Row>
          <ConsumptionCards manualDataEntry={selectedManualData} />
        </Grid>
      </div>
    </Flex>
  );
};

export default Archives;
