import { every, extend, pick } from "lodash";
import React, { useCallback } from "react";
import { HotelStateContext } from "../..";
import {
  tHotelStaffPermissions,
  tHotelStaffRole,
} from "../../../../models/hotel";
import { tManagerId } from "../../../../models/manager";
import useHotelState from "./useHotelState";

const useHotelStaffState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error("useHotelStaffState must be used within HotelStateContext");

  const { errorLoading, hotel, hotelId, hotelIsLoaded, hotelLoading } =
    useHotelState();

  const getStaffRoles = useCallback(
    (staffId: tManagerId) => {
      return hotel.staffRoles.filter((r) => r.staff.includes(staffId));
    },
    [hotel.staffRoles]
  );

  const staffMemberHasPermissions = (
    staffId: tManagerId,
    permissions: (keyof tHotelStaffRole["permissions"])[]
  ) => {
    const roles = getStaffRoles(staffId);
    if (roles.length === 0) return false;

    const fullPermissions: Partial<tHotelStaffPermissions> = {};

    roles.forEach((r) => {
      extend(fullPermissions, r.permissions);
    });

    return every(
      Object.values(pick(fullPermissions, permissions)),
      (p) => p === true
    );
  };

  const isStaffMemberAdmin = (staffId: tManagerId) => {
    const roles = getStaffRoles(staffId);

    for (const role of roles) {
      if (role.isAdmin) return true;
    }
    return false;
  };

  return {
    hotelIsLoaded,
    hotelLoading,
    errorLoading,
    hotel,
    hotelId,

    isStaffMemberAdmin,
    getStaffRoles,
    staffMemberHasPermissions,
  };
};

export default useHotelStaffState;
