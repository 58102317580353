import { forwardRef } from "react";
import { Button, ButtonProps } from "rsuite";
import { tIcon, tIconElement } from "../../../interfaces/icon";
import Flex from "../../Flex";
import Icon from "../../Icon";
import { toIconProps } from "../../Icon/utils";
import InterTag, { iInterTagProps } from "../../Text/Inter";
import { toTextProps } from "../../Text/utils";

interface iProps extends ButtonProps {
  text: string | iInterTagProps;
  icon?: tIcon | tIconElement;
}

const SimpleButton = forwardRef<HTMLButtonElement, iProps>(
  ({ text, icon, ...props }, ref) => {
    return (
      <Button {...{ ...props, ref }}>
        <Flex
          row
          between
          middle
          gap={4}
          {...{ style: { ...(props.loading ? { visibility: "hidden" } : {}) } }}
        >
          <InterTag
            {...{
              ...toTextProps(text),
              ...(props.loading ? { color: "" } : {}),
            }}
          />
          {icon !== undefined && <Icon {...{ ...toIconProps(icon) }} />}
        </Flex>
      </Button>
    );
  }
);

export default SimpleButton;
