import { at, round, sum } from "lodash";
import React from "react";
import { ReactComponent as EcoIcon } from "../../../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as HotelIcon } from "../../../../../assets/icons/hotel.svg";
import { ReactComponent as WaterDropIcon } from "../../../../../assets/icons/water_drop.svg";
import StraightComparison from "../../../../../components/Comparions/straightComparison";
import TrendingComparison from "../../../../../components/Comparions/trendingComparison";
import Flex from "../../../../../components/Flex";
import Icon from "../../../../../components/Icon";
import SkeletonText from "../../../../../components/Skeleton/SkeletonText";
import InterTag from "../../../../../components/Text/Inter";
import useHotelState from "../../../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../../../context/Localization/hooks/useLocalizationState";
import nUseGetMeasures from "../../../../../hooks/apiRequests/useGetMeasures/interfaces";
import {
  tBenchmarkData,
  useGetBenchmarkData,
} from "../../../../../hooks/useGetBenchmarkData";
import { tIcon } from "../../../../../interfaces/icon";
import { COLORS } from "../../../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
} from "../../../../../utils/convertUnits/";
import { nUnitConverter } from "../../../../../utils/convertUnits/interfaces";
import { calcNumberOfNights } from "../../../../../utils/dates";
import { thousandsSeparation } from "../../../../../utils/numbers";

interface iProps {
  homologueMeasuresRequest: nUseGetMeasures.tFunctionReturn;
  measuresRequest: nUseGetMeasures.tFunctionReturn;
  benchmarkRequest: ReturnType<typeof useGetBenchmarkData>;
  period: [Date, Date];
}

const PerAccommodation: React.FC<iProps> = ({
  homologueMeasuresRequest,
  measuresRequest,
  benchmarkRequest,
  period,
}) => {
  const { hotel, hotelLoading, hotelIsLoaded } = useHotelState();
  const { trans } = useLocalizationState();

  const numberOfNights = calcNumberOfNights(...period);

  const renderMainValue = (
    pathToMeasureValues: string,
    converter: nUnitConverter.tFunction
  ) => {
    if (measuresRequest.isLoading || hotelLoading)
      return <SkeletonText width={100} height={36} />;

    if (measuresRequest.isResolved && hotelIsLoaded) {
      const values = at(
        measuresRequest.data,
        pathToMeasureValues
      )[0] as number[];

      const converted = converter(
        sum(values) / hotel.spaces.length / (numberOfNights || 1)
      );

      return (
        <Flex row bottom gap={4}>
          <InterTag
            size={36}
            color={COLORS.secondary}
            text={thousandsSeparation(round(converted.value, 2))}
          />
          <InterTag
            size={16}
            color={COLORS.secondary}
            text={converted.unitInHTML}
            asHTML
          />
        </Flex>
      );
    }
    return null;
  };

  const renderHomologueComparison = (pathToMeasureValues: string) => {
    if (measuresRequest.isLoading || homologueMeasuresRequest.isLoading)
      return <SkeletonText width={50} height={24} />;

    if (measuresRequest.isResolved && homologueMeasuresRequest.isResolved) {
      const value = sum(
        at(measuresRequest.data, pathToMeasureValues)[0] as number[]
      );
      const homologueValue = sum(
        at(homologueMeasuresRequest.data, pathToMeasureValues)[0] as number[]
      );

      return <TrendingComparison value={homologueValue} baseValue={value} />;
    }
    return null;
  };

  const renderBenchmarkComparison = (
    pathToBenchmarkValue: string,
    pathToMeasuresValue: string,
    converter: nUnitConverter.tFunction
  ) => {
    if (benchmarkRequest.isLoading || measuresRequest.isLoading || hotelLoading)
      return <SkeletonText height={36} width={70} />;

    if (
      benchmarkRequest.isResolved &&
      measuresRequest.isResolved &&
      hotelIsLoaded
    ) {
      const benchmarkValue = at(
        benchmarkRequest.data,
        pathToBenchmarkValue
      )[0] as unknown as number;

      const value =
        converter(
          sum(at(measuresRequest.data, pathToMeasuresValue)[0] as number[])
        ).value /
        hotel.spaces.length /
        numberOfNights;

      return <StraightComparison baseValue={benchmarkValue} value={value} />;
    }
    return null;
  };

  const renderBenchmark = (
    pathToBenchmarkValue: `${keyof Pick<
      tBenchmarkData,
      "perAccommodation"
    >}.${keyof tBenchmarkData["perAccommodation"]}`,
    unit: string
  ) => {
    if (benchmarkRequest.isLoading)
      return <SkeletonText height={24} width={50} />;

    if (benchmarkRequest.isResolved) {
      // @ts-expect-error
      const value = at(
        benchmarkRequest.data,
        pathToBenchmarkValue
      )[0] as number;

      return (
        <Flex row gap={4} middle>
          <InterTag
            size={16}
            color={COLORS.secondary}
            text={thousandsSeparation(value)}
          />
          <InterTag size={16} color={COLORS.secondary} text={unit} asHTML />
        </Flex>
      );
    }
    return null;
  };

  const renderDivider = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: COLORS.gray_200,
        }}
      />
    );
  };

  const renderAccommodationCell = (
    label: string,
    icon: tIcon,
    pathToMeasureValues: string,
    pathToBenchmarkValue: `${keyof Pick<
      tBenchmarkData,
      "perAccommodation"
    >}.${keyof tBenchmarkData["perAccommodation"]}`,
    benchmarkUnit: string,
    converter: nUnitConverter.tFunction
  ) => {
    return (
      <Flex gap={10} column color={COLORS.white}>
        <Flex row gap={4} middle>
          <Icon {...{ size: 24, ...icon }} />
          <InterTag size={12} color={COLORS.gray} text={label} asHTML />
        </Flex>
        <Flex row between>
          {renderMainValue(pathToMeasureValues, converter)}
          {renderBenchmarkComparison(
            pathToBenchmarkValue,
            pathToMeasureValues,
            converter
          )}
        </Flex>
        <Flex row between>
          {renderHomologueComparison(pathToMeasureValues)}
          {renderBenchmark(pathToBenchmarkValue, benchmarkUnit)}
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex
      one
      column
      color={COLORS.white}
      style={{ padding: 16, borderRadius: "8px" }}
      gap={16}
    >
      <Flex row gap={8} middle>
        <Icon Element={HotelIcon} size={24} fill={COLORS.secondary} />
        <InterTag
          size={20}
          color={COLORS.secondary}
          text={trans(
            "pages.sustainability.sections.indicators.cards.accommodation.title"
          )}
        />
      </Flex>
      {renderAccommodationCell(
        trans("general.co2_emissions"),
        {
          Element: EcoIcon,
          fill: COLORS.emissions,
        },
        "co2",
        "perAccommodation.co2_kg",
        "kg",
        convertMassUnit
      )}
      {renderDivider()}
      {renderAccommodationCell(
        trans("general.measures.electricity"),
        {
          Element: ElectricBoltIcon,
          fill: COLORS.energy,
        },
        "grouped.te.measurements",
        "perAccommodation.electricity_kWh",
        "kWh",
        convertEnergyUnit
      )}
      {renderDivider()}
      {renderAccommodationCell(
        trans("general.measures.water"),
        {
          Element: WaterDropIcon,
          fill: COLORS.water,
        },
        "grouped.tw.measurements",
        "perAccommodation.water_m3",
        "m<sup>3</sup>",
        convertVolumeUnit
      )}
    </Flex>
  );
};

export default PerAccommodation;
