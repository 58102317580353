import React, { useState } from "react";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import { COLORS } from "../../../utils/colors";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import CreateUpdateReservationModal from "./createUpdateReservationModal";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import axios from "axios";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { apiAddress } from "../../../utils/apiCall";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import { tHotelSpaceId } from "../../../models/hotel";
import { useToaster } from "rsuite";
import { notification } from "../../../utils/notifications";

interface iProps {
  afterCreate(): void;
}

const AddReservation: React.FC<iProps> = ({ afterCreate }) => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const [modal, setModal] = useState<{ open: boolean }>({ open: false });
  const { hotelId } = useHotelState();
  const postRequest = usePostRequest();

  const onCreate = (args: {
    guestEmail: string;
    period: [Date, Date];
    space: tHotelSpaceId;
    numberOfGuests: number;
    guestName?: string;
  }) => {
    const { guestEmail, period, space, numberOfGuests, guestName } = args;

    postRequest.pending();
    axios
      .post(`${apiAddress(false)}/v2/hotels/${hotelId}/stays/`, {
        guestEmail,
        period,
        space,
        numberOfGuests,
        guestName,
      })
      .then((res) => {
        postRequest.resolve();
        setModal({ open: false });
        afterCreate();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        postRequest.reject(error);
        toaster.push(notification("error", error), { placement: "topEnd" });
      });
  };

  return (
    <>
      <PrimaryButton
        label={{
          text: trans("general.add").toUpperCase(),
          color: COLORS.white,
          size: 12,
        }}
        icon={{ Element: AddIcon, fill: COLORS.white, size: 20 }}
        size="sm"
        onClick={() => setModal({ open: true })}
      />
      <CreateUpdateReservationModal
        open={modal.open}
        isLoading={postRequest.isLoading}
        onClose={() => setModal({ open: false })}
        onConfirm={onCreate}
      />
    </>
  );
};

export default AddReservation;
