import React from "react";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";
import EditName from "./editName";
import EditDescription from "./editDescription";
import PhotoGallery from "./photoGallery";
import PageBottomPadding from "../../../components/PageBottomPadding";

interface iHotelProfileWrappedProps {}

const HotelProfileWrapped: React.FC<iHotelProfileWrappedProps> = () => {
  return (
    <div>
      <Flex column gap={40}>
        <EditName />
        <EditDescription />
        <PhotoGallery />
      </Flex>
      <PageBottomPadding />
    </div>
  );
};

const HotelProfile: React.FC = () => {
  const { trans } = useLocalizationState();
  return (
    <>
      <TopBar>
        <InterTag
          text={trans("pages.hotel_profile.title")}
          bold
          color={COLORS.secondary}
          size={24}
        />
      </TopBar>
      <IpValidatorGuard>
        <HotelProfileWrapped />
      </IpValidatorGuard>
    </>
  );
};

export default HotelProfile;
