import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Flex from "../../../../components/Flex";
import ManualDataModal from "../../../../components/Modals/ManualDataModal";
import RoadmapTable from "../../../../components/RoadmapTable";
import SimpleDateRangePicker from "../../../../components/RsuiteWrapper/SimpleDateRangePicker";
import InterTag from "../../../../components/Text/Inter";
import useHotelMeasuresTrackedState from "../../../../context/Hotel/hooks/hotelState/useHotelMeasuresTrackedState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useGetMeasures from "../../../../hooks/apiRequests/useGetMeasures";
import usePeriodState from "../../../../hooks/usePeriodState";
import { TE, tMeasure, TW } from "../../../../models/measures";
import { COLORS } from "../../../../utils/colors";
import { SustainabilityContext } from "../context";
import Benchmark from "./benchmark";
import Indicators from "./indicators";

interface iProps {
  dataTypeToggle: any;
}

const SensorData: React.FC<iProps> = ({ dataTypeToggle }) => {
  const [manualDataModal, setManualDataModal] = useState(false);
  const { trans } = useLocalizationState();
  const [period, setPeriod] = usePeriodState();
  const { getMeasuresTracked, hotel } = useHotelMeasuresTrackedState();
  const { setDataTypeToggleDisabled } = useContext(SustainabilityContext);
  const trackedMeasures = getMeasuresTracked();

  const measuresList: tMeasure[] = [];
  if (trackedMeasures.te) {
    measuresList.push(TE);
  }
  if (trackedMeasures.tw) {
    measuresList.push(TW);
  }

  const measuresRequest = useGetMeasures(hotel, {
    measures: measuresList,
    from: moment(period[0]).toISOString(),
    to: moment(period[1]).toISOString(),
    binUnit: "day",
    binValue: 1,
  });
  const homologueMeasuresRequest = useGetMeasures(hotel, {
    measures: measuresList,
    from: moment(period[0]).subtract(1, "year").toISOString(),
    to: moment(period[1]).subtract(1, "year").toISOString(),
    binUnit: "day",
    binValue: 1,
  });

  useEffect(() => {
    setDataTypeToggleDisabled({ measuresRequest: measuresRequest.isLoading });
  }, [measuresRequest.isLoading, setDataTypeToggleDisabled]);
  useEffect(() => {
    setDataTypeToggleDisabled({
      homologueMeasuresRequest: homologueMeasuresRequest.isLoading,
    });
  }, [homologueMeasuresRequest.isLoading, setDataTypeToggleDisabled]);

  if (measuresList.length === 0)
    return (
      <Flex>
        <InterTag
          stize={14}
          color={COLORS.error}
          text={trans("No data from sensors")}
        />
      </Flex>
    );

  return (
    <>
      <ManualDataModal
        open={manualDataModal}
        onClose={() => {
          setManualDataModal(false);
        }}
      />
      <div
        style={{
          position: "fixed",
          top: 70,
          width: "100%",
          zIndex: 3,
          paddingBottom: "8px",
          backgroundColor: COLORS.app_background,
        }}
      >
        <Flex row gap={16}>
          <Flex middle>{dataTypeToggle}</Flex>
          <SimpleDateRangePicker
            hoverRange={"month"}
            onChange={setPeriod}
            value={period}
          />
        </Flex>
      </div>
      <Flex column gap={40} style={{ marginTop: "40px" }}>
        <Benchmark
          {...{
            measuresRequest,
            homologueMeasuresRequest,
            period,
            noData: measuresList.length === 0,
          }}
        />
        <Indicators
          {...{
            measuresRequest,
            homologueMeasuresRequest,
            period,
            binUnit: "day",
            binValue: 1,
          }}
        />
        <RoadmapTable />
      </Flex>
    </>
  );
};

export default SensorData;
