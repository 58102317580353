import React from "react";
import { ReactComponent as ControllerIcon } from "../../../assets/icons/stadia_controller.svg";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import PageBottomPadding from "../../../components/PageBottomPadding";
import NoPermissions from "../../../components/Pages/NoPermissions";
import PageSectionTitle from "../../../components/PageSectionTitle";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { COLORS } from "../../../utils/colors";
import ChallengesList from "./list";
import Unlock from "./unlock";

const ChallengesWrapped: React.FC = () => {
  const { trans } = useLocalizationState();
  const { activeSubscriptionIsStarter } = useHotelSubscriptionState();
  const profilePermissions = useProfilePermissions();

  if (activeSubscriptionIsStarter)
    return (
      <>
        <Unlock />
        <PageBottomPadding />
      </>
    );

  if (!profilePermissions.readChallenges) {
    return <NoPermissions />;
  }

  return (
    <Flex column gap={40}>
      <Flex column gap={16}>
        <PageSectionTitle
          title={trans("pages.challenges.sections.list.title")}
          description={trans("pages.challenges.sections.list.description")}
          icon={{ Element: ControllerIcon }}
        />
        <ChallengesList />
      </Flex>
    </Flex>
  );
};

const Challenges: React.FC = () => {
  const { trans } = useLocalizationState();

  return (
    <>
      <TopBar>
        <InterTag
          text={trans("general.challenges")}
          size={24}
          bold
          color={COLORS.secondary}
        />
      </TopBar>
      <IpValidatorGuard>
        <ChallengesWrapped />
        <PageBottomPadding />
      </IpValidatorGuard>
    </>
  );
};

export default Challenges;
