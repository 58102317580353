import React, { useContext, useEffect } from "react";
import { ReactComponent as ReadinessScoreIcon } from "../../../../../assets/icons/readiness_score.svg";
import Flex from "../../../../../components/Flex";
import PageSectionTitle from "../../../../../components/PageSectionTitle";
import useLocalizationState from "../../../../../context/Localization/hooks/useLocalizationState";
import nUseGetMeasures from "../../../../../hooks/apiRequests/useGetMeasures/interfaces";
import { useGetBenchmarkData } from "../../../../../hooks/useGetBenchmarkData";
import { SustainabilityContext } from "../../context";
import PerAccommodation from "./perAccommodations";
import PerGuest from "./perGuest";

interface iBenchmarkWrappedProps extends iProps {}

const BenchmarkWrapped: React.FC<iBenchmarkWrappedProps> = ({
  homologueMeasuresRequest,
  measuresRequest,
  period,
  noData,
}) => {
  const benchmarkRequest = useGetBenchmarkData({ doRequest: !noData });
  const { setDataTypeToggleDisabled } = useContext(SustainabilityContext);

  useEffect(() => {
    setDataTypeToggleDisabled({ benchmarkRequest: benchmarkRequest.isLoading });
  }, [benchmarkRequest.isLoading, setDataTypeToggleDisabled]);

  return (
    <Flex row gap={16} between>
      <PerAccommodation
        {...{
          benchmarkRequest,
          homologueMeasuresRequest,
          measuresRequest,
          period,
        }}
      />
      <PerGuest
        {...{
          benchmarkRequest,
          homologueMeasuresRequest,
          measuresRequest,
          noData,
          period,
        }}
      />
    </Flex>
  );
};

interface iProps {
  homologueMeasuresRequest: nUseGetMeasures.tFunctionReturn;
  measuresRequest: nUseGetMeasures.tFunctionReturn;
  period: [Date, Date];
  noData: boolean;
}

const Benchmark: React.FC<iProps> = ({
  homologueMeasuresRequest,
  measuresRequest,
  period,
  noData,
}) => {
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <PageSectionTitle
        icon={ReadinessScoreIcon}
        title={trans("pages.sustainability.sections.indicators.title")}
        description={trans(
          "pages.sustainability.sections.indicators.description"
        )}
      />
      <BenchmarkWrapped
        {...{ homologueMeasuresRequest, measuresRequest, period, noData }}
      />
    </Flex>
  );
};

export default Benchmark;
