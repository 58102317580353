import React from "react";
import { Button } from "rsuite";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/logout.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InterTag from "../../../components/Text/Inter";
import useAuthDispatch from "../../../context/Auth/hooks/useAuthDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";
import styles from "./styles.module.css";

const SessionSettingsWrapped: React.FC = () => {
  const { trans } = useLocalizationState();
  const { logout } = useAuthDispatch();

  return (
    <Flex
      row
      between
      className={[styles["settings-item"], styles["settings-button-item"]].join(
        " "
      )}
      middle
    >
      <Flex column gap={4} center>
        <InterTag
          size={14}
          text={trans("pages.settings.panels.session.items.sign_out.label")}
          bold
          color={COLORS.error}
        />
        <InterTag
          size={14}
          text={trans(
            "pages.settings.panels.session.items.sign_out.description"
          )}
          color={COLORS.secondary}
        />
      </Flex>
      <Button
        color="red"
        appearance="primary"
        style={{
          padding: "4px 8px",
          borderRadius: "8px",
          height: "fit-content",
        }}
        onClick={logout}
      >
        <Flex row between gap={16} middle>
          <InterTag
            size={14}
            text={trans(
              "pages.settings.panels.session.items.sign_out.button_label"
            ).toUpperCase()}
            bold
            color={COLORS.white}
          />
          <Icon Element={LogoutIcon} size={24} fill={COLORS.white} />
        </Flex>
      </Button>
    </Flex>
  );
};

const SessionSettings: React.FC = () => {
  return <SessionSettingsWrapped />;
};

export default SessionSettings;
