import axios from "axios";
import { isNull } from "lodash";
import React, { useCallback, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Flex from "../../../components/Flex";
import PageBottomPadding from "../../../components/PageBottomPadding";
import SkeletonText from "../../../components/Skeleton/SkeletonText";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useEffectSafe from "../../../hooks/useEffectSafe";
import { tGetRequest } from "../../../interfaces/apiCalls";
import { tHotelId } from "../../../models/hotel";
import { tManager, tManagerId } from "../../../models/manager";
import {
  apiAddress,
  REQUEST_STATUS,
  requestStatusIsFinal,
  setGetRequestStateResolved,
  setRequestStatePending,
  setRequestStateRejected,
} from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import UserActivitiesWrapped from "./userActivities";
import UserInfo from "./userInfo";

interface iUserWrappedProps {
  staffId: tManagerId;
}

const UserWrapped: React.FC<iUserWrappedProps> = ({ staffId }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { hotelId } = useHotelState();

  const [requestState, setRequestState] = useState<
    tGetRequest<tManager | null>
  >({
    data: null,
    status: REQUEST_STATUS.PENDING,
    error: null,
  });

  const run = useCallback(
    (staffId: tManagerId, hotelId: tHotelId) => {
      setRequestStatePending(setRequestState);
      axios
        .get(`${apiAddress(false)}/v2/hotels/${hotelId}/staff/${staffId}`)
        .then((res) => {
          const {
            data: { staffMember },
          } = res;
          setGetRequestStateResolved(setRequestState, staffMember);
        })
        .catch((err) => {
          setRequestStateRejected(setRequestState, getErrorMessage(err, trans));
        });
    },
    [trans]
  );

  useEffectSafe(() => {
    if (staffId && hotelId) {
      run(staffId, hotelId);
    }
  }, [hotelId, run, staffId]);

  return (
    <div>
      <TopBar>
        <Flex gap={8} column style={{ height: "100%" }}>
          <InterTag
            hoverUnderline
            onClick={() => {
              navigate("/users");
            }}
            style={{ marginTop: "10px" }}
            text={trans("Users")}
            size={12}
            color={COLORS.secondary}
          />
          {!requestStatusIsFinal(requestState.status) ? (
            <SkeletonText width={150} height={24} />
          ) : requestState.status === REQUEST_STATUS.RESOLVED &&
            !isNull(requestState.data) ? (
            <InterTag
              text={requestState.data.name}
              bold
              color={COLORS.secondary}
              size={24}
            />
          ) : null}
        </Flex>
      </TopBar>
      <UserInfo
        {...{ status: requestState.status, staff: requestState.data }}
      />
    </div>
  );
};

const User: React.FC = () => {
  const { staffId } = useParams<{ staffId: string }>();

  if (!staffId) return <Navigate to="/users" />;

  return (
    <div>
      <UserWrapped {...{ staffId }} />
      <div style={{ marginTop: "40px" }}>
        <UserActivitiesWrapped {...{ staffId }} />
      </div>
      <PageBottomPadding />
    </div>
  );
};

export default User;
