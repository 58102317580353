import axios from "axios";
import {
  cloneDeep,
  find,
  isBoolean,
  last,
  pick,
  random,
  toInteger,
  toNumber,
} from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Modal, ModalProps } from "rsuite";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useDeleteRequest from "../../../hooks/apiRequests/useDeleteRequest";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import { tStringify } from "../../../interfaces/others";
import { tHotelManualData } from "../../../models/hotel";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import Flex from "../../Flex";
import InputWrapper from "../../InputWrapper";
import SimpleDateRangePicker from "../../RsuiteWrapper/SimpleDateRangePicker";
import ActionButtons from "./components/actionButtons";
import Header from "./components/header";
import StepsHeader from "./components/steps";
import Energy from "./energy";
import Fuels from "./fuels";
import Laundry from "./laundry";
import Occupancy from "./occupancy";
import Space from "./space";
import Waste from "./waste";
import Water from "./water";

export type tState2 = {
  data: Required<tHotelManualData>;
  errors: tStringify<tState2["data"]>;
  files: { greenElectricityCertificate: File | null };
};

const initialState2: tState2 = {
  data: {
    _id: "",
    manager: "",
    timestamp: moment().toDate(),
    from: moment().subtract(1, "month").startOf("month").toDate(),
    to: moment().subtract(1, "month").endOf("month").toDate(),
    space: {
      guestRoomsAndCorridorsAreaM2: 0,
      meetingRoomsAreaM2: 0,
      totalAreaM2: 0,
    },
    electricity: {
      isElectricityGreen: false,
      percentageOfGreenElectricity: 0,
      totalKWh: 0,
      greenElectricityCertificate: { file: "", status: "na" },
      price: 0,
    },
    laundry: {
      isLaundryOutsourced: false,
      totalMetricTonsOutsourced: 0,
      kgOutsourced: 0,
      outsourcedElectricityConsumptionIsKnown: false,
      outsourcedElectricityConsumptionKWh: 0,
      outsourcedWaterConsumptionIsKnown: false,
      outsourcedWaterConsumptionM3: 0,
      outsourcedElectricityPrice: 0,
      outsourcedWaterPrice: 0,
    },
    naturalGas: { totalKWh: 0, totalM3: 0, price: 0 },
    biomass: { price: 0, totalKg: 0, totalKWh: 0 },
    butane: { price: 0, totalKg: 0, totalKWh: 0 },
    propane: { price: 0, totalKg: 0, totalKWh: 0 },
    diesel: { price: 0, totalLiters: 0, totalKWh: 0 },
    ethanol: { price: 0, totalLiters: 0, totalKWh: 0 },
    gasoline: { price: 0, totalLiters: 0, totalKWh: 0 },
    occupancy: {
      numberOfGuests: 0,
      numberOfStays: 0,
      occupancyRate: 0,
    },
    water: { totalM3: 0, price: 0 },
    waste: {
      glass: { totalKg: 0 },
      mixed: { totalKg: 0 },
      organic: { totalKg: 0 },
      paper: { totalKg: 0 },
      plastic: { totalKg: 0 },
    },
  },
  files: { greenElectricityCertificate: null },
  errors: {
    _id: "",
    manager: "",
    timestamp: "",
    from: "",
    to: "",
    space: {
      guestRoomsAndCorridorsAreaM2: "",
      meetingRoomsAreaM2: "",
      totalAreaM2: "",
    },
    electricity: {
      isElectricityGreen: "",
      percentageOfGreenElectricity: "",
      totalKWh: "",
      greenElectricityCertificate: { file: "", status: "" },
      price: "",
    },
    laundry: {
      isLaundryOutsourced: "",
      totalMetricTonsOutsourced: "",
      kgOutsourced: "",
      outsourcedElectricityConsumptionIsKnown: "",
      outsourcedElectricityConsumptionKWh: "",
      outsourcedWaterConsumptionIsKnown: "",
      outsourcedWaterConsumptionM3: "",
      outsourcedElectricityPrice: "",
      outsourcedWaterPrice: "",
    },
    naturalGas: { totalKWh: "", totalM3: "", price: "" },
    biomass: { price: "", totalKWh: "", totalKg: "" },
    butane: { price: "", totalKg: "", totalKWh: "" },
    propane: { price: "", totalKg: "", totalKWh: "" },
    diesel: { price: "", totalLiters: "", totalKWh: "" },
    ethanol: { price: "", totalLiters: "", totalKWh: "" },
    gasoline: { price: "", totalLiters: "", totalKWh: "" },
    occupancy: {
      numberOfGuests: "",
      numberOfStays: "",
      occupancyRate: "",
    },
    water: { totalM3: "", price: "" },
    waste: {
      glass: { totalKg: "" },
      mixed: { totalKg: "" },
      organic: { totalKg: "" },
      paper: { totalKg: "" },
      plastic: { totalKg: "" },
    },
  },
};

type tAction2 =
  | { type: "files"; files: tState2["files"] }
  | { type: "paths errors"; paths: string[]; errors: string[] }
  | {
      type: "paths values";
      data: { path: string; value: any; removeError?: boolean }[];
    }
  | { type: "data"; data: tState2["data"] };

const reducer2 = (state: tState2, action: tAction2): tState2 => {
  switch (action.type) {
    case "files": {
      const { files } = action;
      return { ...state, files: { ...state.files, ...files } };
    }
    case "paths values": {
      const { data } = action;

      data.forEach(({ path, value, removeError }) => {
        let errorsObj: any = state.errors;
        let obj: any = state.data;

        const keys = path.split(".");
        keys.slice(0, -1).forEach((key) => {
          obj = obj[key];
          errorsObj = errorsObj[key];
        });

        obj[last(keys)!] = value;
        if (isBoolean(removeError) && removeError) errorsObj[last(keys)!] = "";
      });

      return { ...state };
    }
    case "paths errors": {
      const { paths, errors } = action;

      let errorsObj: any = state.errors;

      paths.forEach((path, i) => {
        const keys = path.split(".");

        keys.slice(0, -1).forEach((key) => {
          errorsObj = errorsObj[key];
        });
        errorsObj[last(keys)!] = errors[i];
      });

      return { ...state };
    }
    case "data": {
      const { data } = action;
      return { ...state, data };
    }

    default:
      return { ...state };
  }
};

interface iManualDataModalWrappedProps
  extends Pick<
    iManualDataModalProps,
    "onClose" | "manualDataId" | "initialStep" | "singleStep"
  > {}

const ManualDataModalWrapped: React.FC<iManualDataModalWrappedProps> = ({
  onClose,
  manualDataId,
  initialStep,
  singleStep,
}) => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const { hotelId, hotel, hotelIsLoaded, manualDataOrdered } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const [state2, dispatch2] = useReducer(reducer2, initialState2);
  const request = usePostRequest();
  const deleteRequest = useDeleteRequest();
  const [step, setStep] = useState(0);

  useEffect(() => {
    switch (initialStep) {
      case "space":
        setStep(1);
        break;
      case "occupancy":
        setStep(2);
        break;
      case "electricity":
        setStep(3);
        break;
      case "naturalGas":
        setStep(4);
        break;
      case "water":
        setStep(5);
        break;
      case "laundry":
        setStep(6);
        break;
      case "waste":
        setStep(7);
        break;
    }
  }, [initialStep]);

  useEffect(() => {
    if (hotelIsLoaded) {
      const obj = manualDataId
        ? find(hotel.manualData, (md) => md._id === manualDataId)
        : find(
            hotel.manualData,
            ({ from, to }) =>
              moment(from).isSame(moment(initialState2.data.from)) &&
              moment(to).isSame(moment(initialState2.data.to))
          );
      if (obj) {
        dispatch2({
          type: "data",
          data: {
            _id: obj._id,
            from: obj.from,
            to: obj.to,
            electricity: obj.electricity
              ? {
                  ...obj.electricity,
                  greenElectricityCertificate: {
                    ...obj.electricity.greenElectricityCertificate,
                  },
                }
              : {
                  ...initialState2.data.electricity,
                  greenElectricityCertificate: {
                    ...initialState2.data.electricity
                      .greenElectricityCertificate,
                  },
                },
            water: obj.water
              ? { ...obj.water }
              : { ...initialState2.data.water },
            naturalGas: obj.naturalGas
              ? { ...obj.naturalGas }
              : { ...initialState2.data.naturalGas },
            space: obj.space
              ? { ...obj.space }
              : { ...initialState2.data.space },
            occupancy: obj.occupancy
              ? { ...obj.occupancy }
              : { ...initialState2.data.occupancy },
            laundry: obj.laundry
              ? { ...obj.laundry }
              : { ...initialState2.data.laundry },
            biomass: obj.biomass
              ? { ...obj.biomass }
              : { ...initialState2.data.biomass },
            butane: obj.butane
              ? { ...obj.butane }
              : { ...initialState2.data.butane },
            propane: obj.propane
              ? { ...obj.propane }
              : { ...initialState2.data.propane },
            diesel: obj.diesel
              ? { ...obj.diesel }
              : { ...initialState2.data.diesel },
            ethanol: obj.ethanol
              ? { ...obj.ethanol }
              : { ...initialState2.data.ethanol },
            gasoline: obj.gasoline
              ? { ...obj.gasoline }
              : { ...initialState2.data.gasoline },
            waste: cloneDeep(obj.waste ? obj.waste : initialState2.data.waste),
            manager: obj.manager,
            timestamp: obj.timestamp,
          },
        });
      }
    }
  }, [hotel.manualData, hotelIsLoaded, manualDataId]);

  useEffect(() => {
    if (hotelIsLoaded) {
      if (hotel.manualData.length) {
        const obj = last(manualDataOrdered)!;

        dispatch2({
          type: "paths values",
          data: [
            {
              path: "space.guestRoomsAndCorridorsAreaM2",
              value: obj.space?.guestRoomsAndCorridorsAreaM2 || 0,
            },
            {
              path: "space.meetingRoomsAreaM2",
              value: obj.space?.meetingRoomsAreaM2 || 0,
            },
            {
              path: "space.totalAreaM2",
              value: obj.space?.totalAreaM2 || 0,
            },
          ],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelIsLoaded]);

  const check = useCallback(() => {
    if (state2.data.space.totalAreaM2) {
      const totalArea = toNumber(state2.data.space.totalAreaM2);
      const roomsAndCorridorsArea = toNumber(
        state2.data.space.guestRoomsAndCorridorsAreaM2
      );
      const meetingRoomsArea = toNumber(state2.data.space.meetingRoomsAreaM2);
      if (roomsAndCorridorsArea + meetingRoomsArea > totalArea) {
        dispatch2({
          type: "paths errors",
          paths: ["space.totalAreaM2"],
          errors: [
            trans("Total area cannot be smaller than sum of specific areas"),
          ],
        });
        return false;
      }
    }
    dispatch2({
      type: "paths errors",
      paths: ["space.totalAreaM2"],
      errors: [],
    });
    return true;
  }, [
    state2.data.space.meetingRoomsAreaM2,
    state2.data.space.guestRoomsAndCorridorsAreaM2,
    state2.data.space.totalAreaM2,
    trans,
  ]);

  useEffect(() => {
    check();
  }, [check]);

  const handleChangePeriod = (period: [Date, Date]) => {
    const obj = find(
      hotel.manualData,
      ({ from, to }) =>
        moment(from).format("DD/MM/YYYY") ===
          moment(period[0]).format("DD/MM/YYYY") &&
        moment(to).format("DD/MM/YYYY") ===
          moment(period[1]).format("DD/MM/YYYY")
    );
    if (obj) {
      dispatch2({
        type: "data",
        data: {
          _id: obj._id,
          from: obj.from,
          to: obj.to,
          electricity: obj.electricity
            ? obj.electricity
            : {
                ...initialState2.data.electricity,
                greenElectricityCertificate: {
                  ...initialState2.data.electricity.greenElectricityCertificate,
                },
              },
          water: obj.water ? obj.water : { ...initialState2.data.water },
          naturalGas: obj.naturalGas
            ? obj.naturalGas
            : { ...initialState2.data.naturalGas },
          space: obj.space ? obj.space : { ...initialState2.data.space },
          occupancy: obj.occupancy
            ? obj.occupancy
            : { ...initialState2.data.occupancy },
          laundry: obj.laundry
            ? obj.laundry
            : { ...initialState2.data.laundry },
          gasoline: obj.gasoline
            ? obj.gasoline
            : { ...initialState2.data.gasoline },
          biomass: obj.biomass
            ? obj.biomass
            : { ...initialState2.data.biomass },
          diesel: obj.diesel ? obj.diesel : { ...initialState2.data.diesel },
          butane: obj.butane ? obj.butane : { ...initialState2.data.butane },
          propane: obj.propane
            ? obj.propane
            : { ...initialState2.data.propane },
          ethanol: obj.ethanol
            ? obj.ethanol
            : { ...initialState2.data.ethanol },
          waste: cloneDeep(obj.waste ? obj.waste : initialState2.data.waste),
          manager: obj.manager,
          timestamp: obj.timestamp,
        },
      });
    } else {
      dispatch2({
        type: "data",
        data: {
          _id: "",
          from: period[0],
          to: period[1],
          electricity: {
            ...initialState2.data.electricity,
            greenElectricityCertificate: {
              ...initialState2.data.electricity.greenElectricityCertificate,
            },
          },
          water: { ...initialState2.data.water },
          naturalGas: { ...initialState2.data.naturalGas },
          space: { ...initialState2.data.space },
          occupancy: { ...initialState2.data.occupancy },
          laundry: { ...initialState2.data.laundry },
          ethanol: { ...initialState2.data.ethanol },
          butane: { ...initialState2.data.butane },
          gasoline: { ...initialState2.data.gasoline },
          propane: { ...initialState2.data.propane },
          diesel: { ...initialState2.data.diesel },
          biomass: { ...initialState2.data.biomass },
          waste: { ...initialState2.data.waste },
          manager: "",
          timestamp: moment(),
        },
      });
    }
  };

  const handleClose = (action: Parameters<typeof onClose>[0]) => {
    onClose(action);
  };

  const handleConfirm = () => {
    const stateManualData = state2.data;

    const body: {
      manualData: Omit<tHotelManualData, "_id" | "manager" | "timestamp">[];
    } = { manualData: [] };

    const manualDataItem: Omit<
      tHotelManualData,
      "_id" | "manager" | "timestamp"
    > = {
      from: moment(stateManualData.from).toISOString(),
      to: moment(stateManualData.to).toISOString(),
      space: {
        guestRoomsAndCorridorsAreaM2:
          toNumber(stateManualData.space.guestRoomsAndCorridorsAreaM2) || 0,
        meetingRoomsAreaM2:
          toNumber(stateManualData.space.meetingRoomsAreaM2) || 0,
        totalAreaM2: toNumber(stateManualData.space.totalAreaM2) || 0,
      },
      electricity: {
        greenElectricityCertificate: {
          file: state2.files.greenElectricityCertificate?.name || "",
          status: "na",
        },
        isElectricityGreen: stateManualData.electricity.isElectricityGreen,
        percentageOfGreenElectricity: stateManualData.electricity
          .isElectricityGreen
          ? 100
          : 0,
        totalKWh: toNumber(stateManualData.electricity.totalKWh) || 0,
        price: toNumber(stateManualData.electricity.price) || 0,
      },
      occupancy: {
        numberOfGuests:
          toInteger(stateManualData.occupancy.numberOfGuests) || 0,
        occupancyRate: toInteger(stateManualData.occupancy.occupancyRate) || 0,
        numberOfStays: toInteger(stateManualData.occupancy.numberOfStays) || 0,
      },
      laundry: {
        isLaundryOutsourced: stateManualData.laundry.isLaundryOutsourced,
        totalMetricTonsOutsourced:
          toNumber(stateManualData.laundry.totalMetricTonsOutsourced) || 0,
        kgOutsourced: toNumber(stateManualData.laundry.kgOutsourced) || 0,
        outsourcedElectricityConsumptionIsKnown:
          stateManualData.laundry.outsourcedElectricityConsumptionIsKnown,
        outsourcedElectricityConsumptionKWh:
          toNumber(
            stateManualData.laundry.outsourcedElectricityConsumptionKWh
          ) || 0,
        outsourcedElectricityPrice:
          toNumber(stateManualData.laundry.outsourcedElectricityPrice) || 0,
        outsourcedWaterConsumptionIsKnown:
          stateManualData.laundry.outsourcedWaterConsumptionIsKnown,
        outsourcedWaterConsumptionM3:
          toNumber(stateManualData.laundry.outsourcedWaterConsumptionM3) || 0,
        outsourcedWaterPrice:
          toNumber(stateManualData.laundry.outsourcedWaterPrice) || 0,
      },
      naturalGas: {
        totalKWh: toNumber(stateManualData.naturalGas.totalKWh) || 0,
        totalM3: toNumber(stateManualData.naturalGas.totalM3) || 0,
        price: toNumber(stateManualData.naturalGas.price) || 0,
      },
      gasoline: {
        totalKWh: toNumber(stateManualData.gasoline.totalKWh) || 0,
        price: toNumber(stateManualData.gasoline.price) || 0,
        totalLiters: toNumber(stateManualData.gasoline.totalLiters) || 0,
      },
      diesel: {
        totalKWh: toNumber(stateManualData.diesel.totalKWh) || 0,
        price: toNumber(stateManualData.diesel.price) || 0,
        totalLiters: toNumber(stateManualData.diesel.totalLiters) || 0,
      },
      ethanol: {
        totalKWh: toNumber(stateManualData.ethanol.totalKWh) || 0,
        price: toNumber(stateManualData.ethanol.price) || 0,
        totalLiters: toNumber(stateManualData.ethanol.totalLiters) || 0,
      },
      propane: {
        totalKWh: toNumber(stateManualData.propane.totalKWh) || 0,
        price: toNumber(stateManualData.propane.price) || 0,
        totalKg: toNumber(stateManualData.propane.totalKg) || 0,
      },
      biomass: {
        totalKWh: toNumber(stateManualData.biomass.totalKWh) || 0,
        price: toNumber(stateManualData.biomass.price) || 0,
        totalKg: toNumber(stateManualData.biomass.totalKg) || 0,
      },
      butane: {
        totalKWh: toNumber(stateManualData.butane.totalKWh) || 0,
        price: toNumber(stateManualData.butane.price) || 0,
        totalKg: toNumber(stateManualData.butane.totalKg) || 0,
      },
      waste: {
        glass: { totalKg: toNumber(stateManualData.waste.glass.totalKg) },
        mixed: { totalKg: toNumber(stateManualData.waste.mixed.totalKg) },
        organic: { totalKg: toNumber(stateManualData.waste.organic.totalKg) },
        paper: { totalKg: toNumber(stateManualData.waste.paper.totalKg) },
        plastic: { totalKg: toNumber(stateManualData.waste.plastic.totalKg) },
      },
      water: {
        totalM3: toNumber(stateManualData.water.totalM3) || 0,
        price: toNumber(stateManualData.water.price) || 0,
      },
    };

    body.manualData.push(manualDataItem);

    const formData = new FormData();

    if (state2.files.greenElectricityCertificate) {
      const filename: `${Extract<
        keyof Required<tHotelManualData>["electricity"],
        "greenElectricityCertificate"
      >}-${number}` = "greenElectricityCertificate-0";
      formData.append(
        filename,
        state2.files.greenElectricityCertificate,
        state2.files.greenElectricityCertificate.name
      );
    }
    formData.append("jsonData", JSON.stringify(body));

    request.pending();
    (state2.data._id.length
      ? axios.put(
          `${apiAddress(true)}/v2/hotels/${hotelId}/manual-data/${
            state2.data._id
          }`,
          formData
        )
      : axios.post(
          `${apiAddress(true)}/v2/hotels/${hotelId}/manual-data`,
          formData
        )
    )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        request.resolve();
        toaster.success(trans("Data successfully inserted"));
        handleClose("created");
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        request.reject(error);
        toaster.error(error);
      });
  };

  const handleDelete = () => {
    if (state2.data._id.length) {
      deleteRequest.pending();
      axios
        .delete(
          `${apiAddress(false)}/v2/hotels/${hotelId}/manual-data/${
            state2.data._id
          }`
        )
        .then((res) => {
          const {
            data: { hotel },
          } = res;
          updateHotel(hotelId, hotel);
          deleteRequest.resolve();
          toaster.success(trans("Entry deleted"));
          handleClose("deleted");
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          deleteRequest.reject(error);
          toaster.error(error);
        });
    }
  };

  useEffect(() => {
    if (hotelIsLoaded) {
      const globalStyles: string[] = [];
      hotel.manualData.forEach(({ from, to }) => {
        globalStyles.push(
          `div[aria-label="${moment(from).format(
            "MMM YYYY"
          )}"] > span { border: 2px solid ${COLORS.primary_200}; }`
        );
      });

      const stringifiedStyles = globalStyles.join(" ");
      const styleElement = document.createElement("style");
      styleElement.innerHTML = stringifiedStyles;
      styleElement.setAttribute("id", "global-styles");
      document.head.appendChild(styleElement);
    }
    return () => {
      const styleElement = document.getElementById("global-styles");
      if (styleElement) {
        document.head.removeChild(styleElement);
      }
    };
  }, [hotel.manualData, hotelIsLoaded]);

  const renderStepWrapped = (
    children: any,
    headerParams: { title: string; description: string },
    periodParams: {
      disabled?: boolean;
      required?: boolean;
      description?: string;
    },
    onGoBack?: () => void
  ) => {
    return (
      <Flex column gap={20}>
        <Header {...headerParams} />
        <InputWrapper
          label={trans("Timeframe")}
          {...pick(periodParams, ["description", "required"])}
        >
          <SimpleDateRangePicker
            hoverRange={"month"}
            oneTap
            value={[
              moment(state2.data.from).toDate(),
              moment(state2.data.to).toDate(),
            ]}
            onChange={handleChangePeriod}
            disabled={
              (periodParams.disabled && !singleStep) ||
              request.isLoading ||
              deleteRequest.isLoading
            }
            ranges={[]}
            showOneCalendar
            style={{ border: `1px solid ${COLORS.gray_200}` }}
          />
        </InputWrapper>
        {children}
        <ActionButtons
          onGoBack={
            singleStep
              ? undefined
              : () => {
                  onGoBack ? onGoBack() : setStep((prev) => prev - 1);
                }
          }
          onContinue={
            singleStep ? undefined : () => setStep((prev) => prev + 1)
          }
          onSaveAndExit={() => handleClose("exit")}
          onSubmit={handleConfirm}
          onDelete={state2.data._id.length ? handleDelete : undefined}
          isLoading={deleteRequest.isLoading || request.isLoading}
        />
      </Flex>
    );
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return renderStepWrapped(
          null,
          {
            title: trans("Select Timeframe"),
            description: trans(
              "Select a specific period for which you have data."
            ),
          },
          { required: true },
          () => handleClose("exit")
        );
      case 1:
        return renderStepWrapped(
          <Space
            status={request.status}
            errors={state2.errors.space}
            state={state2.data.space}
            onChange={(key, value) => {
              dispatch2({
                type: "paths values",
                data: [
                  {
                    path: `space.${key}`,
                    value,
                    removeError: key !== "totalAreaM2",
                  },
                ],
              });
            }}
          />,
          {
            title: trans("Let's Measure Up Your Space"),
            description: trans(
              "Understanding the total area of your accommodation is crucial for accurately calculating key sustainability indicators. This helps us provide more precise insights into energy, water usage, and more, tailored specifically to your property's size. Later, you'll be able to detail these measures by specific spaces for even more tailored analytics."
            ),
          },
          { disabled: true }
        );
      case 2:
        return renderStepWrapped(
          <Occupancy
            status={request.status}
            onChange={(key, value) => {
              dispatch2({
                type: "paths values",
                data: [{ path: `occupancy.${key}`, value }],
              });
            }}
            errors={state2.errors.occupancy}
            state={state2.data.occupancy}
          />,
          {
            title: trans("Let's Track Your Occupancy"),
            description: trans(
              "To accurately assess your sustainability performance, we need some details about your hotel's occupancy."
            ),
          },
          { disabled: true }
        );
      case 3:
        return renderStepWrapped(
          <Energy
            status={request.status}
            greenElectricityCertificate={
              state2.files.greenElectricityCertificate
            }
            electricityErrors={state2.errors.electricity}
            electricityState={state2.data.electricity}
            onChange={(key, value) => {
              dispatch2({
                type: "paths values",
                data: [
                  {
                    path: `electricity.${key}` as `${Extract<
                      keyof tState2["data"],
                      "electricity"
                    >}.${keyof tState2["data"]["electricity"]}`,
                    value,
                  },
                ],
              });
            }}
            onSelectFile={(key, file) => {
              dispatch2({ type: "files", files: { [key]: file } });
            }}
          />,
          {
            title: trans("Let's Track Your Energy Usage"),
            description: trans(
              "Accurate energy consumption data is key to understanding and improving your hotel's environmental impact. Let's input your consumption figures for electricity, natural gas, and fossil fuels. This information helps in assessing your energy efficiency and identifying areas for cost-saving and sustainability improvements."
            ),
          },
          { disabled: true }
        );
      case 4: {
        return renderStepWrapped(
          <Fuels
            from={state2.data.from}
            to={state2.data.to}
            status={request.status}
            state={pick(state2.data, [
              "naturalGas",
              "propane",
              "butane",
              "ethanol",
              "biomass",
              "diesel",
              "gasoline",
            ])}
            errors={pick(state2.errors, [
              "naturalGas",
              "propane",
              "butane",
              "ethanol",
              "biomass",
              "diesel",
              "gasoline",
            ])}
            onChange={(key) => (subKey) => (value) => {
              dispatch2({
                type: "paths values",
                data: [{ path: `${key}.${String(subKey)}`, value }],
              });
            }}
          />,
          {
            title: trans("Let's Track Your Fuel Usage"),
            description: trans(
              "Accurate fuel consumption data is key to understanding and improving your hotel's environmental impact. Let's input your consumption figures for natural gas, and fossil fuels. This information helps in assessing your energy efficiency and identifying areas for cost-saving and sustainability improvements."
            ),
          },
          { disabled: true }
        );
      }
      case 5:
        return renderStepWrapped(
          <Water
            status={request.status}
            onChange={(key, value) => {
              dispatch2({
                type: "paths values",
                data: [
                  {
                    path: `water.${key}` as `${Extract<
                      keyof tState2["data"],
                      "water"
                    >}.${keyof tState2["data"]["water"]}`,
                    value,
                  },
                ],
              });
            }}
            state={state2.data.water}
            errors={state2.errors.water}
          />,
          {
            title: trans("Let's Track Your Water Usage"),
            description: trans(
              "Understanding your hotel's water consumption is essential for effective water management and sustainability efforts. Let's input your water usage data. This data will help us calculate your water efficiency and identify opportunities for conservation and cost savings."
            ),
          },
          { disabled: true }
        );
      case 6:
        return renderStepWrapped(
          <Laundry
            status={request.status}
            onChange={(key, value) => {
              dispatch2({
                type: "paths values",
                data: [
                  {
                    path: `laundry.${key}` as `${Extract<
                      keyof tState2["data"],
                      "laundry"
                    >}.${keyof tState2["data"]["laundry"]}`,
                    value,
                  },
                ],
              });
            }}
            state={state2.data.laundry}
            errors={state2.errors.laundry}
          />,
          {
            title: trans("Let's Track Your Laundry Management"),
            description: trans(
              "Laundry operations can significantly impact your accommodations' water and energy consumption. Let's gather some information about how you manage laundry."
            ),
          },
          { disabled: true }
        );
      case 7:
        return renderStepWrapped(
          <Waste
            status={request.status}
            onChange={(key, subKey, value) => {
              dispatch2({
                type: "paths values",
                data: [
                  {
                    path: `waste.${key}.${subKey}` as `${Extract<
                      keyof tState2["data"],
                      "waste"
                    >}.${keyof tState2["data"]["waste"]}.${keyof tState2["data"]["waste"][typeof key]}`,
                    value,
                  },
                ],
              });
            }}
            state={state2.data.waste}
            errors={state2.errors.waste}
          />,
          {
            title: trans("Let's Track Your Waste Management"),
            description: trans(""),
          },
          { disabled: true }
        );
      case 8:
        return (
          <Flex column gap={20}>
            <Header
              title={trans("Confirm Data Insertion")}
              description={trans("")}
            />
            <ActionButtons
              isLoading={deleteRequest.isLoading || request.isLoading}
              onGoBack={() => setStep((prev) => prev - 1)}
              onSaveAndExit={() => handleClose("exit")}
              onDelete={state2.data._id.length ? handleDelete : undefined}
              onSubmit={handleConfirm}
            />
          </Flex>
        );

      default:
        return null;
    }
  };

  return (
    <Modal.Body className="modal-body-pb0">
      <Flex column gap={40}>
        {!singleStep && (
          <StepsHeader
            current={step}
            onClick={(step: number) => {
              setStep(step);
            }}
          />
        )}
        {renderStep()}
      </Flex>
    </Modal.Body>
  );
};

export interface iManualDataModalProps extends Omit<ModalProps, "onClose"> {
  onClose(action: "created" | "back" | "exit" | "deleted"): void;
  manualDataId?: tHotelManualData["_id"];
  initialStep?: keyof Pick<
    tHotelManualData,
    | "electricity"
    | "laundry"
    | "naturalGas"
    | "occupancy"
    | "water"
    | "space"
    | "waste"
  >;
  singleStep?: boolean;
}

const ManualDataModal: React.FC<iManualDataModalProps> = ({
  onClose,
  manualDataId,
  initialStep,
  singleStep = false,
  ...rest
}) => {
  return (
    <Modal
      {...{
        onClose: () => onClose("exit"),
        size: "lg",
        overflow: false,
        keyboard: false,
        backdrop: "static",
        ...rest,
      }}
    >
      {rest.open && (
        <ManualDataModalWrapped
          key={random(0, 10000000)}
          {...{ onClose, manualDataId, initialStep, singleStep }}
        />
      )}
    </Modal>
  );
};

export default ManualDataModal;
