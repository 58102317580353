import { round, sortBy, toInteger, toNumber, uniq } from "lodash";
import moment from "moment";
import React, { useMemo, useRef, useState } from "react";
import Flex from "../../../components/Flex";
import ApexChart from "../../../components/Graphs/Apex/Chart";
import SkeletonText from "../../../components/Skeleton/SkeletonText";
import InterTag from "../../../components/Text/Inter";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";
import { generateGraphCategories } from "../../../utils/graphs";

interface iProps {}

const Graph: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();
  const [graphType] = useState<"line" | "bar">("bar");
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);
  const { manualDataByYear, hotelLoading, hotelIsLoaded } = useHotelState();
  const [selectedYears, setSelectedYears] = useState<number[]>([
    ...sortBy(Object.keys(manualDataByYear).map((y) => toInteger(y))).slice(
      Object.keys(manualDataByYear).length - 3
    ),
  ]);
  const selectedYearsRef = useRef<number[]>([]);

  const [outsourcedKgSeries] = useMemo(() => {
    const outsourcedKgSeries: any[] = [];

    Object.entries(manualDataByYear).forEach((entry) => {
      const [year, list] = entry;

      const outsourcedKgData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      list.forEach((md) => {
        const { from } = md;
        const index = moment(from).month();

        outsourcedKgData[index] = md.laundry?.kgOutsourced || 0;
      });

      outsourcedKgSeries.push({ name: year, data: outsourcedKgData });
    });

    return [outsourcedKgSeries];
  }, [manualDataByYear]);

  const [outsourcedKgValue] = useMemo(() => {
    let outsourcedKgValue = 0;

    selectedYears.forEach((year) => {
      manualDataByYear[year].forEach((md) => {
        outsourcedKgValue += md.laundry?.kgOutsourced || 0;
      });
    });
    return [outsourcedKgValue];
  }, [manualDataByYear, selectedYears]);

  const segments: {
    key: string;
    label: string;
    value: number;
    mainUnit: string;
    series: number[];
    graphColors: string[];
  }[] = [
    {
      key: "outsourcedKg",
      label: trans("general.outsourced"),
      value: outsourcedKgValue,
      mainUnit: "kg",
      series: outsourcedKgSeries,
      graphColors: [COLORS.primary_900, COLORS.primary_600, COLORS.primary_300],
    },
  ];

  const renderSegments = () => {
    if (hotelLoading)
      return (
        <Flex row>
          {segments.map(({ key, label }, i) => {
            let labelColor = COLORS.gray_400;

            if (selectedSegmentIndex === i) {
              labelColor = COLORS.primary;
            }

            return (
              <Flex
                basis={15}
                key={key}
                column
                gap={8}
                style={{
                  cursor: "pointer",
                  padding: "6px",
                  borderRadius: "8px",
                }}
                className="hover-darken-white-bg"
              >
                <InterTag size={12} text={label} color={labelColor} />
                <SkeletonText height={36} width={100} />
              </Flex>
            );
          })}
        </Flex>
      );

    if (hotelIsLoaded)
      return (
        <Flex row>
          {segments.map(({ key, label, mainUnit, value }, i) => {
            let labelColor = COLORS.gray_400;
            let valueColor = COLORS.secondary;

            if (selectedSegmentIndex === i) {
              labelColor = valueColor = COLORS.primary;
            }

            return (
              <Flex
                basis={15}
                key={key}
                column
                gap={8}
                style={{
                  cursor: "pointer",
                  padding: "6px",
                  borderRadius: "8px",
                }}
                className="hover-darken-white-bg"
                onClick={() => {
                  if (selectedSegmentIndex !== i) setSelectedSegmentIndex(i);
                }}
              >
                <InterTag size={12} text={label} color={labelColor} />
                <Flex row bottom>
                  <InterTag size={36} text={value} color={valueColor} />
                  <InterTag size={12} text={mainUnit} color={valueColor} />
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      );
  };

  const renderGraph = () => {
    const categories = generateGraphCategories(
      "month",
      1,
      moment().startOf("year"),
      moment().endOf("year")
    );

    const segment = segments[selectedSegmentIndex];

    const { series, mainUnit, graphColors } = segment;

    return (
      <ApexChart
        {...{
          type: graphType,
          colors: graphColors,
          categories,
          series,
          yaxis: {
            labels: {
              show: true,
              formatter(val: any, opts: any) {
                return `${round(val, 2)} ${mainUnit}`;
              },
            },
          },
          xaxis: {
            categories,
            labels: {
              show: true,
              formatter(value: any, timestamp: any, opts: any) {
                return moment(value).format("MMM");
              },
            },
          },

          legend: {
            show: true,
            showForSingleSeries: true,
          },
          chart: {
            events: {
              legendClick(chart, seriesIndex, options) {
                if (seriesIndex !== undefined) {
                  const year = toNumber(
                    options.globals.seriesNames[seriesIndex]
                  );
                  if (selectedYearsRef.current.includes(year)) {
                    setSelectedYears((prev) => [
                      ...prev.filter((y) => y !== year),
                    ]);
                  } else {
                    setSelectedYears((prev) => sortBy(uniq([...prev, year])));
                  }
                }
              },
            },
          },
        }}
      />
    );
  };

  return (
    <Flex className="card-m" column gap={16}>
      {renderSegments()}
      {renderGraph()}
    </Flex>
  );
};

export default Graph;
