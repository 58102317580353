import { has, round, sum } from "lodash";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "rsuite";
import { ReactComponent as EcoIcon } from "../../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as WaterDropIcon } from "../../../../assets/icons/water_drop.svg";
import TrendingComparison from "../../../../components/Comparions/trendingComparison";
import Flex from "../../../../components/Flex";
import Icon from "../../../../components/Icon";
import SkeletonText from "../../../../components/Skeleton/SkeletonText";
import { iTextProps } from "../../../../components/Text";
import InterTag from "../../../../components/Text/Inter";
import { toTextProps } from "../../../../components/Text/utils";
import useHotelMeasuresTrackedState from "../../../../context/Hotel/hooks/hotelState/useHotelMeasuresTrackedState";
import useHotelState from "../../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useGetMeasures from "../../../../hooks/apiRequests/useGetMeasures";
import nUseGetMeasures from "../../../../hooks/apiRequests/useGetMeasures/interfaces";
import { tIcon } from "../../../../interfaces/icon";
import { TE, tMeasureTE, tMeasureTW, TW } from "../../../../models/measures";
import { COLORS } from "../../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
  DEFAULT_ENERGY_MEASURE_UNIT,
  DEFAULT_MASS_MEASURE_UNIT,
  DEFAULT_WATER_MEASURE_UNIT,
} from "../../../../utils/convertUnits";
import { nUnitConverter } from "../../../../utils/convertUnits/interfaces";
import { getMeasureInfo } from "../../../../utils/measures";
import { thousandsSeparation } from "../../../../utils/numbers";
import { renderRequestedData } from "../../../../utils/render";

type tCardMeasures = {
  to: string;
  label: string | iTextProps;
  tooltip?: string;
  icon: tIcon;
  key: "te" | "tw" | "co2";
  valueFromMeasuresRequest(
    data: nUseGetMeasures.tFunctionReturn<[tMeasureTE | tMeasureTW]>["data"]
  ): number;
  unit: string;
  converter: nUnitConverter.tFunction;
};

interface iProps {
  period: [Date, Date];
}

const ConsumptionCards: React.FC<iProps> = ({ period }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { hotel } = useHotelState();
  const periodParams = period
    ? {
        from: moment(period[0]).toISOString(),
        to: moment(period[1]).toISOString(),
      }
    : {};
  const homologuePeriodParams = period
    ? {
        from: moment(period[0]).subtract(1, "year").toISOString(),
        to: moment(period[1]).subtract(1, "year").toISOString(),
      }
    : {};

  const measuresRequest = useGetMeasures<[tMeasureTE | tMeasureTW]>(hotel, {
    measures: [TE, TW],
    ...periodParams,
  });
  const homologueMeasuresRequest = useGetMeasures<[tMeasureTE | tMeasureTW]>(
    hotel,
    {
      measures: [TE, TW],
      ...homologuePeriodParams,
    }
  );

  const { getMeasuresTracked } = useHotelMeasuresTrackedState();

  const measuresList: tCardMeasures[] = [];

  const trackedMeasures = getMeasuresTracked();

  if (trackedMeasures.te || trackedMeasures.tw) {
    measuresList.push({
      to: "/consumption",
      label: { text: trans("general.co2_emissions"), asHTML: true },
      icon: { Element: EcoIcon, fill: COLORS.esg_environment },
      key: "co2",
      valueFromMeasuresRequest(data) {
        return has(data, "co2") ? sum(data.co2) : 0;
      },
      unit: DEFAULT_MASS_MEASURE_UNIT,
      converter: convertMassUnit,
    });

    if (trackedMeasures.te) {
      measuresList.push({
        to: "/consumption/electricity",
        label: trans("general.measures.electricity"),
        icon: { Element: ElectricBoltIcon, fill: getMeasureInfo(TE).color },
        key: "te",
        valueFromMeasuresRequest(data) {
          return has(data, ["grouped", "te", "measurements"])
            ? sum(data.grouped.te.measurements)
            : 0;
        },
        unit: DEFAULT_ENERGY_MEASURE_UNIT,
        converter: convertEnergyUnit,
      });
    }

    if (trackedMeasures.tw) {
      measuresList.push({
        to: "/consumption/water",
        label: trans("general.measures.water"),
        icon: { Element: WaterDropIcon, fill: getMeasureInfo(TW).color },
        key: "tw",
        valueFromMeasuresRequest(data) {
          return has(data, ["grouped", "tw", "measurements"])
            ? sum(data.grouped.tw.measurements)
            : 0;
        },
        unit: DEFAULT_WATER_MEASURE_UNIT,
        converter: convertVolumeUnit,
      });
    }
  }

  return renderRequestedData(measuresRequest.status, {
    pending: () => (
      <Row gutter={16}>
        {measuresList.map(({ to, label, icon }, k) => (
          <Col key={k} sm={8} xxl={4}>
            <Flex
              style={{ cursor: "pointer" }}
              className="card-m hover-box-shadow"
              column
              gap={20}
              onClick={() => {
                navigate(to);
              }}
            >
              <Flex row gap={8} left middle>
                <Icon {...{ size: 24, ...icon }} />
                <InterTag
                  {...{
                    size: 20,
                    color: COLORS.secondary,
                    ...toTextProps(label),
                  }}
                />
              </Flex>
              <Flex column middle gap={4}>
                <SkeletonText width={100} height={36} />
                <SkeletonText width={100} height={24} />
              </Flex>
            </Flex>
          </Col>
        ))}
      </Row>
    ),
    resolved: () => (
      <Row gutter={16}>
        {measuresList.map(
          (
            { to, label, icon, key, unit, valueFromMeasuresRequest, converter },
            k
          ) => {
            const value = valueFromMeasuresRequest(measuresRequest.data);
            const converted = converter(value, { from: unit });
            return (
              <Col key={k} sm={8} xxl={4}>
                <Flex
                  style={{ cursor: "pointer" }}
                  column
                  className="card-m hover-box-shadow"
                  gap={20}
                  onClick={() => {
                    navigate(to);
                  }}
                >
                  <Flex row gap={8} left middle>
                    <Icon {...{ size: 24, ...icon }} />
                    <InterTag
                      {...{
                        size: 20,
                        color: COLORS.secondary,
                        ...toTextProps(label),
                      }}
                    />
                  </Flex>
                  <Flex column middle gap={4}>
                    <Flex row gap={4} bottom>
                      <InterTag
                        size={36}
                        text={thousandsSeparation(round(converted.value, 2))}
                      />
                      <InterTag size={16} text={converted.unitInHTML} asHTML />
                    </Flex>
                    {renderRequestedData(homologueMeasuresRequest.status, {
                      pending: () => <SkeletonText width={100} height={16} />,
                      resolved: () => (
                        <TrendingComparison
                          baseValue={valueFromMeasuresRequest(
                            homologueMeasuresRequest.data
                          )}
                          value={value}
                        />
                      ),
                    })}
                  </Flex>
                </Flex>
              </Col>
            );
          }
        )}
      </Row>
    ),
  });
};

export default ConsumptionCards;
