import axios from "axios";
import React, { Fragment, useState } from "react";
import { Button, Checkbox, Input, InputGroup, useToaster } from "rsuite";
import { ReactComponent as MailIcon } from "../../../assets/icons/mail.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InputWrapper from "../../../components/InputWrapper";
import PrivacyNoticeModal from "../../../components/Modals/PrivacyNoticeModal";
import TermsOfServiceModal from "../../../components/Modals/TermsOfService";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useApiRequest from "../../../hooks/apiRequests/useApiRequest";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { notification } from "../../../utils/notifications";
import { tRegisterState2 } from "./register";

type tDataKeys = keyof Pick<tRegisterState2["data"], "email" | "termsAccepted">;

interface iProps {
  state: Pick<tRegisterState2["data"], tDataKeys>;
  errors: Pick<tRegisterState2["errors"], tDataKeys>;
  onChange(key: tDataKeys, value: any): void;
  setErrors(errors: Partial<Pick<tRegisterState2["errors"], tDataKeys>>): void;
  onConfirm(): void;
}

const Step1: React.FC<iProps> = ({
  state,
  errors,
  onChange,
  setErrors,
  onConfirm,
}) => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const verifyRequest = useApiRequest();
  const [privacyNoticeModal, setPrivacyNoticeModal] = useState({ open: false });
  const [termsOfServiceModal, setTermsOfServiceModal] = useState({
    open: false,
  });

  const onClickSignUp = () => {
    const errorsObj: Partial<typeof errors> = {};
    if (!state.termsAccepted) {
      errorsObj.termsAccepted = trans(
        "pages.register.errors.terms_not_accepted"
      );
    }

    if (!state.email.length) {
      errorsObj.email = trans("pages.register.errors.no_email");
    } else if (
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(state.email)
    ) {
      errorsObj.email = trans("pages.register.errors.invalid_email");
    }
    if (Object.keys(errorsObj).length) {
      return setErrors(errorsObj);
    }

    verifyRequest.pending();
    axios
      .get(`${apiAddress(false)}/v2/managers/verify-email`, {
        params: { email: state.email },
      })
      .then((res) => {
        verifyRequest.resolve();
        onConfirm();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        verifyRequest.reject(error);
        toaster.push(notification("error", error), {
          placement: "topEnd",
        });
      });
  };

  return (
    <Fragment>
      <PrivacyNoticeModal
        open={privacyNoticeModal.open}
        language="en"
        onClose={() => setPrivacyNoticeModal({ open: false })}
      />
      <TermsOfServiceModal
        open={termsOfServiceModal.open}
        language="en"
        onClose={() => setTermsOfServiceModal({ open: false })}
      />
      <Flex gap={8} column middle style={{ marginTop: "24px" }}>
        <InterTag
          bold
          text={trans("pages.register.greetings[0]")}
          size={32}
          color={COLORS.secondary}
        />
        <InterTag
          style={{ textAlign: "center" }}
          bold
          wrap
          text={trans("pages.register.greetings[1]")}
          size={32}
          color={COLORS.secondary}
        />
      </Flex>
      <div className="width-100" style={{ marginTop: "32px" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onClickSignUp();
          }}
        >
          <Flex column gap={4}>
            <Flex gap={4} className="width-100" column>
              <InputWrapper
                required
                label={trans("general.email")}
                error={errors.email}
              >
                <InputGroup disabled={verifyRequest.isLoading}>
                  <InputGroup.Addon className="bg-white">
                    <Icon
                      Element={MailIcon}
                      width={24}
                      height={24}
                      fill={COLORS.secondary}
                    />
                  </InputGroup.Addon>
                  <Input
                    className="bg-white"
                    value={state.email}
                    onChange={(value) => onChange("email", value)}
                    type="text"
                    placeholder={trans("general.placeholders.email")}
                    size="lg"
                  />
                </InputGroup>
              </InputWrapper>
            </Flex>
            <Flex column gap={0}>
              <Flex row gap={8} middle>
                <Checkbox
                  disabled={verifyRequest.isLoading}
                  checked={state.termsAccepted}
                  onChange={(_, checked) => {
                    onChange("termsAccepted", checked);
                  }}
                />
                <Flex row gap={4} style={{ marginTop: "4px" }}>
                  <InterTag
                    text={trans("pages.register.checkbox[0]")}
                    color={COLORS.gray_400}
                    size={12}
                    onClick={() =>
                      onChange("termsAccepted", !state.termsAccepted)
                    }
                  />
                  <InterTag
                    text={trans("pages.register.checkbox[1]")}
                    color={COLORS.primary}
                    size={12}
                    onClick={() => {
                      setTermsOfServiceModal({ open: true });
                    }}
                    hoverUnderline
                  />
                  <InterTag
                    text={trans("pages.register.checkbox[2]")}
                    color={COLORS.gray_400}
                    size={12}
                    onClick={() =>
                      onChange("termsAccepted", !state.termsAccepted)
                    }
                  />
                  <InterTag
                    text={trans("pages.register.checkbox[3]")}
                    color={COLORS.primary}
                    size={12}
                    onClick={() => {
                      setPrivacyNoticeModal({ open: true });
                    }}
                    hoverUnderline
                  />
                </Flex>
              </Flex>
              {errors.termsAccepted.length > 0 && (
                <InterTag
                  text={errors.termsAccepted}
                  color={COLORS.error}
                  size={10}
                />
              )}
            </Flex>
          </Flex>
          <input type="submit" hidden />
        </form>
      </div>
      <div className="width-100" style={{ marginTop: "24px" }}>
        <Button
          disabled={verifyRequest.isLoading}
          onClick={onClickSignUp}
          block
          appearance="primary"
          loading={verifyRequest.isLoading}
        >
          <InterTag
            style={{ opacity: verifyRequest.isLoading ? 0 : 1 }}
            text={trans("general.signup")}
            bold
            color={COLORS.white}
            size={20}
          />
        </Button>
      </div>
    </Fragment>
  );
};

export default Step1;
