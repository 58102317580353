import React, { Fragment, useState } from "react";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/category.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../assets/icons/meeting_room.svg";
import { ReactComponent as StacksIcon } from "../../../assets/icons/stacks.svg";
import { ReactComponent as WorkSpacesIcon } from "../../../assets/icons/workspaces.svg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SimpleWhisperPopoverDropdown from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { tIconElement } from "../../../interfaces/icon";
import { tHotelSpaceAggregate } from "../../../models/hotel";
import SpaceAggregateModal from "./spaceAggregateModal";
import SpaceModal from "./spaceModal";

interface iProps {
  placement?: TypeAttributes.Placement;
}

const AddButton: React.FC<iProps> = ({ placement = "bottomEnd" }) => {
  const { trans } = useLocalizationState();
  const [spaceModal, setSpaceModal] = useState<{ open: boolean }>({
    open: false,
  });
  const [aggModal, setAggModal] = useState<
    { open: false } | { open: true; category: tHotelSpaceAggregate["category"] }
  >({ open: false });

  const profilePermissions = useProfilePermissions();

  const options: {
    key: string;
    label: string;
    onClick(): void;
    icon: tIconElement;
  }[] = [];

  if (profilePermissions.writeSpaces)
    options.push({
      key: "spaces",
      label: trans("general.spaces"),
      onClick: () => {
        setSpaceModal((prev) => ({ ...prev, open: true }));
      },
      icon: MeetingRoomIcon,
    });

  if (profilePermissions.writeSpaceAggregates)
    options.push(
      {
        key: "groups",
        label: trans("general.space_categories.groups"),
        onClick: () => {
          setAggModal({ open: true, category: "group" });
        },
        icon: WorkSpacesIcon,
      },
      {
        key: "types",
        label: trans("general.space_categories.types"),
        onClick: () => {
          setAggModal({ open: true, category: "type" });
        },
        icon: CategoryIcon,
      },
      {
        key: "zones",
        label: trans("general.space_categories.zones"),
        onClick: () => {
          setAggModal({ open: true, category: "zone" });
        },
        icon: StacksIcon,
      }
    );

  if (options.length === 0) return null;

  return (
    <Fragment>
      <SpaceModal
        onClose={() => setSpaceModal((prev) => ({ ...prev, open: false }))}
        open={spaceModal.open}
      />
      {aggModal.open && (
        <SpaceAggregateModal
          onClose={() => setAggModal({ open: false })}
          category={aggModal.category}
          open={true}
        />
      )}
      <SimpleWhisperPopoverDropdown options={options} {...{ placement }}>
        <PrimaryButton
          label={trans("general.add").toUpperCase()}
          icon={AddIcon}
          size="sm"
        />
      </SimpleWhisperPopoverDropdown>
    </Fragment>
  );
};

export default AddButton;
