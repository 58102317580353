import { round } from "lodash";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Col, Grid, Row } from "rsuite";
import { useWindowSize } from "usehooks-ts";
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/delete.svg";
import { ReactComponent as EcoIcon } from "../../../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as GroupIcon } from "../../../../../assets/icons/group.svg";
import { ReactComponent as HotelIcon } from "../../../../../assets/icons/hotel.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../../../assets/icons/meeting_room.svg";
import { ReactComponent as ModeHeatIcon } from "../../../../../assets/icons/mode_heat.svg";
import { ReactComponent as ReadinessScoreIcon } from "../../../../../assets/icons/readiness_score.svg";
import { ReactComponent as RecyclingIcon } from "../../../../../assets/icons/recycling.svg";
import { ReactComponent as WaterDropIcon } from "../../../../../assets/icons/water_drop.svg";
import StraightComparison from "../../../../../components/Comparions/straightComparison";
import TrendingComparison from "../../../../../components/Comparions/trendingComparison";
import Flex from "../../../../../components/Flex";
import Icon from "../../../../../components/Icon";
import ManualDataModal, {
  iManualDataModalProps,
} from "../../../../../components/Modals/ManualDataModal";
import PageSectionTitle from "../../../../../components/PageSectionTitle";
import SkeletonText from "../../../../../components/Skeleton/SkeletonText";
import { iTextProps } from "../../../../../components/Text";
import InterTag from "../../../../../components/Text/Inter";
import { toTextProps } from "../../../../../components/Text/utils";
import useHotelState from "../../../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../../../context/Localization/hooks/useLocalizationState";
import { useGetBenchmarkData } from "../../../../../hooks/useGetBenchmarkData";
import useGetDefaultConverionFactors from "../../../../../hooks/useGetDefaultConverionFactors";
import { tIcon } from "../../../../../interfaces/icon";
import { tHotelManualData } from "../../../../../models/hotel";
import { COLORS } from "../../../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
} from "../../../../../utils/convertUnits/";
import { nUnitConverter } from "../../../../../utils/convertUnits/interfaces";
import { thousandsSeparation } from "../../../../../utils/numbers";
import { renderRequestedData } from "../../../../../utils/render";
import { SustainabilityContext } from "../../context";

interface iBenchmarkWrappedProps extends iProps {}

const BenchmarkWrapped: React.FC<iBenchmarkWrappedProps> = ({
  manualDataIndex,
  conversionFactorsRequest,
}) => {
  const windowSize = useWindowSize();
  const { trans } = useLocalizationState();
  const [manualDataModal, setManualDataModal] = useState<{
    open: boolean;
    manualDataId?: tHotelManualData["_id"];
    initialStep?: iManualDataModalProps["initialStep"];
  }>({
    open: false,
    manualDataId: undefined,
    initialStep: undefined,
  });
  const benchmarkRequest = useGetBenchmarkData();
  const { setDataTypeToggleDisabled } = useContext(SustainabilityContext);
  const {
    hotel,
    hotelLoading,
    hotelIsLoaded,
    getHotelDimensionsInTimeframe,
    activeSubscriptionType,
  } = useHotelState();

  const manualData = hotel.manualData[manualDataIndex];

  const hotelDimensions = getHotelDimensionsInTimeframe(
    (moment(manualData.from).valueOf() + moment(manualData.to).valueOf()) / 2
  );

  useEffect(() => {
    setDataTypeToggleDisabled({ benchmarkRequest: benchmarkRequest.isLoading });
  }, [benchmarkRequest.isLoading, setDataTypeToggleDisabled]);

  const renderCell = (
    icon: tIcon,
    label: string | iTextProps,
    main: { value: number; unit: string; loading?: boolean },
    homologue: { value: number; unit: string },
    converter: nUnitConverter.tFunction,
    benchmark?: { value: number; unit: string; loading?: boolean }
  ) => {
    const renderMainValue = () => {
      if (hotelLoading || main.loading)
        return <SkeletonText width={100} height={36} />;
      if (hotelIsLoaded) {
        const converted = converter(main.value, {
          from: main.unit,
          forceUnit: null,
        });
        return (
          <Flex row gap={4} bottom>
            <InterTag
              size={36}
              color={COLORS.secondary}
              text={thousandsSeparation(round(converted.value, 2).toFixed(2))}
            />
            <InterTag
              size={16}
              color={COLORS.secondary}
              text={converted.unitInHTML}
              asHTML
            />
          </Flex>
        );
      }
    };
    const renderHomologueComparison = (
      value: number,
      homologueValue: number
    ) => {
      return <TrendingComparison baseValue={homologueValue} value={value} />;
    };
    const renderBenchmark = () => {
      if (!benchmark) return null;

      if (benchmarkRequest.isLoading)
        return <SkeletonText height={16} width={50} />;

      if (benchmarkRequest.isResolved) {
        const converted = converter(main.value, {
          from: main.unit,
          forceUnit: null,
        });
        const benchmarkConverted = converter(benchmark.value, {
          from: benchmark.unit,
          forceUnit: converted.unit,
        });

        return (
          <InterTag
            size={16}
            color={COLORS.secondary}
            text={`${thousandsSeparation(
              round(benchmarkConverted.value, 2).toFixed(2)
            )} ${benchmarkConverted.unitInHTML}`}
            asHTML
          />
        );
      }
    };
    const renderBenchmarkComparison = () => {
      if (!benchmark) return null;

      if (benchmarkRequest.isLoading || benchmark.loading)
        return <SkeletonText height={36} width={100} />;

      if (benchmarkRequest.isResolved)
        return (
          <StraightComparison value={main.value} baseValue={benchmark.value} />
        );
    };

    return (
      <Flex gap={10} column color={COLORS.white}>
        <Flex row gap={4} middle>
          <Icon {...{ size: 24, ...icon }} />
          <InterTag
            size={12}
            color={COLORS.gray}
            {...{ ...toTextProps(label) }}
          />
        </Flex>
        <Flex row>
          <Flex one left>
            <div style={{ paddingLeft: "40px" }}>{renderMainValue()}</div>
          </Flex>
          <Flex one right>
            <div style={{ paddingRight: "40px" }}>
              {renderBenchmarkComparison()}
            </div>
          </Flex>
        </Flex>
        <Flex row>
          <Flex one>
            <div></div>
          </Flex>
          <Flex one right>
            <div style={{ paddingRight: "40px" }}>{renderBenchmark()}</div>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderDivider = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: COLORS.gray_200,
        }}
      />
    );
  };

  const realElectricity_kWh =
    (manualData.electricity?.totalKWh || 0) +
    (manualData.laundry?.outsourcedElectricityConsumptionKWh || 0);

  const realWater_m3 =
    (manualData.water?.totalM3 || 0) +
    (manualData.laundry?.outsourcedWaterConsumptionM3 || 0);

  const realFuel_kWh = manualData.naturalGas?.totalKWh || 0;
  const realWaste_kg = manualData.waste
    ? manualData.waste.glass.totalKg +
      manualData.waste.mixed.totalKg +
      manualData.waste.organic.totalKg +
      manualData.waste.paper.totalKg +
      manualData.waste.plastic.totalKg
    : 0;
  const realRecyclableWaste_kg = manualData.waste
    ? manualData.waste.glass.totalKg +
      manualData.waste.paper.totalKg +
      manualData.waste.plastic.totalKg
    : 0;

  const realCO2_kg =
    realElectricity_kWh *
      conversionFactorsRequest.data.electricity_kWh_to_kgCO2e +
    realWater_m3 * conversionFactorsRequest.data.water_m3_to_kgCO2e +
    realFuel_kWh * conversionFactorsRequest.data.natural_gas_kWh_to_kgCO2e;

  const energyPerAreaCard = (
    <Flex column gap={16} basis={50} className="card-sm">
      <Flex row gap={4} middle>
        <Icon Element={MeetingRoomIcon} size={24} fill={COLORS.secondary} />
        <InterTag
          size={20}
          color={COLORS.secondary}
          text={trans(
            "pages.sustainability.sections.indicators.cards.energyPerArea.title"
          )}
          asHTML
        />
      </Flex>
      {hotelDimensions && hotelDimensions.totalAreaM2 > 0 ? (
        <>
          {(() => {
            const value = realElectricity_kWh / hotelDimensions.totalAreaM2;
            const converted = convertEnergyUnit(value, { from: "kWh" });

            return (
              <Flex row>
                <Flex one left>
                  <Flex row bottom gap={4} style={{ paddingLeft: "40px" }}>
                    <InterTag
                      size={36}
                      color={COLORS.secondary}
                      text={thousandsSeparation(round(converted.value, 2))}
                    />
                    <InterTag
                      size={16}
                      color={COLORS.secondary}
                      text={`${converted.unitInHTML}/m<sup>2</sup>`}
                      asHTML
                    />
                  </Flex>
                </Flex>
                {benchmarkRequest.isLoading ? (
                  <SkeletonText width={150} height={36} />
                ) : (
                  <Flex one right style={{ paddingRight: "40px" }}>
                    <StraightComparison
                      baseValue={benchmarkRequest.data.perM2.electricity_kWh}
                      value={value}
                    />
                  </Flex>
                )}
              </Flex>
            );
          })()}
          <Flex row between>
            <Flex one left>
              <div style={{ paddingLeft: "40px" }}></div>
            </Flex>
            <Flex one right>
              <div style={{ paddingRight: "40px" }}>
                {renderRequestedData(benchmarkRequest.status, {
                  pending: () => <SkeletonText width={90} height={16} />,
                  resolved: () => (
                    <InterTag
                      size={16}
                      color={COLORS.secondary}
                      text={`${thousandsSeparation(
                        benchmarkRequest.data.perM2.electricity_kWh
                      )} kWh/m<sup>2</sup>`}
                      asHTML
                    />
                  ),
                })}
              </div>
            </Flex>
          </Flex>
        </>
      ) : (
        <InterTag
          hoverUnderline
          size={14}
          color={COLORS.primary}
          text={trans(
            "pages.sustainability.sections.indicators.cards.energyPerArea.noData"
          )}
          onClick={() =>
            setManualDataModal({
              open: true,
              manualDataId: manualData._id,
              initialStep: "space",
            })
          }
        />
      )}
    </Flex>
  );

  const renewableEnergyValue = manualData.electricity?.isElectricityGreen
    ? 100
    : 0;

  const renewableEnergyCard = (
    <Flex column gap={16} basis={50} className="card-sm">
      <Flex row gap={4} middle>
        <Icon Element={ElectricBoltIcon} size={24} fill={COLORS.primary} />
        <InterTag
          size={20}
          color={COLORS.secondary}
          text={trans(
            "pages.sustainability.sections.indicators.cards.renewableEnergy.title"
          )}
          asHTML
        />
      </Flex>
      <Flex row>
        <Flex one left>
          <Flex row gap={4} bottom style={{ paddingLeft: "40px" }}>
            <InterTag
              size={36}
              text={renewableEnergyValue}
              color={COLORS.secondary}
            />
            <InterTag size={16} text={"%"} color={COLORS.secondary} />
          </Flex>
        </Flex>
        <Flex one right>
          <div style={{ paddingRight: "40px" }}>
            <StraightComparison
              moreIsGood
              value={renewableEnergyValue}
              baseValue={100}
            />
          </div>
        </Flex>
      </Flex>
      <Flex row gap={40}>
        <Flex one left>
          <div style={{ paddingLeft: "40px" }}></div>
        </Flex>
        <Flex one right>
          <div style={{ paddingRight: "40px" }}>
            <InterTag
              size={16}
              color={COLORS.secondary}
              text={trans("100 %")}
            />
          </div>
        </Flex>
      </Flex>
    </Flex>
  );

  const recyclingCard = (
    <Flex column gap={16} basis={50} className="card-sm">
      <Flex row gap={4} middle>
        <Icon Element={RecyclingIcon} size={24} fill={COLORS.primary} />
        <InterTag
          size={20}
          color={COLORS.secondary}
          text={trans(
            "pages.sustainability.sections.indicators.cards.recycledWaste.title"
          )}
          asHTML
        />
      </Flex>
      <Flex row>
        <Flex one left>
          <Flex row gap={4} bottom style={{ paddingLeft: "40px" }}>
            <InterTag
              size={36}
              text={
                realWaste_kg
                  ? round((realRecyclableWaste_kg / realWaste_kg) * 100, 2)
                  : 0
              }
              color={COLORS.secondary}
            />
            <InterTag size={16} text={"kg"} color={COLORS.secondary} />
          </Flex>
        </Flex>
        <Flex row right>
          <div style={{ paddingRight: "40px" }}>
            <StraightComparison
              moreIsGood
              value={
                realWaste_kg
                  ? round((realRecyclableWaste_kg / realWaste_kg) * 100, 2)
                  : 0
              }
              baseValue={84}
            />
          </div>
        </Flex>
      </Flex>
      <Flex row>
        <Flex one left>
          <div style={{ paddingLeft: "40px" }}></div>
        </Flex>
        <Flex one right>
          <div style={{ paddingRight: "40px" }}>
            <InterTag size={16} color={COLORS.secondary} text={trans("84 %")} />
          </div>
        </Flex>
      </Flex>
    </Flex>
  );

  const onlyAvailableOnBusiness = (icon: tIcon, label: string) => {
    return (
      <Flex column gap={16}>
        <Flex row gap={4} middle>
          <Icon size={24} fill={COLORS.secondary} {...{ ...icon }} />
          <InterTag size={12} color={COLORS.gray} text={label} asHTML />
        </Flex>
        <InterTag text={trans("general.business only")} />
      </Flex>
    );
  };

  const mainCards = (
    <Fragment>
      <Flex one column className="card-sm" gap={16}>
        <Flex row gap={4}>
          <Icon Element={HotelIcon} fill={COLORS.secondary} size={24} />
          <InterTag
            size={20}
            color={COLORS.secondary}
            text={`${trans(
              "pages.sustainability.sections.indicators.cards.accommodation.title"
            )} (${manualData.occupancy?.numberOfStays || 0})`}
          />
        </Flex>
        {manualData.occupancy?.numberOfStays ? (
          <>
            {renderCell(
              { Element: EcoIcon, fill: COLORS.emissions },
              { text: trans("general.co2_emissions"), asHTML: true },
              {
                value: realCO2_kg / manualData.occupancy.numberOfStays,
                unit: "kg",
                loading: conversionFactorsRequest.isLoading,
              },
              { value: 0, unit: "" },
              convertMassUnit,
              {
                value: benchmarkRequest.data.perAccommodation.co2_kg,
                unit: "kg",
                loading: conversionFactorsRequest.isLoading,
              }
            )}
            {renderDivider()}
            {renderCell(
              { Element: ElectricBoltIcon, fill: COLORS.energy },
              trans("general.measures.electricity"),
              {
                value: realElectricity_kWh / manualData.occupancy.numberOfStays,
                unit: "kWh",
              },
              { value: 0, unit: "" },
              convertEnergyUnit,
              {
                value: benchmarkRequest.data.perAccommodation.electricity_kWh,
                unit: "kWh",
              }
            )}
            {renderDivider()}
            {renderCell(
              { Element: WaterDropIcon, fill: COLORS.water },
              trans("general.measures.water"),
              {
                value: realWater_m3 / manualData.occupancy.numberOfStays,
                unit: "m3",
              },
              { value: 0, unit: "" },
              convertVolumeUnit,
              {
                value: benchmarkRequest.data.perAccommodation.water_m3,
                unit: "m3",
              }
            )}
            {renderDivider()}
            {activeSubscriptionType === "starter"
              ? onlyAvailableOnBusiness(
                  { Element: ModeHeatIcon, fill: COLORS.fuels },
                  trans("general.fuels")
                )
              : renderCell(
                  { Element: ModeHeatIcon, fill: COLORS.fuels },
                  trans("general.fuels"),
                  {
                    value: realFuel_kWh / manualData.occupancy.numberOfStays,
                    unit: "kWh",
                  },
                  { value: 0, unit: "" },
                  convertEnergyUnit,
                  {
                    value: benchmarkRequest.data.perAccommodation.fuels_kWh,
                    unit: "kWh",
                  }
                )}
            {renderDivider()}
            {activeSubscriptionType === "starter"
              ? onlyAvailableOnBusiness(
                  { Element: DeleteIcon, fill: COLORS.waste },
                  trans("general.waste")
                )
              : renderCell(
                  { Element: DeleteIcon, fill: COLORS.waste },
                  trans("general.waste"),
                  {
                    value: realWaste_kg / manualData.occupancy.numberOfStays,
                    unit: "kg",
                  },
                  { value: 0, unit: "" },
                  convertMassUnit,
                  {
                    value: benchmarkRequest.data.perAccommodation.waste_kg,
                    unit: "kg",
                  }
                )}
          </>
        ) : null}
      </Flex>
      <Flex
        one
        column
        color={COLORS.white}
        style={{ padding: 16, borderRadius: "8px" }}
        gap={16}
      >
        <Flex row gap={4}>
          <Icon Element={GroupIcon} fill={COLORS.secondary} size={24} />
          <InterTag
            size={20}
            color={COLORS.secondary}
            text={`${trans(
              "pages.sustainability.sections.indicators.cards.guest.title"
            )} (${manualData.occupancy?.numberOfGuests || 0})`}
          />
        </Flex>
        {manualData.occupancy?.numberOfGuests ? (
          <>
            {renderCell(
              { Element: EcoIcon, fill: COLORS.emissions },
              { text: trans("general.co2_emissions"), asHTML: true },
              {
                value: realCO2_kg / manualData.occupancy.numberOfGuests,
                unit: "kg",
                loading: conversionFactorsRequest.isLoading,
              },
              { value: 0, unit: "" },
              convertMassUnit,
              {
                value: benchmarkRequest.data.perGuest.co2_kg,
                unit: "kg",
                loading: conversionFactorsRequest.isLoading,
              }
            )}
            {renderDivider()}
            {renderCell(
              { Element: ElectricBoltIcon, fill: COLORS.energy },
              trans("general.measures.electricity"),
              {
                value:
                  realElectricity_kWh / manualData.occupancy.numberOfGuests,
                unit: "kWh",
              },
              { value: 0, unit: "" },
              convertEnergyUnit,
              {
                value: benchmarkRequest.data.perGuest.electricity_kWh,
                unit: "kWh",
              }
            )}
            {renderDivider()}
            {renderCell(
              { Element: WaterDropIcon, fill: COLORS.water },
              trans("general.measures.water"),
              {
                value: realWater_m3 / manualData.occupancy.numberOfGuests,
                unit: "m3",
              },
              { value: 0, unit: "" },
              convertVolumeUnit,
              {
                value: benchmarkRequest.data.perGuest.water_m3,
                unit: "m3",
              }
            )}
            {renderDivider()}
            {activeSubscriptionType === "starter"
              ? onlyAvailableOnBusiness(
                  { Element: ModeHeatIcon, fill: COLORS.fuels },
                  trans("general.fuels")
                )
              : renderCell(
                  { Element: ModeHeatIcon, fill: COLORS.fuels },
                  trans("general.fuels"),
                  {
                    value: realFuel_kWh / manualData.occupancy.numberOfGuests,
                    unit: "kWh",
                  },
                  { value: 0, unit: "" },
                  convertEnergyUnit,
                  {
                    value: benchmarkRequest.data.perGuest.fuels_kWh,
                    unit: "kWh",
                  }
                )}
            {renderDivider()}
            {activeSubscriptionType === "starter"
              ? onlyAvailableOnBusiness(
                  { Element: DeleteIcon, fill: COLORS.waste },
                  trans("general.waste")
                )
              : renderCell(
                  { Element: DeleteIcon, fill: COLORS.waste },
                  trans("general.waste"),
                  {
                    value: realWaste_kg / manualData.occupancy.numberOfGuests,
                    unit: "kg",
                  },
                  { value: 0, unit: "" },
                  convertMassUnit,
                  {
                    value: benchmarkRequest.data.perGuest.waste_kg,
                    unit: "kg",
                  }
                )}
          </>
        ) : null}
      </Flex>
    </Fragment>
  );

  return (
    <>
      <ManualDataModal
        open={manualDataModal.open}
        onClose={() => {
          setManualDataModal({ open: false });
        }}
        manualDataId={manualDataModal.manualDataId}
        initialStep={manualDataModal.initialStep}
      />
      {windowSize.width >= 1720 ? (
        <Flex row gap={24}>
          {mainCards}
          <Flex one column gap={24}>
            <div>{energyPerAreaCard}</div>
            <div>{renewableEnergyCard}</div>
            <div>{recyclingCard}</div>
          </Flex>
        </Flex>
      ) : (
        <Flex column gap={16}>
          <Flex row gap={24}>
            {mainCards}
          </Flex>
          <div>
            <Grid fluid>
              <Row gutter={24}>
                <Col xs={12}>{energyPerAreaCard}</Col>
                <Col xs={12}>{renewableEnergyCard}</Col>
              </Row>
              <Row gutter={24} style={{ marginTop: "8px" }}>
                <Col xs={12}>{recyclingCard}</Col>
              </Row>
            </Grid>
          </div>
        </Flex>
      )}
    </>
  );
};

interface iProps {
  manualDataIndex: number;
  conversionFactorsRequest: ReturnType<typeof useGetDefaultConverionFactors>;
}

const Benchmark: React.FC<iProps> = ({
  manualDataIndex,
  conversionFactorsRequest,
}) => {
  const { trans } = useLocalizationState();
  return (
    <Flex column gap={16}>
      <PageSectionTitle
        icon={ReadinessScoreIcon}
        title={trans("pages.sustainability.sections.indicators.title")}
        description={trans(
          "pages.sustainability.sections.indicators.description"
        )}
      />
      <BenchmarkWrapped {...{ manualDataIndex, conversionFactorsRequest }} />
    </Flex>
  );
};

export default Benchmark;
