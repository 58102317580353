import axios from "axios";
import React, { useState } from "react";
import { InlineEdit, Input, Loader } from "rsuite";
import { ReactComponent as StoreFrontIcon } from "../../../assets/icons/storefront.svg";
import Flex from "../../../components/Flex";
import PageSectionTitle from "../../../components/PageSectionTitle";
import InterTag from "../../../components/Text/Inter";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePatchRequest from "../../../hooks/apiRequests/usePostRequest";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";

const MAX_DESCRIPTION_LENGTH = 2000;

interface EditDescriptionWrappedProps {}

const EditDescriptionWrapped: React.FC<EditDescriptionWrappedProps> = () => {
  const { trans } = useLocalizationState();
  const { hotel, hotelId } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const [description, setDescription] = useState(
    () => hotel.guestApp.description || ""
  );
  const request = usePatchRequest();

  const onSave = (event: any) => {
    if (description.length < 3) {
      return setDescription(hotel.guestApp.description || "");
    }
    if (
      event.type === "click" ||
      (event.type === "keydown" && event.key === "Enter")
    ) {
      if (description !== hotel.guestApp.description) {
        request.pending();
        axios
          .patch(`${apiAddress(false)}/v2/hotels/${hotelId}/guest-app`, {
            description,
          })
          .then((res) => {
            const {
              data: { hotel },
            } = res;
            updateHotel(hotelId, hotel);
            request.resolve(
              undefined,
              trans(
                "pages.hotel_profile.sections.description.notifications.description_changed"
              )
            );
          })
          .catch((err) => {
            const error = getErrorMessage(err, trans);
            request.reject(error, true);
            setDescription(hotel.guestApp.description || "");
          });
      }
      return;
    }
    return setDescription(hotel.guestApp.description || "");
  };

  return (
    <Flex column gap={8}>
      {request.isLoading ? (
        <Flex
          style={{
            height: "34px",
            backgroundColor: COLORS.gray_300,
            borderRadius: "8px",
            border: `1px solid ${COLORS.gray_200}`,
            paddingLeft: "8px",
          }}
          middle
        >
          <Loader size="sm" />
        </Flex>
      ) : (
        <InlineEdit
          className="inter"
          style={{
            backgroundColor: COLORS.white,
            borderRadius: "8px",
            border: `1px solid ${COLORS.gray_200}`,
          }}
          value={description}
          onChange={(value) =>
            setDescription(value.slice(0, MAX_DESCRIPTION_LENGTH))
          }
          onCancel={() => setDescription(hotel.guestApp.description || "")}
          onSave={onSave}
        >
          <Input as="textarea" rows={5} />
        </InlineEdit>
      )}
      <InterTag
        size={12}
        color={COLORS.gray_400}
        text={trans("pages.hotel_profile.sections.description.characters", {
          parameters: [description.length, MAX_DESCRIPTION_LENGTH],
        })}
      />
    </Flex>
  );
};

interface iProps {}

const EditDescription: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();
  return (
    <Flex column gap={16}>
      <PageSectionTitle
        title={trans("pages.hotel_profile.sections.description.title")}
        icon={StoreFrontIcon}
        description={trans(
          "pages.hotel_profile.sections.description.description"
        )}
      />
      <EditDescriptionWrapped />
    </Flex>
  );
};

export default EditDescription;
