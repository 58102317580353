import React, { Fragment, useContext, useState } from "react";
import DataTypeToggle from "../../../components/DataTypeToggle";
import { useDataTypeToggle } from "../../../components/DataTypeToggle/useDataTypeToggle";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import ManualDataModal from "../../../components/Modals/ManualDataModal";
import PageBottomPadding from "../../../components/PageBottomPadding";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import WithContextProvider from "../../../hoc/withProvider";
import { COLORS } from "../../../utils/colors";
import SustainabilityContextProvider, {
  SustainabilityContext,
} from "./context";
import ManualData from "./manualData";
import SensorData from "./sensorData";

interface iSustainabilityWrappedProps {}

const SustainabilityWrapped: React.FC<iSustainabilityWrappedProps> = () => {
  const { activeSubscriptionIsEnterprise } = useHotelSubscriptionState();
  const context = useContext(SustainabilityContext);
  const dataTypeState = useDataTypeToggle();

  const toggle = activeSubscriptionIsEnterprise ? (
    <DataTypeToggle
      {...{ dataTypeState }}
      loading={
        dataTypeState.isLoading ||
        Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
      }
    />
  ) : null;

  if (!dataTypeState.isResolved) return null;

  return (
    <Flex column gap={40}>
      {dataTypeState.dataTypeLive ? (
        <SensorData dataTypeToggle={toggle} />
      ) : (
        <ManualData dataTypeToggle={toggle} />
      )}
    </Flex>
  );
};

const Sustainability: React.FC = () => {
  const { trans } = useLocalizationState();
  const [manualDataModal, setManualDataModal] = useState(false);

  return (
    <>
      <ManualDataModal
        open={manualDataModal}
        onClose={() => {
          setManualDataModal(false);
        }}
      />
      <TopBar>
        <InterTag
          text={trans("general.sustainability")}
          bold
          color={COLORS.secondary}
          size={24}
        />
      </TopBar>
      <IpValidatorGuard>
        <Fragment>
          <SustainabilityWrapped />
          <PageBottomPadding />
        </Fragment>
      </IpValidatorGuard>
    </>
  );
};

export default WithContextProvider(
  SustainabilityContextProvider,
  Sustainability
);
