import { round, sum } from "lodash";
import React, { useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Flex from "../../../../components/Flex";
import SkeletonText from "../../../../components/Skeleton/SkeletonText";
import InterTag from "../../../../components/Text/Inter";
import useHotelState from "../../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { tHotelFleet } from "../../../../models/hotel";
import { COLORS } from "../../../../utils/colors";

interface iProps {}

const Graph: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();
  const { hotel, hotelIsLoaded, hotelLoading } = useHotelState();
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);
  const [graphType] = useState<"donut">("donut");

  const [totalDistance, totalFuelLiters, labels, distanceSeries, fuelsSeries] =
    useMemo(() => {
      const labels: string[] = [];
      const distanceSeries: number[] = [];
      const fuelsSeries: number[] = [];
      let totalDistance = 0;
      let totalFuelLiters = 0;

      hotel.fleet.forEach((f) => {
        labels.push(f.licensePlate);

        let fTotalDistance = 0;
        let fTotalFuelLiters = 0;

        f.entries.forEach((e) => {
          fTotalDistance += e.distanceKm;
          fTotalFuelLiters += e.fuelLiters;
        });

        distanceSeries.push(fTotalDistance);
        fuelsSeries.push(fTotalFuelLiters);

        totalDistance += fTotalDistance;
        totalFuelLiters += fTotalFuelLiters;
      });

      return [
        totalDistance,
        totalFuelLiters,
        labels,
        distanceSeries,
        fuelsSeries,
      ];
    }, [hotel.fleet]);

  const segments: {
    key: keyof tHotelFleet["entries"][0];
    label: string;
    value: number;
    unit: string;
    series: number[];
  }[] = [
    {
      key: "distanceKm",
      label: trans("general.distance"),
      value: totalDistance,
      unit: "km",
      series: distanceSeries,
    },
    {
      key: "fuelLiters",
      label: trans("general.fuel_usage"),
      value: totalFuelLiters,
      unit: "L",
      series: fuelsSeries,
    },
  ];

  const renderSegments = () => {
    if (hotelLoading)
      return (
        <Flex row>
          {segments.map(({ key, label }, i) => {
            let labelColor = COLORS.gray_400;

            if (selectedSegmentIndex === i) {
              labelColor = COLORS.primary;
            }

            return (
              <Flex
                basis={15}
                key={key}
                column
                gap={8}
                style={{
                  cursor: "pointer",
                  padding: "6px",
                  borderRadius: "8px",
                }}
                className="hover-darken-white-bg"
              >
                <InterTag size={12} text={label} color={labelColor} />
                <SkeletonText height={36} width={100} />
              </Flex>
            );
          })}
        </Flex>
      );
    if (hotelIsLoaded)
      return (
        <Flex row>
          {segments.map(({ key, label, unit, value }, i) => {
            let labelColor = COLORS.gray_400;
            let valueColor = COLORS.secondary;

            if (selectedSegmentIndex === i) {
              labelColor = valueColor = COLORS.primary;
            }

            return (
              <Flex
                basis={15}
                key={key}
                column
                gap={8}
                style={{
                  cursor: "pointer",
                  padding: "6px",
                  borderRadius: "8px",
                }}
                className="hover-darken-white-bg"
                onClick={() => {
                  if (selectedSegmentIndex !== i) setSelectedSegmentIndex(i);
                }}
              >
                <InterTag size={12} text={label} color={labelColor} />
                <Flex row bottom>
                  <InterTag size={36} text={value} color={valueColor} />
                  <InterTag size={12} text={unit} color={valueColor} />
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      );
  };

  const graph = () => {
    const { series } = segments[selectedSegmentIndex];

    if (graphType === "donut") {
      if (hotelLoading) return <SkeletonText width={600} height={317.8} />;
      if (hotelIsLoaded)
        return (
          <ReactApexChart
            type="donut"
            {...{
              series,
              options: {
                theme: {
                  monochrome: {
                    enabled: true,
                    color: COLORS.primary_500,
                    shadeIntensity: 1,
                  },
                },
                chart: {
                  type: "donut",
                },
                tooltip: {
                  enabled: false,
                },
                labels,
                responsive: [
                  {
                    breakpoint: 10000,
                    options: {
                      chart: {
                        width: 600,
                        height: 311,
                      },
                    },
                  },
                ],
                dataLabels: {
                  enabled: false,
                },
                legend: {
                  position: "right",
                  fontFamily: "Inter",

                  formatter(legendName, opts) {
                    const name = opts.w.globals.labels[opts.seriesIndex];
                    const val = opts.w.globals.series[opts.seriesIndex];

                    const percentage = (val / sum(opts.w.globals.series)) * 100;

                    return `
        <span>${name}<span/>
        <span style="margin-left: 8px">${round(percentage, 1)} %<span/>
        `;
                  },
                },
                stroke: {
                  width: 0,
                },
              },
            }}
          />
        );
    }
  };

  return (
    <Flex className="card-m" column gap={16}>
      {renderSegments()}
      <Flex center>{graph()}</Flex>
    </Flex>
  );
};

export default Graph;
