import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, InputGroup, Loader, useToaster } from "rsuite";
import { ReactComponent as PasswordIcon } from "../../../assets/icons/password.svg";
import { ReactComponent as VisibilityOnIcon } from "../../../assets/icons/visibility.svg";
import { ReactComponent as VisibilityOffIcon } from "../../../assets/icons/visibility_off.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InputWrapper from "../../../components/InputWrapper";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { notification } from "../../../utils/notifications";

interface iProps {}

const SetupPassword: React.FC<iProps> = () => {
  const toaster = useToaster();
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const params = useParams<{ token: string }>();

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState<string | null>(null);

  const [visibility, setVisibility] = useState(false);
  const [visibility2, setVisibility2] = useState(false);

  const postRequest = usePostRequest();

  const handleSubmit = () => {
    if (password1 !== password2) {
      setError(trans("pages.setup_password.errors.no_match"));
      return;
    }

    postRequest.pending();
    axios
      .post(`${apiAddress(false)}/v2/managers/setup-password/${params.token}`, {
        password1,
        password2,
      })
      .then(() => {
        toaster.push(
          notification(
            "success",
            trans("pages.setup_password.notifications.done")
          ),
          {
            placement: "topEnd",
          }
        );
        navigate("/login");
      })
      .catch((err) => {
        toaster.push(notification("error", getErrorMessage(err, trans)), {
          placement: "topEnd",
        });
      });
  };

  useEffect(() => {
    setError(null);
  }, [password1, password2]);

  return (
    <Fragment>
      {postRequest.isLoading && <Loader center backdrop size="lg" />}
      <Flex column middle gap={16}>
        <Flex
          column
          center
          style={{
            backgroundColor: COLORS.light_white,
            borderRadius: "16px",
            padding: "64px",
            width: "40%",
          }}
          gap={40}
        >
          <InterTag
            size={36}
            text={trans("pages.setup_password.title")}
            bold
            color={COLORS.secondary}
          />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Flex column gap={20}>
              <InputWrapper label={trans("general.password")}>
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon
                      Element={PasswordIcon}
                      width={24}
                      height={24}
                      fill={COLORS.secondary}
                    />
                  </InputGroup.Addon>
                  <Input
                    value={password1}
                    onChange={setPassword1}
                    type={visibility ? "text" : "password"}
                    placeholder={trans("general.placeholders.password")}
                    size="lg"
                  />
                  <InputGroup.Button
                    className={`hover-gray-100 bg-white`}
                    onClick={() => setVisibility((prev) => !prev)}
                  >
                    {visibility ? (
                      <Icon
                        Element={VisibilityOnIcon}
                        width={24}
                        height={24}
                        fill={COLORS.gray}
                      />
                    ) : (
                      <Icon
                        Element={VisibilityOffIcon}
                        width={24}
                        height={24}
                        fill={COLORS.gray}
                      />
                    )}
                  </InputGroup.Button>
                </InputGroup>
              </InputWrapper>
              <InputWrapper
                error={error}
                label={trans(
                  "pages.setup_password.inputs.confirm_password.label"
                )}
              >
                <InputGroup>
                  <InputGroup.Addon>
                    <Icon
                      Element={PasswordIcon}
                      width={24}
                      height={24}
                      fill={COLORS.secondary}
                    />
                  </InputGroup.Addon>
                  <Input
                    value={password2}
                    onChange={setPassword2}
                    type={visibility2 ? "text" : "password"}
                    placeholder={trans("general.placeholders.password")}
                    size="lg"
                  />
                  <InputGroup.Button
                    className={`hover-gray-100 bg-white`}
                    onClick={() => setVisibility2((prev) => !prev)}
                  >
                    {visibility2 ? (
                      <Icon
                        Element={VisibilityOnIcon}
                        width={24}
                        height={24}
                        fill={COLORS.gray}
                      />
                    ) : (
                      <Icon
                        Element={VisibilityOffIcon}
                        width={24}
                        height={24}
                        fill={COLORS.gray}
                      />
                    )}
                  </InputGroup.Button>
                </InputGroup>
              </InputWrapper>
            </Flex>
            <input type="submit" hidden />
          </form>
          <Flex center>
            <Button
              onClick={handleSubmit}
              appearance="primary"
              style={{ fontWeight: "bold", fontSize: "20px", width: "100%" }}
            >
              {trans("Submit")}
            </Button>
          </Flex>
        </Flex>
        <Flex row gap={4} center>
          <InterTag
            color={COLORS.white}
            text={trans("pages.setup_password.signin")}
          />
          <InterTag
            color={COLORS.primary}
            text={trans("general.signin")}
            hoverUnderline
            onClick={() => {
              navigate("/login");
            }}
          />
        </Flex>
        <Flex row gap={4} center>
          <InterTag
            color={COLORS.white}
            text={trans("pages.setup_password.signup[0]")}
          />
          <InterTag
            color={COLORS.primary}
            text={trans("pages.setup_password.signup[1]")}
            hoverUnderline
            onClick={() => {
              navigate("/register");
            }}
          />
          <InterTag
            color={COLORS.white}
            text={trans("pages.setup_password.signup[2]")}
          />
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default SetupPassword;
