import axios from "axios";
import { findIndex, sortBy, uniq } from "lodash";
import React, { Fragment, useEffect, useReducer } from "react";
import {
  Button,
  Checkbox,
  Input,
  InputGroup,
  InputNumber,
  Modal,
  ModalProps,
  TagPicker,
  useToaster,
} from "rsuite";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import { ReactComponent as ArrowForwardIcon } from "../../../assets/icons/arrow_forward.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../assets/icons/meeting_room.svg";
import Flex from "../../../components/Flex";
import InputWrapper from "../../../components/InputWrapper";
import PageSectionTitle from "../../../components/PageSectionTitle";
import SimpleButton from "../../../components/RsuiteWrapper/SimpleButton";
import InterTag from "../../../components/Text/Inter";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePutRequest, {
  tUsePutRequest,
} from "../../../hooks/apiRequests/usePutRequest";
import { tHotelSpaceAggregateId, tHotelSpaceId } from "../../../models/hotel";
import {
  AC,
  CW,
  EL,
  ES,
  GW,
  HW,
  MEASURES,
  TE,
  tMeasure,
  TW,
  WH,
} from "../../../models/measures";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { getMeasureInfo } from "../../../utils/measures";
import { notification } from "../../../utils/notifications";

type tData = {
  name: string;
  groups: tHotelSpaceAggregateId[];
  zones: tHotelSpaceAggregateId[];
  types: tHotelSpaceAggregateId[];
  areaInSquareMeters: string;
  isAccommodation: boolean;
  measures: tMeasure[];
};

type tErrors = Record<keyof tData, string>;

type tState = {
  data: tData;
  errors: tErrors;
};

type tAction =
  | { type: "reset" }
  | { type: "set errors"; errors: Partial<tErrors> }
  | { type: "set data"; data: Partial<tData> }
  | { type: "update"; key: keyof tData; value: any };

const initialState: tState = {
  data: {
    name: "",
    groups: [],
    zones: [],
    types: [],
    areaInSquareMeters: "",
    measures: [],
    isAccommodation: false,
  },
  errors: {
    name: "",
    groups: "",
    zones: "",
    types: "",
    areaInSquareMeters: "",
    measures: "",
    isAccommodation: "",
  },
};

const reducer = (state: tState, action: tAction): tState => {
  switch (action.type) {
    case "reset": {
      return { ...initialState };
    }
    case "set errors": {
      const { errors } = action;
      return { ...state, errors: { ...state.errors, ...errors } };
    }
    case "set data": {
      const { data } = action;
      return { ...state, data: { ...state.data, ...data } };
    }
    case "update": {
      const { key, value } = action;
      return {
        ...state,
        errors: { ...state.errors, [key]: "" },
        data: { ...state.data, [key]: value },
      };
    }
    default: {
      return { ...state };
    }
  }
};

interface iSpaceModalWrappedProps {
  confirmRequest: tUsePutRequest;
  onClose(): void;
  spaceId?: tHotelSpaceId;
  spaceName?: string;
}

const SpaceModalWrapped: React.FC<iSpaceModalWrappedProps> = ({
  confirmRequest,
  onClose,
  spaceId,
  spaceName,
}) => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const {
    hotelId,
    hotel: { spaces },
    hotelIsLoaded,
    findSpace,
    findSpaceSpaceAggregates,
    spaceAggregatesByCategory,
  } = useHotelState();
  const { updateHotel, updatingHotel } = useHotelDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);

  const onChange =
    (key: keyof Pick<tData, "name" | "areaInSquareMeters">) => (value: any) => {
      dispatch({ type: "update", key, value });
    };

  const {
    data: {
      name,
      areaInSquareMeters,
      groups,
      zones,
      measures,
      types,
      isAccommodation,
    },
    errors,
  } = state;

  useEffect(() => {
    if (confirmRequest.isIdle) {
      if (spaceId) {
        const space = findSpace(spaceId);
        if (!space) {
          toaster.push(
            notification(
              "error",
              `${trans("Space not found")}: (${spaceName || spaceId})`
            )
          );
          onClose();
          return;
        }
        dispatch({
          type: "set data",
          data: {
            name: space.name,
            areaInSquareMeters: `${space.areaInSquareMeters}`,
            groups: findSpaceSpaceAggregates(spaceId, "group").map(
              (g) => g._id
            ),
            zones: findSpaceSpaceAggregates(spaceId, "zone").map((g) => g._id),
            types: findSpaceSpaceAggregates(spaceId, "type").map((g) => g._id),
            measures: MEASURES.filter(
              (measure) => space.measures[measure].isMeasured
            ),
            isAccommodation: space.isAccommodation,
          },
        });
      }
    }
  }, [
    confirmRequest.isIdle,
    findSpace,
    findSpaceSpaceAggregates,
    onClose,
    spaceId,
    spaceName,
    toaster,
    trans,
  ]);

  useEffect(() => {
    if (confirmRequest.isIdle) {
      if (name !== spaceName) {
        if (spaces.map((s) => s.name).includes(name))
          dispatch({
            type: "set errors",
            errors: { name: `A space already exists with this name` },
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    confirmRequest.isIdle,
    spaceName,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(sortBy(spaces.map((s) => s.name))),
    hotelIsLoaded,
  ]);

  const setMeasures = (newMeasures: tMeasure[]) => {
    dispatch({ type: "update", key: "measures", value: newMeasures });
  };

  const onClickBeingMeasuredCheckbox = (key: tMeasure, parentKey: tMeasure) => {
    const index = findIndex(measures, (v) => v === key);
    if (index === -1) return setMeasures(uniq([...measures, key, parentKey]));
    return setMeasures(measures.filter((v, i) => i !== index));
  };

  const requestPending = confirmRequest.isLoading;

  const renderCheckPickerMeasures = () => {
    const list: {
      key: tMeasure;
      label: string;
      children: { key: tMeasure; label: string }[];
    }[] = [
      {
        key: TE,
        label: getMeasureInfo(TE, trans).label,
        children: [
          { key: ES, label: getMeasureInfo(ES, trans).label },
          { key: EL, label: getMeasureInfo(EL, trans).label },
          { key: AC, label: getMeasureInfo(AC, trans).label },
          { key: WH, label: getMeasureInfo(WH, trans).label },
        ],
      },
      {
        key: TW,
        label: getMeasureInfo(TW, trans).label,
        children: [
          { key: HW, label: getMeasureInfo(HW, trans).label },
          { key: GW, label: getMeasureInfo(GW, trans).label },
          { key: CW, label: getMeasureInfo(CW, trans).label },
        ],
      },
    ];

    return list.flatMap(({ key: parentKey, label, children }) => {
      const checked = measures.includes(parentKey);

      return [
        <Checkbox
          disabled={requestPending}
          checked={checked}
          key={parentKey}
          onChange={() => {
            const measureKeysToRemove = [
              parentKey,
              ...children.map((c) => c.key),
            ];
            if (checked)
              setMeasures(
                measures.filter(
                  (measure) => !measureKeysToRemove.includes(measure)
                )
              );
            else setMeasures(uniq([...measures, parentKey]));
          }}
        >
          {label}
        </Checkbox>,
        <Flex
          column
          key={`${parentKey}:children`}
          style={{ marginLeft: "32px" }}
        >
          {children.map(({ key: childKey, label }) => {
            return (
              <Checkbox
                disabled={requestPending}
                key={childKey}
                onChange={() =>
                  onClickBeingMeasuredCheckbox(childKey as tMeasure, parentKey)
                }
                checked={measures.includes(childKey)}
              >
                {label}
              </Checkbox>
            );
          })}
        </Flex>,
      ];
    });
  };

  const handleConfirm = () => {
    updatingHotel();
    confirmRequest.pending();
    (spaceId
      ? axios.post(
          `${apiAddress(false)}/v2/hotels/${hotelId}/spaces/${spaceId}`,
          {
            name,
            areaInSquareMeters,
            isAccommodation,
            spaceAggregates: [...groups, ...types, ...zones],
            measures,
          }
        )
      : axios.put(`${apiAddress(false)}/v2/hotels/${hotelId}/spaces`, {
          name,
          areaInSquareMeters,
          isAccommodation,
          spaceAggregates: [...groups, ...types, ...zones],
          measures,
        })
    )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        toaster.push(
          notification(
            "success",
            trans(
              `pages.spaces.modals.space.success.message.${
                spaceId ? "update" : "new"
              }`
            )
          ),
          {
            placement: "topEnd",
          }
        );
        confirmRequest.resolve();
        updateHotel(hotelId, hotel);
        onClose();
      })
      .catch((err) => {
        console.log(err);
        try {
          const {
            data: { hotel },
          } = err.response;
          const error = getErrorMessage(err, trans);
          updateHotel(hotelId, hotel);
          confirmRequest.reject(error);
          toaster.push(notification("error", error), { placement: "topEnd" });
        } catch (err) {}
        confirmRequest.reject("");
      });
  };

  const confirmButtonDisabled =
    Object.values(errors).filter((v) => v.length).length > 0 ||
    name.length === 0;

  return (
    <Fragment>
      <Modal.Header closeButton={!confirmRequest.isLoading}></Modal.Header>
      <Modal.Body>
        <Flex column gap={32}>
          <PageSectionTitle
            title={{
              text: trans(
                `pages.spaces.modals.space.title.${spaceId ? "update" : "new"}`
              ),
            }}
            icon={{ Element: MeetingRoomIcon }}
          />
          <Flex row gap={60}>
            <Flex column gap={16} basis={50}>
              <InputWrapper
                label={{ text: trans("general.name") }}
                error={{ text: errors.name }}
              >
                <Input
                  type="text"
                  size="md"
                  disabled={requestPending}
                  value={name}
                  onChange={onChange("name")}
                  placeholder={trans(
                    "pages.spaces.modals.space.name.placeholder"
                  )}
                />
              </InputWrapper>
              <InputWrapper
                label={{ text: trans("general.area") }}
                error={{ text: errors.areaInSquareMeters }}
              >
                <InputGroup>
                  <InputNumber
                    disabled={requestPending}
                    min={0}
                    value={areaInSquareMeters}
                    placeholder={"123"}
                    onChange={onChange("areaInSquareMeters")}
                  />
                  <InputGroup.Addon>
                    m<sup>2</sup>
                  </InputGroup.Addon>
                </InputGroup>
              </InputWrapper>
              <InputWrapper
                label={{ text: trans("general.space_categories.groups") }}
                error={{ text: errors.groups }}
              >
                <TagPicker
                  disabled={requestPending}
                  onChange={(value) => {
                    dispatch({ type: "update", key: "groups", value });
                  }}
                  value={groups}
                  data={spaceAggregatesByCategory["group"].map(
                    ({ name, _id }) => ({
                      value: _id,
                      label: name,
                    })
                  )}
                />
              </InputWrapper>
              <InputWrapper
                label={{ text: trans("general.space_categories.types") }}
                error={{ text: errors.types }}
              >
                <TagPicker
                  disabled={requestPending}
                  onChange={(value) => {
                    dispatch({ type: "update", key: "types", value });
                  }}
                  value={types}
                  data={spaceAggregatesByCategory["type"].map(
                    ({ name, _id }) => ({
                      value: _id,
                      label: name,
                    })
                  )}
                />
              </InputWrapper>
              <InputWrapper
                label={{ text: trans("general.space_categories.zones") }}
                error={{ text: errors.zones }}
              >
                <TagPicker
                  disabled={requestPending}
                  onChange={(value) => {
                    dispatch({ type: "update", key: "zones", value });
                  }}
                  value={zones}
                  data={spaceAggregatesByCategory["zone"].map(
                    ({ name, _id }) => ({
                      value: _id,
                      label: name,
                    })
                  )}
                />
              </InputWrapper>
            </Flex>
            <Flex column gap={12}>
              <Flex column>{renderCheckPickerMeasures()}</Flex>
              <Checkbox
                disabled={requestPending}
                checked={isAccommodation}
                onChange={(value, checked) => {
                  dispatch({
                    type: "update",
                    key: "isAccommodation",
                    value: checked,
                  });
                }}
              >
                {trans("pages.spaces.modals.space.is_accommodation")}
              </Checkbox>
            </Flex>
          </Flex>
          <Flex row center>
            <Button onClick={onClose} appearance="link">
              <InterTag
                text={trans("general.discard_and_close")}
                color={COLORS.primary}
                size={14}
              />
            </Button>
            <SimpleButton
              appearance="primary"
              loading={requestPending}
              onClick={handleConfirm}
              disabled={confirmButtonDisabled}
              icon={{
                Element: spaceId ? ArrowForwardIcon : AddIcon,
                fill: COLORS.white,
                size: 20,
              }}
              text={{
                text: trans(
                  `pages.spaces.modals.space.confirm_button.label.${
                    spaceId ? "update" : "new"
                  }`
                ),
                color: COLORS.white,
                size: 14,
              }}
            />
          </Flex>
        </Flex>
      </Modal.Body>
    </Fragment>
  );
};

interface iSpaceModalProps extends ModalProps {
  onClose(): void;
  spaceId?: string;
  spaceName?: string;
}

const SpaceModal: React.FC<iSpaceModalProps> = ({
  spaceId,
  spaceName,
  onClose,
  ...props
}) => {
  const confirmRequest = usePutRequest();

  return (
    <Modal
      overflow={false}
      keyboard={false}
      backdrop={confirmRequest.isLoading ? "static" : true}
      id="space-modal"
      size="md"
      {...{
        ...props,
        onClose,
      }}
    >
      <SpaceModalWrapped
        {...{
          confirmRequest,
          onClose,
          spaceId,
          spaceName,
        }}
      />
    </Modal>
  );
};

export default SpaceModal;
