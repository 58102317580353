import axios from "axios";
import { cloneDeep, min } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalProps, Table, toaster } from "rsuite";
import { tBillingTimePeriod } from ".";
import { ReactComponent as PaymentsIcon } from "../../../assets/icons/payments.svg";
import { ReactComponent as NoytrallSymbolIcon } from "../../../assets/noytrall_symbol.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import PageSectionTitle from "../../../components/PageSectionTitle";
import BaseCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleCheckCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleCheckCell";
import SimpleTextCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import InterTag from "../../../components/Text/Inter";
import useAuthDispatch from "../../../context/Auth/hooks/useAuthDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import useListPaymentMethods, {
  tPaymentMethod,
} from "../../../hooks/useListPaymentMethods";
import tHotel from "../../../models/hotel";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { defaultHotel } from "../../../utils/hotels";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { notification } from "../../../utils/notifications";
import { DEFAULT_MODAL_PROPS } from "../../../utils/rsuite/modals";
import { cleanStorage } from "../../../utils/storage";
import { HOTEL_STRIPE_PROPERTY, STRIPE_ENV } from "../../../utils/stripe";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../../utils/tables";
import AddPaymentMethodModal from "./addPaymentMethodModal";

interface iBusinessPaymentModalWrappedProps {
  billingTimePeriod: tBillingTimePeriod;
}

const BusinessPaymentModalWrapped: React.FC<
  iBusinessPaymentModalWrappedProps
> = ({ billingTimePeriod }) => {
  const navigate = useNavigate();
  const sessionState = useGetRequest<{ hotel: tHotel; token: string }>({
    hotel: cloneDeep(defaultHotel),
    token: "",
  });
  const { trans } = useLocalizationState();
  const { login } = useAuthDispatch();
  const { request, run } = useListPaymentMethods(sessionState.data.hotel._id, {
    doRequest: sessionState.isResolved,
  });
  const paymentRequest = usePostRequest();
  const [selectedMethod, setSelectedMethod] = useState("");
  const [addPaymentMethodModal, setAddPaymentMethodModal] = useState({
    open: false,
  });

  useEffect(() => {
    try {
      if (!sessionState.isFinal) {
        const token = sessionStorage.getItem("plan-token");
        const hotel = sessionStorage.getItem("plan-hotel");
        if (token && hotel) {
          sessionState.resolve({ hotel: JSON.parse(hotel), token });
        } else {
          cleanStorage();
          navigate("/login");
        }
      }
    } catch (err) {
      cleanStorage();
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  useEffect(() => {
    if (sessionState.isResolved) {
      setSelectedMethod(
        sessionState.data.hotel[HOTEL_STRIPE_PROPERTY].defaultCard
      );
    }
  }, [sessionState.data.hotel, sessionState.isResolved]);

  const handlePayment = () => {
    if (selectedMethod.length === 0) {
      toaster.push(
        notification(
          "error",
          trans(
            "pages.register_plan.modals.business_payment.errors.no_payment_method"
          )
        ),
        {
          placement: "topEnd",
        }
      );
      return;
    }
    axios.defaults.headers.common["token"] = sessionState.data.token;
    paymentRequest.pending();

    axios
      .post(
        `${apiAddress(false)}/v2/hotels/${
          sessionState.data.hotel._id
        }/confirm-first-plan/business`,
        {
          billingTimePeriod,
          paymentMethodId: selectedMethod,
        },
        { params: { stripeEnv: STRIPE_ENV } }
      )
      .then((res) => {
        paymentRequest.resolve();
        sessionStorage.clear();
        login(sessionState.data.token);
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        toaster.push(notification("error", error), { placement: "topEnd" });
        paymentRequest.reject(error);
      });
  };

  const TYPE = "business";
  const PRICE = billingTimePeriod === "monthly" ? 50 : 50 * 0.9 * 12;
  const TAX_PERCENTAGE = 0.23;
  const TAX = PRICE * TAX_PERCENTAGE;
  const TOTAL_PRICE = PRICE + TAX;

  const renderInfoCard = () => {
    return (
      <Flex one className="card-m" column gap={40}>
        <Flex row gap={8} middle>
          <Icon Element={NoytrallSymbolIcon} size={24} />
          <InterTag
            text={trans("Noytrall Technologies, Lda.")}
            size={16}
            color={COLORS.secondary}
            bold
          />
        </Flex>
        <Flex column gap={12}>
          <InterTag
            text={`${trans("general.subscribe_x", {
              parameters: [trans("general.noytrall.business")],
            })}`}
            bold
            color={COLORS.secondary}
            size={20}
          />
          <Flex row gap={8} bottom>
            <InterTag text={"€"} size={48} color={COLORS.secondary} bold />
            <InterTag
              text={`${TOTAL_PRICE.toFixed(2)}`.replace(".", ",")}
              size={48}
              color={COLORS.secondary}
              bold
            />
            <InterTag
              text={trans(
                billingTimePeriod === "monthly" ? "monthly" : "annually"
              )}
              size={16}
              color={COLORS.secondary}
            />
          </Flex>
        </Flex>
        <Flex column gap={12}>
          <Flex row between>
            <InterTag
              text={trans(
                TYPE === "business"
                  ? "general.noytrall.business"
                  : "general.noytrall.enterprise"
              )}
              size={16}
              bold
              color={COLORS.secondary}
            />
            <InterTag
              size={16}
              bold
              color={COLORS.secondary}
              text={`€ ${PRICE.toFixed(2)}`}
            />
          </Flex>
          <InterTag
            size={16}
            color={COLORS.secondary}
            text={trans(
              "pages.register_plan.modals.business_payment.sustainability_platform"
            )}
          />
          <InterTag
            size={16}
            color={COLORS.secondary}
            text={trans(
              billingTimePeriod === "monthly"
                ? "pages.register_plan.modals.business_payment.billed_monthly"
                : "pages.register_plan.modals.business_payment.billed_annually"
            )}
          />
          <div
            style={{
              height: "19px",
              borderBottom: `1px solid ${COLORS.secondary}`,
            }}
          />
          <Flex row between>
            <InterTag
              text={trans("general.subtotal")}
              size={16}
              bold
              color={COLORS.secondary}
            />
            <InterTag
              size={16}
              bold
              color={COLORS.secondary}
              text={`€ ${PRICE.toFixed(2)}`}
            />
          </Flex>
          <Flex row between>
            <InterTag
              text={trans("general.tax_x%", { parameters: [23] })}
              size={16}
              color={COLORS.secondary}
            />
            <InterTag
              size={16}
              color={COLORS.secondary}
              text={`€ ${TAX.toFixed(2)}`}
            />
          </Flex>
          <div
            style={{
              height: "19px",
              borderBottom: `1px solid ${COLORS.secondary}`,
            }}
          />
          <Flex row between>
            <InterTag
              text={trans(
                "pages.register_plan.modals.business_payment.amount_due"
              )}
              size={16}
              bold
              color={COLORS.secondary}
            />
            <InterTag
              size={16}
              bold
              color={COLORS.secondary}
              text={`€ ${TOTAL_PRICE.toFixed(2)}`}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderPaymentCard = () => {
    const isLoadingLoading = request.isLoading || sessionState.isResolved;

    const tableHeight = isLoadingLoading
      ? TABLE_HEADER_HEIGHT + TABLE_ROW_HEIGHT.SM
      : (min([3, request.data.paymentMethods.length]) as number) *
          TABLE_ROW_HEIGHT.SM +
        TABLE_HEADER_HEIGHT;

    return (
      <Flex one className="card-m" column between gap={12}>
        <InterTag
          size={20}
          text={trans(
            "pages.register_plan.modals.business_payment.select_payment_method"
          )}
          color={COLORS.secondary}
          bold
        />
        <Flex column gap={8} style={{ flexGrow: 1 }}>
          <div
            style={{ backgroundColor: COLORS.gray_100 }}
            className="table-wrapper"
          >
            <Table
              rowHeight={TABLE_ROW_HEIGHT.SM}
              headerHeight={TABLE_HEADER_HEIGHT}
              data={request.data.paymentMethods}
              loading={request.isLoading}
              height={tableHeight}
            >
              <Table.Column>
                <SimpleHeaderCell />
                <SimpleCheckCell
                  isChecked={(item: tPaymentMethod) =>
                    item.id === selectedMethod
                  }
                  onChange={(item: tPaymentMethod) => {
                    if (item.id === selectedMethod) return null;
                    setSelectedMethod(item.id);
                  }}
                />
              </Table.Column>
              <Table.Column flexGrow={1}>
                <SimpleHeaderCell name={trans("general.card")} />
                <BaseCell>
                  {(item: tPaymentMethod) => {
                    return (
                      <Flex row gap={4}>
                        <InterTag
                          text={trans("Visa **** $0", {
                            parameters: [item.card?.last4 || ""],
                          })}
                          size={12}
                          color={COLORS.secondary}
                        />
                      </Flex>
                    );
                  }}
                </BaseCell>
              </Table.Column>
              <Table.Column flexGrow={1}>
                <SimpleHeaderCell name={trans("general.expires")} />
                <SimpleTextCell
                  dataKey="expires"
                  textFunction={(item: tPaymentMethod) => {
                    if (item.card) {
                      const date = moment().set({
                        month: item.card.exp_month,
                        year: item.card.exp_year,
                      });
                      return trans("$0/$1", {
                        parameters: [
                          moment(date).format("MM"),
                          moment(date).format("YYYY"),
                        ],
                      });
                    }
                  }}
                />
              </Table.Column>
            </Table>
          </div>
          <Button
            onClick={() => {
              setAddPaymentMethodModal({ open: true });
            }}
          >
            {trans(
              "pages.register_plan.modals.business_payment.manage_payment_methods"
            )}
          </Button>
        </Flex>

        <Button
          onClick={handlePayment}
          loading={paymentRequest.isLoading}
          appearance="primary"
          disabled={selectedMethod.length === 0}
        >
          {trans("pages.register_plan.modals.business_payment.confirm")}
        </Button>
      </Flex>
    );
  };
  return (
    <>
      <AddPaymentMethodModal
        open={addPaymentMethodModal.open}
        onClose={(action) => {
          if (action === "create") run();
          setAddPaymentMethodModal({ open: false });
        }}
      />
      <Modal.Header closeButton={!paymentRequest.isLoading} />
      <Modal.Body>
        <Flex column gap={16}>
          <PageSectionTitle
            title={trans("general.payment")}
            description={trans(
              "pages.register_plan.modals.business_payment.description"
            )}
            icon={PaymentsIcon}
          />
          <Flex row gap={40}>
            {renderInfoCard()}
            {renderPaymentCard()}
          </Flex>
        </Flex>
      </Modal.Body>
    </>
  );
};

interface iProps extends Omit<ModalProps, "onClose"> {
  onClose(): void;
  billingTimePeriod: tBillingTimePeriod;
}

const BusinessPaymentModal: React.FC<iProps> = ({
  onClose,
  open,
  billingTimePeriod,
  ...props
}) => {
  return (
    <Modal
      {...{
        ...DEFAULT_MODAL_PROPS,
        open,
        onClose,
        size: "lg",
        overflow: false,
      }}
    >
      {open && <BusinessPaymentModalWrapped {...{ billingTimePeriod }} />}
    </Modal>
  );
};

export default BusinessPaymentModal;
