import axios from "axios";
import { find } from "lodash";
import React, { Fragment, useEffect, useMemo, useReducer } from "react";
import {
  Button,
  Input,
  Modal,
  ModalProps,
  TagPicker,
  useToaster,
} from "rsuite";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import { ReactComponent as ArrowForwardIcon } from "../../../assets/icons/arrow_forward.svg";
import { ReactComponent as WorkspacesIcon } from "../../../assets/icons/workspaces.svg";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Flex from "../../../components/Flex";
import InputWrapper from "../../../components/InputWrapper";
import PageSectionTitle from "../../../components/PageSectionTitle";
import InterTag from "../../../components/Text/Inter";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import {
  tHotelSpace,
  tHotelSpaceAggregate,
  tHotelSpaceAggregateId,
  tHotelSpaceId,
} from "../../../models/hotel";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";

import usePutRequest, {
  tUsePutRequest,
} from "../../../hooks/apiRequests/usePutRequest";
import { notification } from "../../../utils/notifications";

type tData = {
  name: string;
  spaces: tHotelSpaceId[];
};

type tErrors = Record<keyof tData, string>;

type tState = {
  data: tData;
  errors: tErrors;
};

type tAction =
  | { type: "reset" }
  | { type: "errors"; errors: Partial<tErrors> }
  | { type: "data"; data: Partial<tData> }
  | { type: "key value"; key: keyof tData; value: any };

const initialState: tState = {
  data: { name: "", spaces: [] },
  errors: { name: "", spaces: "" },
};

const reducer = (state: tState, action: tAction): tState => {
  switch (action.type) {
    case "reset": {
      return { ...initialState };
    }
    case "errors": {
      const { errors } = action;
      return { ...state, errors: { ...state.errors, ...errors } };
    }
    case "data": {
      const { data } = action;
      return {
        ...state,
        errors: {
          ...state.errors,
          ...Object.fromEntries(Object.keys(data).map((key) => [key, ""])),
        },
        data: { ...state.data, ...data },
      };
    }
    case "key value": {
      const { key, value } = action;
      return {
        ...state,
        errors: { ...state.errors, [key]: "" },
        data: { ...state.data, [key]: value },
      };
    }
    default: {
      return { ...state };
    }
  }
};

interface iSpaceAggregateModalWrappedProps
  extends Pick<
    iSpaceAggregateModalProps,
    "category" | "aggId" | "aggName" | "onClose"
  > {
  confirmRequest: tUsePutRequest;
}

const SpaceAggregateModalWrapped: React.FC<
  iSpaceAggregateModalWrappedProps
> = ({ confirmRequest, onClose, aggId, aggName, category }) => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { hotelId, hotel, hotelIsLoaded, findSpaceAggregate } = useHotelState();
  const { updateHotel, updatingHotel } = useHotelDispatch();

  const [
    notFoundText,
    aggUpdatedText,
    updateAggText,
    addNewAggText,
    newAggCreatedText,
    createAggText,
    nameInUseText,
  ] = useMemo(() => {
    let keyword =
      category === "group" ? "groups" : category === "zone" ? "zones" : "types";

    return [
      `pages.spaces.modals.space_aggregates.${keyword}.not_found`,
      `pages.spaces.modals.space_aggregates.${keyword}.updated`,
      `pages.spaces.modals.space_aggregates.${keyword}.update`,
      `pages.spaces.modals.space_aggregates.${keyword}.add_new`,
      `pages.spaces.modals.space_aggregates.${keyword}.created`,
      `pages.spaces.modals.space_aggregates.${keyword}.create`,
      `pages.spaces.modals.space_aggregates.${keyword}.name_in_use`,
    ].map((t) => trans(t));
  }, [category, trans]);

  const onChange = (key: keyof tData) => (value: any) => {
    dispatch({ type: "key value", key, value });
  };

  const {
    data: { name, spaces },
    errors,
  } = state;

  useEffect(() => {
    if (aggId) {
      const agg = findSpaceAggregate(aggId);
      if (!agg) {
        toaster.push(
          notification("error", `${notFoundText}: (${aggName || aggId})`)
        );
        onClose();
        return;
      }
      dispatch({
        type: "data",
        data: {
          name: agg.name,
          spaces: agg.spaces,
        },
      });
    }
  }, [
    aggId,
    aggName,
    onClose,
    toaster,
    trans,
    notFoundText,
    findSpaceAggregate,
  ]);

  useEffect(() => {
    if (hotelIsLoaded) {
      if (name !== aggName) {
        if (
          hotel.spaceAggregates
            .filter((s) => s.category === category)
            .map((s) => s.name)
            .includes(name)
        )
          dispatch({
            type: "errors",
            errors: { name: trans(nameInUseText) },
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, aggName, hotelIsLoaded, category, trans, nameInUseText]);
  const handleConfirm = () => {
    confirmRequest.pending();
    updatingHotel();
    (aggId
      ? axios.post(
          `${apiAddress(true)}/v2/hotels/${hotelId}/space-aggregates/${aggId}`,
          { name, spaces }
        )
      : axios.put(`${apiAddress(true)}/v2/hotels/${hotelId}/space-aggregates`, {
          name,
          spaces,
          category,
        })
    )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        toaster.push(
          notification(
            "success",
            `${aggId ? aggUpdatedText : newAggCreatedText}`
          ),
          {
            placement: "topEnd",
          }
        );
        confirmRequest.resolve();
        updateHotel(hotelId, hotel);
        onClose();
      })
      .catch((err) => {
        let error: string | null = null;
        try {
          const {
            data: { failedSpaces },
          } = err.response;
          if (err.response.status === 409) {
            error = `${nameInUseText}`;
            dispatch({
              type: "errors",
              errors: {
                name: error,
              },
            });
          }
          if (failedSpaces && failedSpaces.length) {
            error = `${trans(
              "pages.spaces.modals.space_aggregates.errors.spaces_dont_exist",
              {
                parameters: [
                  failedSpaces
                    .map((_id: tHotelSpaceId) =>
                      find(hotel.spaces, (s) => s._id === _id)
                    )
                    .filter((v: any) => v)
                    .map((v: tHotelSpace) => v.name)
                    .join(", "),
                ],
              }
            )}`;
            dispatch({
              type: "errors",
              errors: {
                spaces: error,
              },
            });
          }
          if (!error) {
            error = getErrorMessage(err, trans);
            toaster.push(notification("error", error as string), {
              placement: "topEnd",
            });
          }
        } catch (err) {
          console.log(err);
        }
        updateHotel(hotelId, {});
        confirmRequest.reject(error || "", true);
      });
  };

  const confirmButtonDisabled =
    Object.values(errors).filter((v) => v.length).length > 0 ||
    name.length === 0;

  return (
    <Fragment>
      <Modal.Header closeButton={!confirmRequest.isLoading}></Modal.Header>
      <Modal.Body>
        <Flex column gap={32}>
          <PageSectionTitle
            title={{
              text: aggId ? updateAggText : addNewAggText,
            }}
            icon={{ Element: WorkspacesIcon }}
          />
          <Flex column gap={16} style={{ width: "50%" }}>
            <InputWrapper
              label={{ text: trans("general.name") }}
              error={{ text: errors.name }}
            >
              <Input
                disabled={confirmRequest.isLoading}
                type="text"
                size="md"
                value={name}
                onChange={onChange("name")}
                placeholder={trans(
                  "pages.spaces.modals.space_aggregates.name.placeholder"
                )}
              />
            </InputWrapper>
            <InputWrapper
              label={{ text: trans("general.spaces") }}
              error={{ text: errors.spaces }}
            >
              <TagPicker
                disabled={confirmRequest.isLoading}
                onChange={(value) => {
                  dispatch({ type: "key value", key: "spaces", value });
                }}
                value={spaces}
                data={hotel.spaces.map(({ name, _id }) => ({
                  value: _id,
                  label: name,
                }))}
              />
            </InputWrapper>
          </Flex>
          <Flex row center>
            <Button
              onClick={onClose}
              appearance="link"
              disabled={confirmRequest.isLoading}
            >
              <InterTag
                text={trans("general.discard_and_close")}
                color={COLORS.primary}
                size={14}
              />
            </Button>
            <PrimaryButton
              loading={confirmRequest.isLoading}
              onClick={handleConfirm}
              disabled={confirmButtonDisabled || confirmRequest.isLoading}
              label={{
                text: aggId ? updateAggText : createAggText,
                color: COLORS.white,
              }}
              icon={{
                Element: aggId ? ArrowForwardIcon : AddIcon,
                fill: COLORS.white,
                size: 20,
              }}
            />
          </Flex>
        </Flex>
      </Modal.Body>
    </Fragment>
  );
};

interface iSpaceAggregateModalProps extends ModalProps {
  onClose(): void;
  aggId?: tHotelSpaceAggregateId;
  aggName?: string;
  category: tHotelSpaceAggregate["category"];
}

const SpaceAggregateModal: React.FC<iSpaceAggregateModalProps> = ({
  aggId,
  aggName,
  onClose,
  category,
  ...props
}) => {
  const confirmRequest = usePutRequest();

  return (
    <Modal
      overflow={false}
      keyboard={false}
      backdrop={confirmRequest.isLoading ? "static" : true}
      id="space-agg-modal"
      size="md"
      {...{
        ...props,
        onClose,
      }}
    >
      <SpaceAggregateModalWrapped
        {...{
          confirmRequest,
          onClose,
          aggId,
          aggName,
          category,
        }}
      />
    </Modal>
  );
};

export default SpaceAggregateModal;
