import axios from "axios";
import { MomentInput } from "moment";
import { useEffect } from "react";
import useHotelState from "../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../context/Localization/hooks/useLocalizationState";
import { apiAddress, REQUEST_STATUS } from "../utils/apiCall";
import { getErrorMessage } from "../utils/httpResponses/others";
import useGetRequest from "./apiRequests/useGetRequest";
import useEffectSafe from "./useEffectSafe";

const useGetNumberOfStaysAndGuests = (
  { from, to }: { from?: MomentInput | null; to?: MomentInput | null } = {
    from: null,
    to: null,
  },
  { doRequest = true }: { doRequest?: boolean } = { doRequest: true }
) => {
  const { trans } = useLocalizationState();
  const { hotelId } = useHotelState();

  const request = useGetRequest<{
    numberOfStays: number;
    numberOfGuests: number;
  }>(
    {
      numberOfStays: 0,
      numberOfGuests: 0,
    },
    { ...(doRequest ? {} : { status: REQUEST_STATUS.IDLE }) }
  );

  useEffectSafe(() => {
    if (hotelId && doRequest) {
      request.pending();

      axios
        .get(
          `${apiAddress(
            false
          )}/v2/hotels/${hotelId}/stays/number-of-stays-and-guests`,
          {
            params: { from, to },
          }
        )
        .then((res) => {
          const {
            data: { numberOfStays, numberOfGuests },
          } = res;
          request.resolve({
            numberOfGuests: numberOfGuests,
            numberOfStays: numberOfStays,
          });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          request.reject(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, hotelId, to, trans, doRequest]);

  useEffect(() => {});

  const { data, error, status, isFinal, isLoading, isRejected, isResolved } =
    request;

  return { data, error, status, isFinal, isLoading, isRejected, isResolved };
};

export default useGetNumberOfStaysAndGuests;
