import { findIndex } from "lodash";
import React, { Fragment, useState } from "react";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/category.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../assets/icons/meeting_room.svg";
import { ReactComponent as RoomPreferencesIcon } from "../../../assets/icons/room_preferences.svg";
import { ReactComponent as StacksIcon } from "../../../assets/icons/stacks.svg";
import { ReactComponent as WorkspaceIcon } from "../../../assets/icons/workspaces.svg";
import bySpacePlaceholder from "../../../assets/placeholders/consumption-by-space.png";
import spacesManagementPlaceholder from "../../../assets/placeholders/spaces-management.png";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import PageBottomPadding from "../../../components/PageBottomPadding";
import NoPermissions from "../../../components/Pages/NoPermissions";
import PageSectionTitle, {
  iPageSectionTitleProps,
} from "../../../components/PageSectionTitle";
import Segmentation from "../../../components/Segmentation";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import Unlock from "../../../components/Unlock";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useHotelState from "../../../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import useScreenSize from "../../../hooks/useScreenSize";
import { COLORS } from "../../../utils/colors";
import AccommodationMeasures from "./accommodationMeasures";
import AddButton from "./addButton";
import SpaceAggregateConsumption, {
  iSpaceAggregateConsumptionProps,
} from "./spaceAggregateConsumption";
import SpaceAggregateTable from "./spaceAggregateTable";
import SpacesConsumption, {
  iSpacesConsumptionProps,
} from "./spacesConsumption";
import SpacesTable from "./spacesTable";

type tTopLevelFilter = {
  key: string;
  label: string;
  count: number;
  tableComponent: {
    pageSectionTitleProps: iPageSectionTitleProps;
    Element: React.FC<any>;
    props: Record<string, any>;
  };
  consumptionComponent:
    | {
        Element: React.FC<iSpaceAggregateConsumptionProps>;
        props: iSpaceAggregateConsumptionProps;
      }
    | {
        Element: React.FC<iSpacesConsumptionProps>;
        props: iSpacesConsumptionProps;
      };
  extraComponents: { Component: React.FC<any>; key: string }[];
};

interface iProps {
  outletContainerWidth: number;
}

const SpacesWrapped: React.FC<iProps> = ({ outletContainerWidth }) => {
  const { trans } = useLocalizationState();
  const [topLevelFilterIndex, setTopLevelFilterIndex] = useState<number>(0);
  const {
    hotel: { spaces },
    hotelIsLoaded,
    spaceAggregatesByCategory,
  } = useHotelState();

  const TOP_LEVEL_FILTERS: tTopLevelFilter[] = [
    {
      key: "spaces",
      label: trans("pages.spaces.tabs.spaces.title"),
      count: spaces.length,
      tableComponent: {
        pageSectionTitleProps: {
          title: trans("pages.spaces.tabs.spaces.management.title"),
          description: trans("pages.spaces.tabs.spaces.management.description"),
          icon: RoomPreferencesIcon,
        },
        Element: SpacesTable,
        props: { outletContainerWidth },
      },
      consumptionComponent: {
        Element: SpacesConsumption,
        props: {},
      },
      extraComponents: [
        { Component: AccommodationMeasures, key: "accommodation-measures" },
      ],
    },
    {
      key: "groups",
      label: trans("pages.spaces.tabs.groups.title"),
      count: spaceAggregatesByCategory.group.length,
      tableComponent: {
        pageSectionTitleProps: {
          title: trans("pages.spaces.tabs.groups.management.title"),
          description: trans("pages.spaces.tabs.groups.management.description"),
          icon: RoomPreferencesIcon,
        },
        Element: SpaceAggregateTable,
        props: {
          outletContainerWidth,
          category: "group",
        },
      },
      consumptionComponent: {
        Element: SpaceAggregateConsumption,
        props: {
          category: "group",
          pageSectionTitleProps: {
            title: trans("pages.spaces.tabs.groups.consumption.title"),
            description: trans(
              "pages.spaces.tabs.groups.consumption.description"
            ),
            icon: WorkspaceIcon,
          },
        },
      },
      extraComponents: [],
    },
    {
      key: "zones",
      label: trans("pages.spaces.tabs.zones.title"),
      count: spaceAggregatesByCategory.zone.length,
      tableComponent: {
        pageSectionTitleProps: {
          title: trans("pages.spaces.tabs.zones.management.title"),
          description: trans("pages.spaces.tabs.zones.management.description"),
          icon: RoomPreferencesIcon,
        },
        Element: SpaceAggregateTable,
        props: {
          outletContainerWidth,
          category: "zone",
        },
      },
      consumptionComponent: {
        Element: SpaceAggregateConsumption,
        props: {
          category: "zone",
          pageSectionTitleProps: {
            title: trans("pages.spaces.tabs.zones.consumption.title"),
            description: trans(
              "pages.spaces.tabs.zones.consumption.description"
            ),
            icon: StacksIcon,
          },
        },
      },
      extraComponents: [],
    },
    {
      key: "types",
      label: trans("pages.spaces.tabs.types.title"),
      count: spaceAggregatesByCategory.type.length,
      tableComponent: {
        pageSectionTitleProps: {
          title: trans("pages.spaces.tabs.types.management.title"),
          description: trans("pages.spaces.tabs.types.management.description"),
          icon: RoomPreferencesIcon,
        },
        Element: SpaceAggregateTable,
        props: {
          outletContainerWidth,
          category: "type",
        },
      },
      consumptionComponent: {
        Element: SpaceAggregateConsumption,
        props: {
          category: "type",
          pageSectionTitleProps: {
            title: trans("pages.spaces.tabs.types.consumption.title"),
            description: trans(
              "pages.spaces.tabs.types.consumption.description"
            ),
            icon: CategoryIcon,
          },
        },
      },
      extraComponents: [],
    },
  ];

  const segment = TOP_LEVEL_FILTERS[topLevelFilterIndex];
  const {
    tableComponent: {
      Element: TableElement,
      props: tableProps,
      pageSectionTitleProps,
    },
    consumptionComponent: {
      Element: ConsumptionElement,
      props: consumptionProps,
    },
    extraComponents,
  } = segment;

  const onSelectTopLevelFilter = (key: string) => {
    setTopLevelFilterIndex(findIndex(TOP_LEVEL_FILTERS, (f) => f.key === key));
  };

  return (
    <Fragment>
      <Flex column gap={16}>
        <Flex row between>
          <Flex grow={1}>
            <Segmentation
              appearance="subtle"
              active={segment.key}
              onSelect={onSelectTopLevelFilter}
              options={TOP_LEVEL_FILTERS.map((f) => {
                const { key, label, count } = f;
                return {
                  key,
                  label: { text: label },
                  count: count,
                  disabled: !hotelIsLoaded,
                };
              })}
            />
          </Flex>
          <Flex
            middle
            style={{
              borderBottom: `2px ${COLORS.gray} solid`,
            }}
          >
            <AddButton />
          </Flex>
        </Flex>
        <Flex column gap={40}>
          {/* @ts-ignore */}
          <ConsumptionElement key={segment.key} {...consumptionProps} />
          <Flex column gap={16}>
            <PageSectionTitle {...pageSectionTitleProps} />
            <TableElement key={segment.key} {...tableProps} />
          </Flex>
          {extraComponents.map(({ Component, key }) => (
            <Component {...{ key }} />
          ))}
        </Flex>
      </Flex>
    </Fragment>
  );
};

const SpacesGuarded: React.FC = () => {
  const { trans } = useLocalizationState();
  const { outlet: oc } = useScreenSize();
  const { activeSubscriptionType } = useHotelSubscriptionState();
  const profilePermissions = useProfilePermissions();

  if (activeSubscriptionType === "starter")
    return (
      <>
        <Flex column gap={40}>
          <AccommodationMeasures />
          <Flex column gap={16}>
            <PageSectionTitle
              title={trans("pages.spaces.unlock.consumption.title")}
              description={trans("pages.spaces.unlock.consumption.description")}
              icon={MeetingRoomIcon}
            />
            <Unlock
              image={bySpacePlaceholder}
              title={trans("pages.spaces.unlock.consumption.unlock.title")}
              description={[0].map((i) =>
                trans(
                  `pages.spaces.unlock.consumption.unlock.description[${i}]`
                )
              )}
              style={{ paddingBottom: "200px" }}
            />
          </Flex>
          <Flex column gap={16}>
            <PageSectionTitle
              title={trans("pages.spaces.unlock.management.title")}
              description={trans("pages.spaces.unlock.management.description")}
              icon={RoomPreferencesIcon}
            />
            <Unlock
              image={spacesManagementPlaceholder}
              title={trans("pages.spaces.unlock.management.unlock.title")}
              description={[0].map((i) =>
                trans(`pages.spaces.unlock.management.unlock.description[${i}]`)
              )}
            />
          </Flex>
        </Flex>
        <PageBottomPadding />
      </>
    );

  if (!profilePermissions.readSpaces) return <NoPermissions />;

  if (!oc) return null;

  return (
    <>
      <SpacesWrapped outletContainerWidth={oc.width} />
      <PageBottomPadding />
    </>
  );
};

const Spaces: React.FC = () => {
  const { trans } = useLocalizationState();
  return (
    <div>
      <TopBar>
        <InterTag
          size={24}
          bold
          color={COLORS.secondary}
          text={trans("pages.spaces.title")}
        />
      </TopBar>
      <IpValidatorGuard>
        <SpacesGuarded />
      </IpValidatorGuard>
    </div>
  );
};

export default Spaces;
