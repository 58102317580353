import axios, { AxiosResponse } from "axios";
import {
  CountryCode,
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js";
import { find } from "lodash";
import React, { Fragment, useState } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputNumber,
  SelectPicker,
  useToaster,
} from "rsuite";
import { ReactComponent as Call } from "../../../assets/icons/call.svg";
import { ReactComponent as KeyboardArrowDown } from "../../../assets/icons/keyboard_arrow_down.svg";
import { ReactComponent as PasswordIcon } from "../../../assets/icons/password.svg";
import { ReactComponent as PersonIcon } from "../../../assets/icons/person.svg";
import { ReactComponent as VerifiedUser } from "../../../assets/icons/verified_user.svg";
import { ReactComponent as VisibilityOn } from "../../../assets/icons/visibility.svg";
import { ReactComponent as VisibilityOff } from "../../../assets/icons/visibility_off.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icon";
import InputWrapper from "../../../components/InputWrapper";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import { apiAddress, REQUEST_STATUS } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import {
  COUNTRIES,
  getCountryFlagURL2,
  getCountryInfo,
} from "../../../utils/countries";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { notification } from "../../../utils/notifications";
import { tRegisterState2 } from "./register";

type tAxiosResponseData = {
  accommodationName: string;
  accommodationType: string;
  accommodationNumberOfUnits: number;
  accommodationWebsite: string;
  accommodationAddress: string;
  accommodationCounty: string;
  accommodationDistrict: string;
  accommodationEmail: string;
  accommodationPhoneNumber: string;
  accommodationPostalCode: string;
};

const TOURISM_REGISTER = [
  { code: "ET", name: "Estabelecimento Turístico" },
  { code: "AL", name: "Alojamento Local" },
];

type tDataKeys = keyof Pick<
  tRegisterState2["data"],
  | "name"
  | "password"
  | "phoneNumber"
  | "country"
  | "tourismRegisterType"
  | "tourismRegisterNumber"
>;

interface iProps {
  state: Pick<tRegisterState2["data"], tDataKeys>;
  errors: Pick<tRegisterState2["errors"], tDataKeys>;
  onChange(key: tDataKeys, value: any): void;
  setErrors(errors: Partial<Pick<tRegisterState2["errors"], tDataKeys>>): void;
  onConfirm(obj: tAxiosResponseData): void;
}

const Step2: React.FC<iProps> = ({
  errors,
  onChange,
  onConfirm,
  setErrors,
  state,
}) => {
  const toaster = useToaster();

  const getRequest = useGetRequest({}, { status: REQUEST_STATUS.IDLE });

  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  const { trans } = useLocalizationState();

  const handleChange = (key: tDataKeys) => (value: any) => {
    onChange(key, value);
  };

  const handleContinue = () => {
    const errorsObj: Partial<typeof errors> = {};

    if (!state.name || state.name.length === 0) {
      errorsObj["name"] = trans("Enter a valid name");
    }
    if (!state.password || state.password.length === 0) {
      errorsObj["password"] = trans("Enter a valid password");
    }
    if (!state.phoneNumber || state.phoneNumber.length === 0) {
      errorsObj["phoneNumber"] = trans("Enter a valid phone number");
    }

    if (Object.keys(errorsObj).length) {
      return setErrors(errorsObj);
    }

    getRequest.pending();

    const { tourismRegisterNumber, tourismRegisterType } = state;
    axios
      .get(`${apiAddress(false)}/v2/managers/verify-tourism-register-number`, {
        params: { tourismRegisterNumber, tourismRegisterType },
      })
      .then((res: AxiosResponse<tAxiosResponseData>) => {
        getRequest.resolve();
        const {
          data: {
            accommodationName,
            accommodationType,
            accommodationNumberOfUnits,
            accommodationWebsite,
            accommodationAddress,
            accommodationCounty,
            accommodationDistrict,
            accommodationEmail,
            accommodationPhoneNumber,
            accommodationPostalCode,
          },
        } = res;
        onConfirm({
          accommodationName,
          accommodationType,
          accommodationNumberOfUnits,
          accommodationWebsite,
          accommodationAddress,
          accommodationCounty,
          accommodationDistrict,
          accommodationEmail,
          accommodationPhoneNumber,
          accommodationPostalCode,
        });
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        getRequest.reject(error);
        toaster.push(notification("error", error), { placement: "topEnd" });
      });
  };

  const nameInput = () => {
    return (
      <InputWrapper required label={trans("general.name")} error={errors.name}>
        <InputGroup disabled={getRequest.isLoading}>
          <InputGroup.Addon>
            <Icon
              Element={PersonIcon}
              width={24}
              height={24}
              fill={COLORS.secondary}
            />
          </InputGroup.Addon>
          <Input
            value={state.name}
            onChange={handleChange("name")}
            type="text"
            placeholder={trans("general.placeholders.your_name")}
            size="lg"
          />
        </InputGroup>
      </InputWrapper>
    );
  };

  const passwordInput = () => {
    return (
      <InputWrapper
        required
        label={trans("general.password")}
        error={errors.password}
      >
        <InputGroup disabled={getRequest.isLoading}>
          <InputGroup.Addon>
            <Icon
              Element={PasswordIcon}
              width={24}
              height={24}
              fill={COLORS.secondary}
            />
          </InputGroup.Addon>
          <Input
            value={state.password}
            onChange={handleChange("password")}
            type={passwordVisibility ? "text" : "password"}
            placeholder={trans("general.placeholders.password")}
            size="lg"
          />
          <InputGroup.Button
            className={`hover-gray-100`}
            onClick={() => setPasswordVisibility((prev) => !prev)}
          >
            {passwordVisibility ? (
              <Icon
                Element={VisibilityOn}
                width={24}
                height={24}
                fill={COLORS.gray}
              />
            ) : (
              <Icon
                Element={VisibilityOff}
                width={24}
                height={24}
                fill={COLORS.gray}
              />
            )}
          </InputGroup.Button>
        </InputGroup>
      </InputWrapper>
    );
  };

  const phoneNumberInput = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const displayedPhoneNumber = () => {
      try {
        if (isPossiblePhoneNumber(state.phoneNumber, "PT")) {
          const formatted = parsePhoneNumber(
            state.phoneNumber,
            state.country as CountryCode
          ).formatNational();
          if (formatted) return formatted;
          return state.phoneNumber;
        }
      } catch (err) {
        return state.phoneNumber;
      }
      return state.phoneNumber;
    };

    return (
      <InputWrapper
        label={trans("general.phone_number")}
        error={errors.phoneNumber}
      >
        <Flex row gap={8}>
          <SelectPicker
            disabled={getRequest.isLoading}
            data={COUNTRIES.map(({ iso: { alpha2 }, name }) => ({
              label: name,
              value: alpha2,
            }))}
            value={state.country}
            onChange={handleChange("country")}
            caretAs={() => null}
            placement="topStart"
            cleanable={false}
            className="phone-number-select"
            renderValue={(value, item, selectedElement) => {
              return (
                <Flex center middle gap={12} style={{ padding: "0 0px" }}>
                  <Icon Element={Call} fill={COLORS.secondary} size={24} />
                  <img
                    src={getCountryFlagURL2(value)}
                    alt="country"
                    style={{ width: "25px" }}
                  />
                  <Icon
                    Element={KeyboardArrowDown}
                    fill={COLORS.secondary}
                    size={24}
                  />
                </Flex>
              );
            }}
          />
          <InputGroup disabled={getRequest.isLoading}>
            <InputGroup.Addon>
              <InterTag
                size={16}
                color={COLORS.secondary}
                text={(getCountryInfo(state.country)?.phone || []).join("/")}
              />
            </InputGroup.Addon>
            <Input
              value={state.phoneNumber}
              onChange={handleChange("phoneNumber")}
              placeholder={trans("911111111")}
              size="lg"
            />
          </InputGroup>
        </Flex>
      </InputWrapper>
    );
  };

  const nationalTourismRegister = () => {
    const portugal = find(COUNTRIES, (c) => c.name === "Portugal");
    return (
      <InputWrapper label={trans("general.rnt")}>
        <Flex row gap={8}>
          <Flex>
            <SelectPicker
              disabled={getRequest.isLoading}
              data={[portugal!].map(({ iso: { alpha2 }, name }) => ({
                label: name,
                value: alpha2,
              }))}
              value={state.country}
              onChange={handleChange("country")}
              caretAs={() => null}
              placement="topStart"
              cleanable={false}
              className="phone-number-select"
              renderValue={(value, item, selectedElement) => {
                return (
                  <Flex center middle gap={12} style={{ padding: "0 0px" }}>
                    <img
                      src={getCountryFlagURL2(value)}
                      alt="country"
                      style={{ width: "25px" }}
                    />
                    <Icon
                      Element={KeyboardArrowDown}
                      fill={COLORS.secondary}
                      size={24}
                    />
                  </Flex>
                );
              }}
            />
          </Flex>
          <Flex column gap={4}>
            <SelectPicker
              disabled={getRequest.isLoading}
              data={TOURISM_REGISTER.map(({ code }) => ({
                label: trans(`general.${code.toLowerCase()}.extended`),
                value: trans(`general.${code.toLowerCase()}.compact`),
              }))}
              searchable={false}
              value={state.tourismRegisterType}
              onChange={handleChange("tourismRegisterType")}
              caretAs={() => null}
              placement="topStart"
              cleanable={false}
              className="phone-number-select"
              renderValue={(value, item, selectedElement) => {
                return (
                  <Flex center middle gap={12} style={{ padding: "0 0px" }}>
                    <Icon
                      Element={VerifiedUser}
                      fill={COLORS.secondary}
                      size={24}
                    />
                    <InterTag text={value} size={16} color={COLORS.secondary} />
                    <Icon
                      Element={KeyboardArrowDown}
                      fill={COLORS.secondary}
                      size={24}
                    />
                  </Flex>
                );
              }}
            />
          </Flex>
          <Flex column gap={4} one>
            <InputNumber
              disabled={getRequest.isLoading}
              className="number-input-no-step"
              value={state.tourismRegisterNumber}
              onChange={handleChange("tourismRegisterNumber")}
              placeholder={trans("pages.register.inputs.rnt_id.placeholder")}
              size="lg"
            />
            {errors.tourismRegisterNumber && (
              <InterTag
                color={COLORS.error}
                text={errors.tourismRegisterNumber}
                size={8}
              />
            )}
          </Flex>
        </Flex>
      </InputWrapper>
    );
  };

  return (
    <Fragment>
      <Flex column middle gap={8}>
        <InterTag
          size={20}
          color={COLORS.secondary}
          text={trans("pages.register.steps.2.title")}
          bold
        />
        <InterTag
          size={16}
          color={COLORS.secondary}
          text={trans("pages.register.steps.2.description")}
        />
      </Flex>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleContinue();
        }}
      >
        <Flex column gap={20}>
          {nameInput()}
          {passwordInput()}
          {phoneNumberInput()}
          {nationalTourismRegister()}
        </Flex>
        <input type="submit" hidden />
      </form>
      <Button
        onClick={handleContinue}
        block
        appearance="primary"
        disabled={getRequest.isLoading}
        loading={getRequest.isLoading}
      >
        <InterTag
          style={{ opacity: getRequest.isLoading ? 0 : 1 }}
          text={trans("general.continue")}
          bold
          color={COLORS.white}
          size={20}
        />
      </Button>
    </Fragment>
  );
};

export default Step2;
