import React from "react";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import NoPermissions from "../../../components/Pages/NoPermissions";
import PageBottomPadding from "../../../components/PageBottomPadding";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { COLORS } from "../../../utils/colors";
import AwardsList from "./list";
import AwardsStatistics from "./statistics";
import Unlock from "./unlock";

const AwardsWrapped: React.FC = () => {
  const { activeSubscriptionIsStarter } = useHotelSubscriptionState();
  const profilePermissions = useProfilePermissions();
  if (activeSubscriptionIsStarter)
    return (
      <>
        <Unlock />
        <PageBottomPadding />
      </>
    );

  if (!profilePermissions.readAwards) {
    return <NoPermissions />;
  }

  return (
    <>
      <Flex column gap={40}>
        <AwardsStatistics />
        <AwardsList />
      </Flex>
      <PageBottomPadding />
    </>
  );
};

const Awards: React.FC = () => {
  const { trans } = useLocalizationState();

  return (
    <>
      <TopBar>
        <InterTag
          text={`${trans("general.awards")}`}
          bold
          color={COLORS.secondary}
          size={24}
        />
      </TopBar>
      <IpValidatorGuard>
        <AwardsWrapped />
      </IpValidatorGuard>
    </>
  );
};

export default Awards;
