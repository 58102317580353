import axios from "axios";
import { useCallback } from "react";
import useHotelState from "../context/Hotel/hooks/useHotelState";
import useLocalizationState from "../context/Localization/hooks/useLocalizationState";
import { tHotelId } from "../models/hotel";
import { tManager } from "../models/manager";
import { apiAddress } from "../utils/apiCall";
import { getErrorMessage } from "../utils/httpResponses/others";
import useGetRequest from "./apiRequests/useGetRequest";
import useEffectSafe from "./useEffectSafe";

function useListStaff() {
  const { trans } = useLocalizationState();
  const { hotelId, hotelIsLoaded, hotelLoading } = useHotelState();
  const getStaffRequest = useGetRequest<{ staff: tManager[] }>({ staff: [] });

  const runStaff = useCallback(
    (hotelId: tHotelId) => {
      getStaffRequest.pending();

      axios
        .get(`${apiAddress(false)}/v2/hotels/${hotelId}/staff`)
        .then((res) => {
          const {
            data: { staff },
          } = res;

          getStaffRequest.resolve({ staff });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          getStaffRequest.reject(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trans]
  );

  useEffectSafe(() => {
    if (hotelLoading) getStaffRequest.pending();
    else if (hotelId && hotelIsLoaded) runStaff(hotelId);
  }, [hotelId, hotelLoading, hotelIsLoaded, trans]);

  return getStaffRequest;
}

export default useListStaff;
