import moment from "moment";
import React from "react";
import useScreenSize from "../../hooks/useScreenSize";
import { tHotelRoadmap, tHotelSpaceId } from "../../models/hotel";
import { TE, tMeasure, tMeasureTotal } from "../../models/measures";
import Flex from "../Flex";
import { iMeasureConsumptionProps } from "../MeasureConsumption";
import MeasureConsumptionElectricity from "../MeasureConsumptionElectricity";
import MeasureConsumptionWater from "../MeasureConsumptionWater";
import PageSectionTitle, { iPageSectionTitleProps } from "../PageSectionTitle";
import Roadmap from "../RoadmapTable";
import { iSimpleHeaderCellProps } from "../RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import SpaceList from "./spaceList";

export type tData = {
  bySpace: Record<tHotelSpaceId, Partial<Record<tMeasure, number[]>>>;
  allSpaces: Partial<Record<tMeasure, number[]>>;
};

interface iProps
  extends Pick<iMeasureConsumptionProps, "measuresRequestStatusTracker"> {
  mainMeasureKey: tMeasureTotal;

  overviewSection: iPageSectionTitleProps;
  spaceListSection: iPageSectionTitleProps;
  converter: any;
  tableHeader: iSimpleHeaderCellProps;
  roadmapFilter: tHotelRoadmap["type"];
  period: [Date, Date];
}

const ConsumptionPageSpecific: React.FC<iProps> = ({
  mainMeasureKey,
  overviewSection,
  spaceListSection,
  converter,
  tableHeader,
  roadmapFilter,
  period,
  measuresRequestStatusTracker,
}) => {
  const { outlet: oc } = useScreenSize();

  return (
    <Flex column gap={40}>
      <Flex column gap={16}>
        <PageSectionTitle {...{ ...overviewSection }} />
        {mainMeasureKey === TE ? (
          <MeasureConsumptionElectricity
            {...{
              measuresRequestStatusTracker,
            }}
            from={period[0]}
            to={period[1]}
          />
        ) : (
          <MeasureConsumptionWater
            {...{
              measuresRequestStatusTracker,
            }}
            from={period[0]}
            to={period[1]}
          />
        )}
      </Flex>
      <Flex column gap={16}>
        <PageSectionTitle {...{ ...spaceListSection }} />
        {oc ? (
          <SpaceList
            {...{
              ...(period
                ? { from: period[0], to: period[1] }
                : {
                    from: moment().startOf("day").toDate(),
                    to: moment().toDate(),
                  }),
              mainMeasureKey,
              converter,
              tableHeader,
            }}
            outletContainerWidth={oc.width}
          />
        ) : null}
      </Flex>
      <Roadmap filterByType={roadmapFilter} />
    </Flex>
  );
};

export default ConsumptionPageSpecific;
