import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import DataTypeToggle from "../../../components/DataTypeToggle";
import { useDataTypeToggle } from "../../../components/DataTypeToggle/useDataTypeToggle";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import WithContextProvider from "../../../hoc/withProvider";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { COLORS } from "../../../utils/colors";
import { DATA_TYPE_TOGGLE_LIVE } from "../../../utils/dataTypeToggle";
import ConsumptionContextProvider, {
  ConsumptionContext,
} from "../consumption/context";
import HistoricData from "./historicData";
import LiveData from "./liveData";

const ElectricityGuarded: React.FC = () => {
  const context = useContext(ConsumptionContext);
  const profilePermissions = useProfilePermissions();
  const { activeSubscriptionIsStarter, activeSubscriptionIsBusiness } =
    useHotelSubscriptionState();
  const dataTypeState = useDataTypeToggle();

  if (activeSubscriptionIsStarter || activeSubscriptionIsBusiness)
    return <HistoricData {...{ dataTypeToggle: null, profilePermissions }} />;

  const dataTypeToggle = (
    <DataTypeToggle
      {...{ dataTypeState }}
      loading={
        dataTypeState.isLoading ||
        Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
      }
    />
  );

  if (!dataTypeState.isResolved) return null;

  if (dataTypeState.dataType === DATA_TYPE_TOGGLE_LIVE)
    return <LiveData {...{ dataTypeToggle }} />;

  return <HistoricData {...{ dataTypeToggle, profilePermissions }} />;
};

const Electricity: React.FC = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  return (
    <>
      <TopBar>
        <Flex gap={8} column center style={{ height: "100%" }}>
          <InterTag
            hoverUnderline
            onClick={() => {
              navigate("/consumption");
            }}
            text={trans("general.consumption")}
            size={12}
            color={COLORS.secondary}
          />
          <InterTag
            text={trans("general.measures.electricity")}
            size={24}
            bold
            color={COLORS.secondary}
          />
        </Flex>
      </TopBar>
      <IpValidatorGuard>
        <ElectricityGuarded />
      </IpValidatorGuard>
    </>
  );
};

export default WithContextProvider(ConsumptionContextProvider, Electricity);
