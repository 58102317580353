import React, { useContext } from "react";
import DataTypeToggle from "../../../components/DataTypeToggle";
import { useDataTypeToggle } from "../../../components/DataTypeToggle/useDataTypeToggle";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import WithContextProvider from "../../../hoc/withProvider";
import { COLORS } from "../../../utils/colors";
import { DATA_TYPE_TOGGLE_LIVE } from "../../../utils/dataTypeToggle";
import ConsumptionContextProvider, { ConsumptionContext } from "./context";
import HistoricData from "./historicData";
import LiveData from "./liveData";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";

const ConsumptionGuarded: React.FC = () => {
  const { activeSubscriptionIsStarter } = useHotelSubscriptionState();
  const context = useContext(ConsumptionContext);
  const dataTypeState = useDataTypeToggle();

  if (activeSubscriptionIsStarter)
    return <HistoricData dataTypeToggle={null} />;

  const toggle = (
    <DataTypeToggle
      {...{ dataTypeState }}
      loading={
        dataTypeState.isLoading ||
        Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
      }
    />
  );

  if (!dataTypeState.isResolved) return null;

  if (dataTypeState.dataType === DATA_TYPE_TOGGLE_LIVE)
    return <LiveData dataTypeToggle={toggle} />;

  return <HistoricData dataTypeToggle={toggle} />;
};

const Consumption: React.FC = () => {
  const { trans } = useLocalizationState();
  return (
    <>
      <TopBar>
        <InterTag
          text={trans("general.consumption")}
          bold
          color={COLORS.secondary}
          size={24}
        />
      </TopBar>

      <IpValidatorGuard>
        <ConsumptionGuarded />
      </IpValidatorGuard>
    </>
  );
};

export default WithContextProvider(ConsumptionContextProvider, Consumption);
