import { tHotelRoadmap } from "../../models/hotel";

export const ROADMAP_TYPES: tHotelRoadmap["type"][] = [
  "electricity",
  "environment",
  "fuels",
  "governance",
  "social",
  "supply chain",
  "waste",
  "water",
];

export const ROADMAP_PHASES: tHotelRoadmap["phase"][] = [
  "1. Foundation and Awareness",
  "2. Environmental Management",
  "3. Policy Implementation",
  "4. Operational Efficiency",
  "5. Infrastructure and Innovation",
  "6. Community and Supplier Engagement",
];

export const ROADMAP_DIFFICULTIES: tHotelRoadmap["difficulty"][] = [
  "low",
  "medium",
  "high",
];

export const HOTEL_ROADMAP_STATUSES: tHotelRoadmap["status"][] = [
  "idle",
  "in progress",
  "completed",
];

export const hotelRoadmapStatusToTransKey = (
  status: tHotelRoadmap["status"]
): string => {
  switch (status) {
    case "completed":
      return "components.roadmap.item.status.completed";
    case "idle":
      return "components.roadmap.item.status.idle";
    case "in progress":
      return "components.roadmap.item.status.in progress";
  }
};
